import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@barracuda/bds-core";
import Grid from "@barracuda/bds-core/dist/Grid";
import React from "react";
import IProduct from "../../../models/Products/IProduct";
import { enterKey } from "../../../utility";

interface ISubmitDeactivateBBSDialogProps {
  onCancel: () => void;
  onSubmitDeactivate: () => void;
  showDeactivateBBSDialog: boolean;
  isActionInProgress: boolean;
  selectedProduct: IProduct | undefined;
}
const ShowDeactivateBBSDialog: React.FC<ISubmitDeactivateBBSDialogProps> = ({ showDeactivateBBSDialog, onCancel, isActionInProgress, onSubmitDeactivate, selectedProduct }) => {
  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onSubmitDeactivate)} disableEscapeKeyDown={isActionInProgress} className="deactivateDialog" data-testid="deactivateDialog" disableBackdropClick={true} open={showDeactivateBBSDialog} onClose={onCancel}>
      <DialogTitle data-testid="deactivateTitle"> {"DEACTIVATE " + selectedProduct?.subname} </DialogTitle>
      <DialogContent style={{ paddingTop: 26, paddingBottom: 19, paddingRight: 27, paddingLeft: 27 }}>
        <Grid container direction="row" item xs={12}>
          <Grid item container xs={3}>
            <Grid item container>
              <Typography data-testid="deactivateModelLabel" variant="button">
                MODEL
              </Typography>
            </Grid>
            <Grid item container>
              <Typography data-testid="deactivateModelValue" variant="body2" style={{ fontWeight: "bold" }}>
                {selectedProduct?.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid item>
              <Typography data-testid="deactivateSerialLabel" variant="button">
                SERIAL
              </Typography>
            </Grid>
            <Grid item>
              <Typography data-testid="deactivateSerialValue" variant="body2" style={{ fontWeight: "bold" }}>
                {selectedProduct && selectedProduct.serials[0].serial.length > 0 ? selectedProduct.serials[0].serial : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <div data-testid="deactivateMessage">
          <Typography data-testid="deactivateMessage" variant="h6">
            This section is dedicated to explaining to the user what happens when an appliance is deactivated.
            <br /> <br />
            To proceed with deactivation, select CONFIRM below.
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <div>
          <Button data-testid="cancelDeactivate" variant="text" size="large" disabled={isActionInProgress} onClick={onCancel}>
            CANCEL
          </Button>
          <Button data-testid="confirmDeactivate" primary="true" isLoading={isActionInProgress} disabled={isActionInProgress} type={"submit"} onClick={onSubmitDeactivate}>
            CONFIRM
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ShowDeactivateBBSDialog;
