import React, { useState, useEffect } from "react";
import { DataTable, LinearProgress } from "@barracuda/bds-core";
import { process } from "@progress/kendo-data-query";
import { GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import "../../../table.css";
import CheckBoxCommandCell from "../../CheckBoxCommandCell";
import IEntitlement from "../../../models/IEntitlement";
import { IAppState } from "../../../store/store";
import { useSelector } from "react-redux";
import Pager from "@barracuda/bds-core/dist/DataTable/Pager";
import { getButtonCount } from "../../../utility";
import { useMediaQuery } from "@material-ui/core";

interface IEntitlementsTableProps {
  userEntitlements: IEntitlement[];
  selectionChanged: (en: any[]) => void;
  disabled: boolean;
}

const EntitlementsTable: React.FC<IEntitlementsTableProps> = ({ userEntitlements, selectionChanged, disabled }) => {
  const loadingEntitlements = useSelector((state: IAppState) => state.userState.loadingEntitlements);
  const [isLoading, setIsLoading] = useState(loadingEntitlements);

  useEffect(() => {
    setIsLoading(loadingEntitlements);
    // eslint-disable-next-line
  }, [loadingEntitlements]);

  const [buttonCount, setButtonCount] = useState(10);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");

  const dataState = {
    skip: 0,
    take: 15,
    sort: [{ field: "date", dir: "asc" }],
    group: [],
    filter: {
      logic: "and",
      filters: [],
    },
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "Name",
        field: "description",
        show: true,
        filter: "text",
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(userEntitlements, dataState as any),
  });

  const dataStateChange = (e: any): void => {
    if (userEntitlements && userEntitlements.length > 0) {
      setGridState({
        dataState: { ...dataState, ...e.dataState },
        dataResult: process(userEntitlements, e.dataState),
      });
    }
  };

  useEffect(() => {
    if (userEntitlements) {
      setGridState({ dataState: gridState.dataState, dataResult: process(userEntitlements, gridState.dataState as any) });
    }
  }, [gridState.dataState, userEntitlements]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const handleSelectionChanged = (dataItem: any, checked: boolean) => {
    const data = [...userEntitlements];
    const current = data.findIndex(item => item.name === dataItem.name);
    data[current].checked = checked;
    selectionChanged(data);
  };

  const SelectEntitlementCell = (props: any) => <CheckBoxCommandCell {...props} checked={props.dataItem.checked} onChange={handleSelectionChanged} disabled={disabled} />;

  return (
    <DataTable
      className={"noHeader noScrollbar noBorders"}
      data={gridState.dataResult}
      resizable
      // page
      pageConfig={{
        pageable: {
          pageSizes: [5, 10, 15],
          buttonCount: buttonCount,
        },
        skip: gridState.dataState.skip,
        take: gridState.dataState.take,
        total: gridState.dataResult.total,
      }}
      pager={gridState.dataResult.data.length > 0 && Pager}
      // sort
      sortConfig={{
        sortable: true,
        sort: gridState.dataState.sort as any,
      }}
      onDataStateChange={dataStateChange}
      selectedField="selected"
      {...(gridState.dataState as any)}
    >
      <GridNoRecords>
        <div>{isLoading ? <LinearProgress data-testid="loadingEntitlements" /> : "No entitlements available"}</div>
      </GridNoRecords>
      <Column cell={SelectEntitlementCell} field="checked" width="60px" headerSelectionValue={gridState.dataResult.data.findIndex(dataItem => dataItem.checked === false) === -1} />
      {gridState.dataState.columns.map((column, idx) => column.show && <Column key={"user" + idx} field={column.field} title={column.title} />)}
    </DataTable>
  );
};

export default EntitlementsTable;
