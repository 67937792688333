import React from "react";
import Tooltip from "@barracuda/bds-core/dist/Tooltip";

const TooltipsForEntitnlements = (props: any) => {
  let entitlementsNameArray = [];
  if (props.dataItem.entitlements !== undefined) {
    entitlementsNameArray = props.dataItem.entitlements.map((x: any) => x.description).flat();
  }
  let entitlementTitle = (
    <p style={{ marginBottom: "0px", marginTop: "0px" }}>
      <b>Entitlements</b>
    </p>
  );
  let entitlementName = ``;
  if (entitlementsNameArray.length > 0) {
    entitlementsNameArray.forEach((z: string) => (entitlementName += z + ` \n`));
  } else {
    entitlementName = "No entitlements";
  }

  let entitlementText = [entitlementTitle, entitlementName];
  const entitlementMessage = <div style={{ whiteSpace: "pre-wrap" }}>{entitlementText}</div>;
  const tableItem = props.dataItem[props.field];
  let noValue = false;
  if (tableItem !== undefined) {
    if (tableItem.length === 0) {
      noValue = true;
    }
  }
  let tooltipComponent;
  tooltipComponent = (
    <td>
      <Tooltip title={entitlementMessage} disableHoverListener={noValue || props.isBa} data-testid="tooltipUserEntitlements">
        <span data-testid="userEmail">{tableItem}</span>
      </Tooltip>
    </td>
  );
  return <>{tooltipComponent}</>;
};

export default TooltipsForEntitnlements;
