import React, { useEffect, useState } from "react";
import { Typography, Button, LinearProgress } from "@barracuda/bds-core";
import Grid from "@barracuda/bds-core/dist/Grid";
import IUser from "../../models/IUser";
import UsersTable from "./UsersTable";
import AddEditUserDialog from "./AddEditUser/AddEditUserDialog";
import IAccount from "../../models/IAccount";
import { addUserAction, editUserAction, deleteUserAction } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import DeleteDialog from "../DeleteDialog";
import { buildPayloadToUpdateUser } from "../../Utilities/usersHelper";
import { filterAccountsAction, goToAccountAction, setPrevSelectedAccountAction } from "../../actions/accountActions";
import { areFiltersActive, findAccountById } from "../../Utilities/accountsHelper";
import { canAddEditUser, truncate } from "../../utility";
import Tooltip from "@barracuda/bds-core/dist/Tooltip";
import MspType from "../../models/MspType";
import UserRole from "../../models/UserRole";

export interface IUserAccount {
  id: number;
  name: string;
  checked: boolean;
  userManagement: boolean;
  billingAdministration: boolean;
  enabled: boolean;
}

export interface IUserToUpdate {
  name?: string;
  email?: string;
  updateRoleOrEntitlements: boolean;
}

const UsersTab = () => {
  const dispatch = useDispatch();
  const [userToEdit, setUserToEdit] = useState<IUser>();
  const [dialogTitle, setDialogTitle] = useState("ADD USER");
  const [isEdit, setIsEdit] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [showAddEditUser, setShowAddEditUser] = useState(false);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const loggedUser = useSelector((state: IAppState) => state.generalState.loggedUser);
  const selectedAcc = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const accountsNames = useSelector((state: IAppState) => state.accountState.accountsNames);
  const prevSelectedAccount = useSelector((state: IAppState) => state.accountState.prevSelectedAccount);
  const loadingUsers = useSelector((state: IAppState) => state.userState.loadingUsers);
  const loadingUsersExtraInfo = useSelector((state: IAppState) => state.userState.loadingUsersExtraInfo);
  const filters = useSelector((state: IAppState) => state.accountState.filters);
  const [deleteMessage, setDeleteMessage] = useState<JSX.Element>();
  const [deleteTitle, setDeleteTitle] = useState<JSX.Element>();
  const [isInheritedUser, setIsInheritedUser] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<IAccount>();
  const [showAddUserButton, setShowAddUserButton] = useState(false);

  useEffect(() => {
    setShowAddUserButton(canAddEditUser(mspAccountLoggedIn));
  }, [mspAccountLoggedIn]);

  useEffect(() => {
    setSelectedAccount(selectedAcc);
  }, [selectedAcc]);

  const handleOpenAddDialog = () => {
    setIsEdit(false);
    setDialogTitle("ADD USER");
    setShowAddEditUser(!showAddEditUser);
  };

  const handleOpenEditDialog = (user: IUser) => {
    if (areFiltersActive(filters)) {
      openDialog();
    } else {
      let currentParentAccount;
      if (selectedAcc?.type === MspType.BillingAggregator) {
        currentParentAccount = selectedAcc;
      } else {
        currentParentAccount = findAccountById(mspAccounts, accountsNames, user.explicitAccountId);
      }

      if (currentParentAccount) {
        if (selectedAcc?.id !== currentParentAccount.id) {
          if (selectedAcc !== undefined) {
            dispatch(setPrevSelectedAccountAction(selectedAcc));
          }
          setIsInheritedUser(true);
          dispatch(goToAccountAction(currentParentAccount));
        }
        openDialog();
      }
    }

    function openDialog() {
      setIsEdit(true);
      setDialogTitle("EDIT USER");
      setUserToEdit(user);
      setShowAddEditUser(!showAddEditUser);
    }
  };

  const addUser = (acc: IAccount, user: IUser) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(addUserAction(acc, user));
      resolve(success);
    });

  const editUser = (user: IUser) =>
    new Promise<any>((resolve, reject) => {
      if (userToEdit !== undefined) {
        user.id = userToEdit.id;
        const newData: IUserToUpdate = buildPayloadToUpdateUser(user, userToEdit, loggedUser);
        const success = dispatch(editUserAction(user, newData));

        resolve(success);
      }
    });

  const deleteUser = (user: IUser) =>
    new Promise<any>((resolve, reject) => {
      if (userToEdit !== undefined) {
        user.id = userToEdit.id;
      }
      const success = dispatch(deleteUserAction(user));
      resolve(success);
    });

  const handleOnSumbitClicked = (user: IUser) => {
    if (selectedAccount) {
      setIsActionInProgress(true);
      if (isEdit) {
        editUser(user).then(success => {
          setIsActionInProgress(false);
          setShowAddEditUser(!success);
          if (isInheritedUser) {
            dispatch(goToAccountAction(prevSelectedAccount));
            setIsInheritedUser(false);
          }
          if (success) {
            setUserToEdit(undefined);
            if (userToEdit !== undefined && filters.associatedLogin === userToEdit.email) {
              dispatch(filterAccountsAction());
            }
          }
        });
      } else {
        addUser(selectedAccount, user).then(success => {
          setIsActionInProgress(false);
          setShowAddEditUser(!success);
          if (success) {
            setUserToEdit(undefined);
          }
        });
      }
    }
  };

  const handleOnCancel = () => {
    if (isInheritedUser) {
      dispatch(goToAccountAction(prevSelectedAccount));
      setIsInheritedUser(false);
    }
    setShowAddEditUser(false);
    setUserToEdit(undefined);
  };

  const handleOnCancelDeleteDialog = () => {
    setShowDeletePopUp(false);
    setShowAddEditUser(true);
  };

  const handleOnSubmitDeleteDialog = () => {
    if (userToEdit) {
      setIsActionInProgress(true);
      deleteUser(userToEdit).then(success => {
        setIsActionInProgress(false);
        setShowAddEditUser(!success);
        setShowDeletePopUp(!success);
        if (isInheritedUser) {
          dispatch(goToAccountAction(prevSelectedAccount));
          setIsInheritedUser(false);
        }
        if ((userToEdit.role && userToEdit.role === UserRole.ReadOnly && filters.hasSmbLogins) || filters.associatedLogin === userToEdit.email) {
          dispatch(filterAccountsAction());
        }
      });
    }
  };

  const handleOnDelete = () => {
    setShowAddEditUser(false);
    setShowDeletePopUp(!showDeletePopUp);
  };

  const addTooltipToDeleteMessage = (user: IUser) => {
    if (user.email.length > 20) {
      return (
        <Tooltip title={user.email}>
          <b> {truncate(user.email, 20)}</b>
        </Tooltip>
      );
    } else {
      return <b> {truncate(user.email, 20)}</b>;
    }
  };

  useEffect(() => {
    if (userToEdit) {
      setDeleteMessage(
        <div>
          <Typography variant="h6">
            Are you sure you want to delete user
            {addTooltipToDeleteMessage(userToEdit)}?
          </Typography>
        </div>,
      );
      setDeleteTitle(<Typography variant="h6">DELETE USER</Typography>);
    }
  }, [userToEdit]);

  return (
    <Grid item container spacing={3} direction="column" className={"UsersTab"}>
      <Grid item container>
        <Grid container item xs={10}>
          <Grid container spacing={1} style={{ marginLeft: "10px", paddingBottom: "10px" }}>
            <Typography data-testid="userListTitleLbl" key="btnAddUser2" variant="h4" noWrap style={{ display: "flex" }}>
              Roles {"&"} Access List for&nbsp;{" "}
              <Tooltip title={selectedAccount ? selectedAccount.name : ""}>
                <Typography variant="h4" noWrap>
                  {selectedAccount ? selectedAccount.name : ""}
                </Typography>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
        {showAddUserButton && (
          <Grid container item xs={12} justify="flex-end" style={{ marginRight: "14px" }}>
            <Button data-testid="addUserBtn" variant={"contained"} size={"large"} onClick={handleOpenAddDialog} disabled={loadingUsers || loadingUsersExtraInfo}>
              ADD
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        {loadingUsers ? (
          <div data-testid={"loadingUsers"}>
            <LinearProgress />
          </div>
        ) : (
          <Grid data-testid="usersTable" data-test="grid-button-add-user" container item xs={12} className={"enableHorizontalScrollbar"}>
            <UsersTable openEditDialog={handleOpenEditDialog} />
          </Grid>
        )}
      </Grid>

      {showAddEditUser && selectedAccount && <AddEditUserDialog showDialog={showAddEditUser} isEdit={isEdit} user={userToEdit} dialogTitle={dialogTitle} onDelete={handleOnDelete} onCancel={handleOnCancel} isActionInProgress={isActionInProgress} isAddFirstUser={false} onSubmit={(user: IUser) => handleOnSumbitClicked(user)} />}
      {showDeletePopUp && <DeleteDialog message={deleteMessage} title={deleteTitle} showDeleteDialog={showDeletePopUp} onCancelDelete={handleOnCancelDeleteDialog} isActionInProgress={isActionInProgress} onSubmitDelete={handleOnSubmitDeleteDialog} hasActiveProducts={false} loadingDeleteDialog={false} />}
    </Grid>
  );
};
export default UsersTab;
