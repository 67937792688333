import React from "react";
import IAuditUserImportAction from "../../../models/Products/IAuditUserImportAction";
import SucessImportMesage from "./SucessImportMesage";

interface IFinishedImportDialogProps {
  onClose: (accountId: number) => void;
  isActionInProgress: boolean;
  showDialog: boolean;
  finishedAction: IAuditUserImportAction;
}
const FinishedImportDialog: React.FC<IFinishedImportDialogProps> = ({ showDialog, isActionInProgress, onClose, finishedAction }) => {
  return <SucessImportMesage finishedAction={finishedAction} showExportDialog={showDialog} isActionInProgress={isActionInProgress} onClose={onClose}></SucessImportMesage>;
};

export default FinishedImportDialog;
