import React, { useEffect, useState } from "react";
import { Snackbar, Alert } from "@barracuda/bds-core";
import ISnackBarMessage from "../models/ISnackBarMessage";
import ActionMessageType from "../models/ActionMessageType";
import { useDispatch } from "react-redux";
import { removeSnackBarMessage } from "../actions/generalActions";

interface ISnackBarMessageProps {
  snackBarMessage: ISnackBarMessage;
}
const SnackBarMessage: React.FC<ISnackBarMessageProps> = ({ snackBarMessage }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const autoHideDuration = snackBarMessage.type === ActionMessageType.Error ? 5000000 : 5000;

  useEffect(() => {
    setOpen(snackBarMessage.message.length > 0);
  }, [snackBarMessage]);

  const close = (event: EventSource, reason: string) => {
    if (reason !== "clickaway") {
      dispatch(removeSnackBarMessage());
    }
  };

  return (
    <>
      {snackBarMessage.message !== "" && snackBarMessage.type !== ActionMessageType.Unknown && (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={(event: EventSource, reason: string) => close(event, reason)}
          sx={8}
          data-testid="snackBar"
        >
          <Alert data-testid="snackBarMessage" severity={snackBarMessage.type}>
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackBarMessage;
