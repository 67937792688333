import FilterAccountsOptions from "../../../../models/FilterAccountsOptions";
import IAccount from "../../../../models/IAccount";
import { IAccountFilters } from "../../../../models/IAccountFilters";
import IFilterCheckbox from "../../../../models/IFilterCheckbox";
import MspType from "../../../../models/MspType";
import IAccountOrders from "../../../../models/Products/IAccountOrders";
import IAccountProducts from "../../../../models/Products/IAccountProducts";
import IOrderSlim from "../../../../models/Products/IOrderSlim";
import IProductFamily from "../../../../models/Products/IProductFamily";
import ProductFamily from "../../../../models/Products/ProductFamily";
import { isStringNullOrEmpty, truncate } from "../../../../utility";

export function getOwnerAccountInfoForFiltering(loggedInAcc: IAccount, expandedPartner: IAccount | undefined): { accountId: number; info: string; directOnly?: boolean } {
  if (loggedInAcc.type === MspType.BillingAggregator) {
    return { accountId: loggedInAcc.id, info: "Filter By MSP Partner Name" };
  }
  if (expandedPartner !== undefined) {
    return { accountId: expandedPartner.id, info: `Filter accounts of ${truncate(expandedPartner.name, 30)}`, directOnly: true };
  }
  return { accountId: loggedInAcc.id, info: "Filter all accounts", directOnly: false };
}

export function getOrders(accOrders: IAccountOrders[], accountId: number | undefined): IOrderSlim[] | undefined {
  if (accOrders.length > 0) {
    const index = accOrders.findIndex(x => x.accountId === accountId);
    if (index > -1) {
      return accOrders[index].orders;
    }
  }
  return undefined;
}

export function computeOptions(currentOrders: IOrderSlim[]): IFilterCheckbox[] {
  if (currentOrders.length === 0) {
    return [];
  }
  const prodOptions: IFilterCheckbox[] = [{ label: FilterAccountsOptions.AllActivatedProductsServices, checked: false, disabled: false }];

  currentOrders.forEach(element => {
    if (element.familyName.includes(ProductFamily.ESSENTIALS_SERIVICES)) {
      prodOptions.push({ label: element.bundleName, value: element.bundleSku, checked: false, disabled: false });
    }
  });

  const csSkus: string | undefined = computeProdSkuFilterOptions(currentOrders, ProductFamily.CONTENT_SHIELD);
  if (csSkus !== undefined && csSkus.length > 0) {
    prodOptions.push({ label: ProductFamily.CONTENT_SHIELD, value: csSkus, checked: false, disabled: false });
  }

  let bbsSkus: string | undefined = computeProdSkuFilterOptions(currentOrders, ProductFamily.BACKUP_APPLIANCES);
  if (bbsSkus !== undefined && bbsSkus.length > 0) {
    prodOptions.push({ label: ProductFamily.BACKUP_APPLIANCES, value: bbsSkus, checked: false, disabled: false });
  }

  return prodOptions;
}

export function computeProdSkuFilterOptions(currentOrders: IOrderSlim[], prodFam: ProductFamily): string | undefined {
  let result: string = "";
  currentOrders.forEach(element => {
    if (element.familyName.includes(prodFam)) {
      result += element.prodSku + ",";
    }
  });
  if (result.length > 0) {
    return result.slice(0, -1);
  }
  return undefined;
}

export function updateNoAvailableProductsOptions(statusesOptions: IFilterCheckbox[]): IFilterCheckbox[] {
  return statusesOptions.filter(e => e.label !== FilterAccountsOptions.NoActivatedProducts && e.label !== FilterAccountsOptions.HasActivationErrors);
}

export function changeFilterOptions(options: IFilterCheckbox[], checkAll: boolean, checked?: boolean, disabled?: boolean, label?: string): IFilterCheckbox[] {
  return options.map((a: IFilterCheckbox) => {
    const isTargetLabel = label && a.label === label;
    const applyChange = isTargetLabel || checkAll;
    if (applyChange) {
      if (checked !== undefined) {
        a.checked = checked;
      }
      if (disabled !== undefined) {
        a.disabled = disabled;
      }
    }
    return a;
  });
}

export function setActiveProductsFilter(productsFilterOptions: IFilterCheckbox[], index: number, statusesFilterOptions: IFilterCheckbox[]) {
  let checkedOption = productsFilterOptions[index];
  const isHasActivationErrorsChecked = hasActivationErrors(statusesFilterOptions);

  let { productsFilter, statusesFilter } = isAllActivatedProductServices(checkedOption.label) ? handleAllActivatedProductServices(checkedOption, productsFilterOptions, statusesFilterOptions, isHasActivationErrorsChecked) : handleIndividualProductServices(checkedOption, productsFilterOptions, statusesFilterOptions, isHasActivationErrorsChecked);

  return { productsFilter, statusesFilter };
}

function hasActivationErrors(statusesFilterOptions: IFilterCheckbox[]): boolean {
  return statusesFilterOptions.filter(checkbox => checkbox.label === FilterAccountsOptions.HasActivationErrors && checkbox.checked).length > 0;
}

function isAllActivatedProductServices(label: string): boolean {
  return label === FilterAccountsOptions.AllActivatedProductsServices;
}

function handleAllActivatedProductServices(checkedOption: IFilterCheckbox, productsFilterOptions: IFilterCheckbox[], statusesFilterOptions: IFilterCheckbox[], isHasActivationErrorsChecked: boolean) {
  let productsFilter, statusesFilter;

  if (checkedOption.checked) {
    productsFilter = changeFilterOptions(productsFilterOptions, true, true);
    statusesFilter = changeFilterOptions(statusesFilterOptions, false, false, true, FilterAccountsOptions.NoActivatedProducts);
  } else {
    productsFilter = changeFilterOptions(productsFilterOptions, true, false);
    statusesFilter = changeFilterOptions(statusesFilterOptions, false, undefined, isHasActivationErrorsChecked, FilterAccountsOptions.NoActivatedProducts);
  }

  return { productsFilter, statusesFilter };
}

function handleIndividualProductServices(checkedOption: IFilterCheckbox, productsFilterOptions: IFilterCheckbox[], statusesFilterOptions: IFilterCheckbox[], isHasActivationErrorsChecked: boolean) {
  let productsFilter;
  if (autoCheckAllProductsOption(productsFilterOptions)) {
    productsFilter = changeFilterOptions(productsFilterOptions, false, true, false, FilterAccountsOptions.AllActivatedProductsServices);
  } else {
    productsFilter = changeFilterOptions(productsFilterOptions, false, false, false, FilterAccountsOptions.AllActivatedProductsServices);
  }

  let statusesFilter;
  if (checkedOption.checked) {
    statusesFilter = changeFilterOptions(statusesFilterOptions, false, false, true, FilterAccountsOptions.NoActivatedProducts);
  } else if (productsFilterOptions.filter(e => e.checked).length === 0) {
    statusesFilter = changeFilterOptions(statusesFilterOptions, false, undefined, isHasActivationErrorsChecked, FilterAccountsOptions.NoActivatedProducts);
  } else {
    statusesFilter = statusesFilterOptions;
  }

  return { productsFilter, statusesFilter };
}

function autoCheckAllProductsOption(productsFilterOptions: IFilterCheckbox[]): boolean {
  return productsFilterOptions.filter(checkbox => checkbox.label !== FilterAccountsOptions.AllActivatedProductsServices).every(checkbox => checkbox.checked);
}

export function setStatusesFilter(statusesFilterOptions: IFilterCheckbox[], index: number, productsFilterOptions: IFilterCheckbox[]) {
  let statusesFilterResult = statusesFilterOptions;
  let productsFilterResult = productsFilterOptions;
  let checkedOption = statusesFilterOptions[index];
  if (checkedOption.label === FilterAccountsOptions.NoActivatedProducts && checkedOption.checked) {
    productsFilterResult = changeFilterOptions(productsFilterOptions, true, false, true);
    statusesFilterResult = changeFilterOptions(statusesFilterOptions, false, false, true, FilterAccountsOptions.HasActivationErrors);
  } else if (checkedOption.label === FilterAccountsOptions.NoActivatedProducts && !checkedOption.checked) {
    productsFilterResult = changeFilterOptions(productsFilterOptions, true, undefined, false);
    statusesFilterResult = changeFilterOptions(statusesFilterOptions, false, undefined, false, FilterAccountsOptions.HasActivationErrors);
  }
  if (checkedOption.label === FilterAccountsOptions.HasActivationErrors && checkedOption.checked) {
    statusesFilterResult = changeFilterOptions(statusesFilterOptions, false, false, true, FilterAccountsOptions.NoActivatedProducts);
  } else if (checkedOption.label === FilterAccountsOptions.HasActivationErrors && !checkedOption.checked) {
    const isActiveProductsChecked = productsFilterResult.some(checkbox => checkbox.checked);
    statusesFilterResult = changeFilterOptions(statusesFilterOptions, false, undefined, isActiveProductsChecked, FilterAccountsOptions.NoActivatedProducts);
  }
  if (checkedOption.label === FilterAccountsOptions.M365LinkedOnly && checkedOption.checked) {
    statusesFilterResult = changeFilterOptions(statusesFilterOptions, false, false, true, FilterAccountsOptions.M365UnlinkedOnly);
  } else if (checkedOption.label === FilterAccountsOptions.M365LinkedOnly && !checkedOption.checked) {
    statusesFilterResult = changeFilterOptions(statusesFilterOptions, false, undefined, false, FilterAccountsOptions.M365UnlinkedOnly);
  }
  if (checkedOption.label === FilterAccountsOptions.M365UnlinkedOnly && checkedOption.checked) {
    statusesFilterResult = changeFilterOptions(statusesFilterOptions, false, false, true, FilterAccountsOptions.M365LinkedOnly);
  } else if (checkedOption.label === FilterAccountsOptions.M365UnlinkedOnly && !checkedOption.checked) {
    statusesFilterResult = changeFilterOptions(statusesFilterOptions, false, undefined, false, FilterAccountsOptions.M365LinkedOnly);
  }
  return { productsFilter: productsFilterResult, statusesFilter: statusesFilterResult };
}

export const getActiveProductsFilterOptions = (accountId: number, accountsProducts: IAccountProducts[]): string[] => {
  let accountProductIndex = -1;
  let accountProductFamilyIndex = -1;
  let filterOptions: string[] = [];
  accountProductIndex = accountsProducts.findIndex((x: IAccountProducts) => x.accountId === accountId);
  if (accountProductIndex >= 0) {
    accountProductFamilyIndex = accountsProducts[accountProductIndex].productFamilies.findIndex((x: IProductFamily) => x.productType.includes(ProductFamily.ESSENTIALS_SERIVICES));
    if (accountProductFamilyIndex > -1) {
      accountsProducts[accountProductIndex].productFamilies[accountProductFamilyIndex].products.forEach(product => {
        filterOptions.push(product.name);
      });
    }
  }
  const uniquefilterOptions = filterOptions.filter((value, index) => filterOptions.indexOf(value) === index);
  return uniquefilterOptions;
};

export function computeChipsNamesFromFilters(filters: IAccountFilters): string[] {
  let values: string[] = [];
  if (filters.essSkus && filters.essSkus.length > 0) {
    filters.essSkus.forEach(element => {
      values.push(element.label);
    });
  }
  if (filters.csProdSkus) {
    values.push(ProductFamily.CONTENT_SHIELD);
  }
  if (filters.bbsProdSkus) {
    values.push(ProductFamily.BACKUP_APPLIANCES);
  }
  if (filters.hasSmbLogins) {
    values.push(FilterAccountsOptions.HasSMBLogins);
  }
  if (filters.hasErrors) {
    values.push(FilterAccountsOptions.HasActivationErrors);
  }
  if (filters.hasIbu) {
    values.push(FilterAccountsOptions.HasIntronisBackup);
  }
  if (filters.onlyM365Linked) {
    values.push(FilterAccountsOptions.M365LinkedOnly);
  }
  if (filters.onlyM365Unlinked) {
    values.push(FilterAccountsOptions.M365UnlinkedOnly);
  }
  if (filters.noProducts) {
    values.push(FilterAccountsOptions.NoActivatedProducts);
  }
  if (filters.associatedLogin) {
    values.push(FilterAccountsOptions.LoginUserAssociated);
  }
  return values;
}

export function computeStateFiltersFromCheckboxes(newActiveProducts: IFilterCheckbox[], newStatuses: IFilterCheckbox[], searchByAccountName: string | undefined, userAssociatedEmail: string | undefined): IAccountFilters {
  let newFilters: IAccountFilters = {};

  newFilters = updateFilterWithProductSkus(newFilters, newActiveProducts, true);
  const statusesLabels = newStatuses.flatMap(status => (status.checked ? status.label : []));
  statusesLabels.forEach(element => {
    newFilters = updateFilter(newFilters, element, true, userAssociatedEmail);
  });

  newFilters.name = searchByAccountName;
  return newFilters;
}

export function removeFilterFromState(current: IAccountFilters, name: string): IAccountFilters {
  let newFilters = { ...current };
  newFilters = updateFilter(newFilters, name, false);
  return newFilters;
}

function updateFilter(current: IAccountFilters, name: string, add: boolean, userAssociatedEmail?: string): IAccountFilters {
  let result = { ...current };
  const newValue = add ? true : undefined;
  switch (name) {
    case FilterAccountsOptions.NoActivatedProducts: {
      result.noProducts = newValue;
      break;
    }
    case FilterAccountsOptions.HasActivationErrors: {
      result.hasErrors = newValue;
      break;
    }
    case FilterAccountsOptions.M365LinkedOnly: {
      result.onlyM365Linked = newValue;
      break;
    }
    case FilterAccountsOptions.M365UnlinkedOnly: {
      result.onlyM365Unlinked = newValue;
      break;
    }
    case FilterAccountsOptions.HasSMBLogins: {
      result.hasSmbLogins = newValue;
      break;
    }
    case FilterAccountsOptions.HasIntronisBackup: {
      result.hasIbu = newValue;
      break;
    }
    case FilterAccountsOptions.LoginUserAssociated: {
      result.associatedLogin = newValue ? userAssociatedEmail : newValue;
      break;
    }
    case ProductFamily.BACKUP_APPLIANCES: {
      if (newValue === undefined) {
        result.bbsProdSkus = undefined;
        if (result.allProducts) {
          result.allProducts = newValue;
        }
      }
      break;
    }
    case ProductFamily.CONTENT_SHIELD: {
      if (newValue === undefined) {
        result.csProdSkus = undefined;
        if (result.allProducts) {
          result.allProducts = newValue;
        }
      }
      break;
    }
    default: {
      if (newValue === undefined && result.essSkus && result.essSkus.length > 0) {
        let index = result.essSkus.findIndex(x => x.label === name);
        if (index > -1) {
          result.essSkus.splice(index, 1);
          if (result.allProducts) {
            result.allProducts = newValue;
          }
        }
      }
    }
  }
  return result;
}

export function computeNewStatusesCheckboxesFromState(statuses: IFilterCheckbox[], filters: IAccountFilters): IFilterCheckbox[] {
  let newStatuses = [...statuses];
  if (filters !== undefined) {
    if (filters.hasSmbLogins !== undefined && filters.hasSmbLogins === true) {
      const index = statuses.findIndex(x => x.label === FilterAccountsOptions.HasSMBLogins);
      if (index > -1) {
        statuses[index].checked = true;
      }
    }
    if (filters.hasErrors !== undefined && filters.hasErrors === true) {
      const index = statuses.findIndex(x => x.label === FilterAccountsOptions.HasActivationErrors);
      if (index > -1) {
        statuses[index].checked = true;
      }
      const indexDisabled = statuses.findIndex(x => x.label === FilterAccountsOptions.NoActivatedProducts);
      if (indexDisabled > -1) {
        statuses[indexDisabled].disabled = true;
      }
    }
    if (filters.noProducts !== undefined && filters.noProducts === true) {
      const index = statuses.findIndex(x => x.label === FilterAccountsOptions.NoActivatedProducts);
      if (index > -1) {
        statuses[index].checked = true;
      }
      const indexDisabled = statuses.findIndex(x => x.label === FilterAccountsOptions.HasActivationErrors);
      if (indexDisabled > -1) {
        statuses[indexDisabled].disabled = true;
      }
    }
    if (filters.hasIbu !== undefined && filters.hasIbu === true) {
      const index = statuses.findIndex(x => x.label === FilterAccountsOptions.HasIntronisBackup);
      if (index > -1) {
        statuses[index].checked = true;
      }
    }
    if (filters.onlyM365Linked !== undefined && filters.onlyM365Linked === true) {
      const index = statuses.findIndex(x => x.label === FilterAccountsOptions.M365LinkedOnly);
      if (index > -1) {
        statuses[index].checked = true;
      }
      const indexDisabled = statuses.findIndex(x => x.label === FilterAccountsOptions.M365UnlinkedOnly);
      if (indexDisabled > -1) {
        statuses[indexDisabled].disabled = true;
      }
    }
    if (filters.onlyM365Unlinked !== undefined && filters.onlyM365Unlinked === true) {
      const index = statuses.findIndex(x => x.label === FilterAccountsOptions.M365UnlinkedOnly);
      if (index > -1) {
        statuses[index].checked = true;
      }
      const indexDisabled = statuses.findIndex(x => x.label === FilterAccountsOptions.M365LinkedOnly);
      if (indexDisabled > -1) {
        statuses[indexDisabled].disabled = true;
      }
    }
    if (filters.associatedLogin !== undefined && !isStringNullOrEmpty(filters.associatedLogin)) {
      const index = statuses.findIndex(x => x.label === FilterAccountsOptions.LoginUserAssociated);
      if (index > -1) {
        statuses[index].checked = true;
      }
    }
    if ((filters.essSkus && filters.essSkus.length > 0) || filters.csProdSkus || filters.bbsProdSkus) {
      const indexDisabled = statuses.findIndex(x => x.label === FilterAccountsOptions.NoActivatedProducts);
      if (indexDisabled > -1) {
        statuses[indexDisabled].disabled = true;
      }
    }
  }
  return newStatuses;
}

export function computeNewActiveProductsCheckboxesFromState(products: IFilterCheckbox[], filters: IAccountFilters): IFilterCheckbox[] {
  let newProducts: IFilterCheckbox[] = JSON.parse(JSON.stringify(products));
  if (filters !== undefined) {
    if (filters.allProducts !== undefined && filters.allProducts === true) {
      const index = newProducts.findIndex(x => x.label === FilterAccountsOptions.AllActivatedProductsServices);
      if (index > -1) {
        newProducts[index].checked = true;
      }
    }
    if (filters.essSkus !== undefined && filters.essSkus.length > 0) {
      filters.essSkus.forEach(element => {
        const index = newProducts.findIndex(x => x.label === element.label);
        if (index > -1) {
          newProducts[index].checked = true;
        }
      });
    }
    if (filters.csProdSkus !== undefined) {
      const index = newProducts.findIndex(x => x.label === ProductFamily.CONTENT_SHIELD);
      if (index > -1) {
        newProducts[index].checked = true;
      }
    }
    if (filters.bbsProdSkus !== undefined) {
      const index = newProducts.findIndex(x => x.label === ProductFamily.BACKUP_APPLIANCES);
      if (index > -1) {
        newProducts[index].checked = true;
      }
    }
  }

  return newProducts;
}
function updateFilterWithProductSkus(current: IAccountFilters, newActiveProducts: IFilterCheckbox[], add: boolean): IAccountFilters {
  let result = { ...current };
  newActiveProducts.forEach(element => {
    if (element.checked) {
      switch (element.label) {
        case FilterAccountsOptions.AllActivatedProductsServices: {
          result.allProducts = element.checked;
          break;
        }
        case ProductFamily.CONTENT_SHIELD: {
          result.csProdSkus = element.value;
          break;
        }
        case ProductFamily.BACKUP_APPLIANCES: {
          result.bbsProdSkus = element.value;
          break;
        }
        default: {
          if (element.value) {
            const newSku = { sku: element.value, label: element.label };
            if (result.essSkus !== undefined) {
              result.essSkus.push(newSku);
            } else {
              result.essSkus = [newSku];
            }
          }
          break;
        }
      }
    }
  });
  return result;
}

export function shouldDisableFilter(loadingAccountId: number | undefined, loadingPartnerAccounts: boolean, loadingOrdersForAccountId: number): boolean {
  return loadingOrdersForAccountId !== 0 || (loadingAccountId !== undefined && loadingAccountId !== 0) || loadingPartnerAccounts === true;
}
