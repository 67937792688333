import React, { useEffect, useState } from "react";
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, DataTable, DataTableColumn as Column } from "@barracuda/bds-core";
import Grid from "@barracuda/bds-core/dist/Grid";
import Pager from "@barracuda/bds-core/dist/DataTable/Pager";
import { process } from "@progress/kendo-data-query";
import IAccountM365DomainList from "../../models/IAccountM365DomainList";
import { useDispatch } from "react-redux";
import IAccount from "../../models/IAccount";
import { getButtonCount } from "../../utility";
import { Backdrop, useMediaQuery } from "@material-ui/core";
import { fetchM365AuthAction, revokeM365AuthAction, fetchM365DomainListAction } from "../../actions/accountActions";

interface ISubmitDialogProps {
  onCancel: () => void;
  showDialog: boolean;
  authErrMsg: string;
  authInProgress: boolean;
  authFail: boolean;
  authDomainList: IAccountM365DomainList;
  fetchDomainListErrMsg: string;
  fetchDomainListSucceed: boolean;
  fetchDomainListFail: boolean;
  fetchDomainListInProgress: boolean;
  account: IAccount | undefined;
}

const M365AuthLinkDialog: React.FC<ISubmitDialogProps> = ({ showDialog, onCancel, authErrMsg, authInProgress, authFail, authDomainList, fetchDomainListErrMsg, fetchDomainListSucceed, fetchDomainListFail, fetchDomainListInProgress, account }) => {
  const dispatch = useDispatch();
  const [tableState, setTableState] = useState({ take: 5, skip: 0 });
  const [pagingErrMsg, setPagingErrMsg] = useState("");
  const [buttonCount, setButtonCount] = useState(5);
  const [isLoadingDomainListPage, setIsLoadingDomainListPage] = useState(false);
  const [isUnlinkInProgress, setIsUnlinkInProgress] = useState(false);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const dataState = {
    skip: tableState?.skip,
    take: tableState?.take,
    group: [],
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "Domian",
        field: "domain",
        show: true,
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process([], dataState as any),
  });

  const fetchM365DomainList = (skip: number, take: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(fetchM365DomainListAction(account, skip, take));
      resolve(result);
    });

  const dataStateChange = (e: any): void => {
    setIsLoadingDomainListPage(true);
    setPagingErrMsg("");
    fetchM365DomainList(e.dataState.skip, e.dataState.take)
      .then(result => {
        if (result) {
          for (let i = result.data.length; i < e.dataState.take; i++) {
            result.data.push({ id: "" });
          }
          const newDataState = { ...dataState, skip: e.dataState.skip, take: e.dataState.take };
          setTableState({ take: gridState.dataState.take, skip: gridState.dataState.skip });
          setGridState({
            dataState: newDataState,
            dataResult: { data: result?.data, total: result?.totalCount },
          });
        }
      })
      .catch(async (error: Error) => {
        setPagingErrMsg(error.message);
      })
      .finally(() => {
        setIsLoadingDomainListPage(false);
      });
  };

  const fetchM365Auth = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(fetchM365AuthAction(account));
      resolve(result);
    });

  const revokeM365Auth = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(revokeM365AuthAction(account));
      resolve(result);
    });

  const handleOnUnlinkM365AuthLinkDialog = () => {
    setIsUnlinkInProgress(true);
    if (account) {
      revokeM365Auth(account)
        .then(success => {
          if (success.status === "success") {
            fetchM365Auth(account);
          }
        })
        .finally(() => {
          onCancel();
        });
    } else {
      onCancel();
    }
  };

  useEffect(() => {
    if (fetchDomainListSucceed) {
      for (let i = authDomainList.data.length; i < tableState.take; i++) {
        authDomainList.data.push({ id: "" });
      }
      setGridState({
        dataState: {
          skip: 0,
          take: 5,
          group: [],
          collapsedGroups: [],
          selectedItem: "any",
          lastSelectedIndex: 0,
          columns: [
            {
              title: "Domian",
              field: "id",
              show: true,
            },
          ],
        },
        dataResult: { data: authDomainList.data, total: authDomainList.totalCount },
      });
    }
  }, [fetchDomainListSucceed, authDomainList, tableState.take]);

  useEffect(() => {
    setButtonCount(getButtonCount(authDomainList?.totalCount, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [authDomainList, gridState.dataState.take, responsiveViewPortTriggerMin]);

  return (
    <Dialog className="m365AuthLinkDialog" data-testid="m365AuthLinkDialog" disableBackdropClick={true} open={showDialog} onClose={onCancel} maxWidth={false} style={{ zIndex: 4002 }}>
      <div className="" style={{ width: "500px" }}>
        <DialogTitle data-testid="m365AuthLinkDialogTitle" id="alert-dialog-title">
          {(authInProgress || authFail) && (
            <Typography data-testid="m365LinkDialogTitle" variant="h5">
              Link M365 Account
            </Typography>
          )}
          {(fetchDomainListSucceed || fetchDomainListFail || fetchDomainListInProgress) && (
            <Typography data-testid="m365LinkDialogDomainTitle" variant="h5">
              M365 Associated Domains
            </Typography>
          )}
          {fetchDomainListSucceed && (
            <Typography data-testid="m365LinkDialogDomainSubTitle" variant="subtitle2">
              The following list displays the domains linked with your M365 Account. To unlink the M365 account, click the Unlink M365 Account button.
            </Typography>
          )}
          {fetchDomainListFail && (
            <Typography data-testid="m365LinkDialogDomainFailSubTitle" style={{ color: "#000006" }} variant="subtitle2">
              The Domains List cannot be accessed because of one of following issues:
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <div className="DialogContentDiv" style={{ padding: 15 }}>
            <Grid container item xs={12} direction="row">
              <Grid container item xs={12} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                {authInProgress && (
                  <Typography data-testid="m365LinkDialogAuthInProgText" variant="subtitle2">
                    M365 Domain authorization in progress
                  </Typography>
                )}
              </Grid>

              <Grid container item xs={12} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                {authFail && (
                  <Typography data-testid="m365inkDialogAuthErrorText" variant="subtitle2">
                    {authErrMsg}
                  </Typography>
                )}
              </Grid>

              <Grid container item xs={12} direction={"row"} alignItems={"center"} justifyContent={"flex-start"}>
                {fetchDomainListFail && (
                  <div>
                    <ul>
                      <li>
                        <Typography data-testid="m365LinkDialogDomainFailText1" style={{ color: "#000006" }} variant="subtitle2">
                          An error occurred communicating with M365.
                        </Typography>
                      </li>
                      <li>
                        <Typography data-testid="m365LinkDialogDomainFailText2" style={{ color: "#000006" }} variant="subtitle2">
                          Permission to read domain information from the M365 account failed.
                        </Typography>
                      </li>
                    </ul>
                    <Typography data-testid="m365LinkDialogDomainFailAction" style={{ color: "#000006" }} variant="subtitle2">
                      You can choose CLOSE and try the DOMAIN LIST button later. Or click the UNLINK M365 ACCOUNT button to unlink the current M365 Account, then link the M365 account again to restore access.
                    </Typography>
                  </div>
                )}
              </Grid>

              <Grid container item xs={12} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                {pagingErrMsg && (
                  <Typography data-testid="m365inkDialogDomainErrorText" variant="subtitle2">
                    An error occurred communicating with M365.
                  </Typography>
                )}
                {!pagingErrMsg && (
                  <Typography data-testid="m365inkDialogDomainEmptyErrorText" variant="subtitle2">
                    &nbsp;
                  </Typography>
                )}
              </Grid>

              <Grid container item xs={12} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                {(authInProgress || fetchDomainListInProgress) && <CircularProgress data-testid="authAndDomainInProgress" />}
              </Grid>
            </Grid>

            {!fetchDomainListInProgress && fetchDomainListSucceed && (
              <div>
                <Backdrop data-testid="loadingDomainList" className={"parentOpacity"} open={isLoadingDomainListPage} style={{ position: "absolute", zIndex: 4000 }}>
                  <CircularProgress data-testid="loadingDomainListProgress" size="80px" style={{ zIndex: 4001 }} />
                </Backdrop>
                <DataTable
                  className={"M365DomainTable noScrollbar noBorders"}
                  data={gridState.dataResult}
                  resizable
                  pageConfig={{
                    pageable: {
                      buttonCount: buttonCount,
                    },
                    skip: tableState?.skip,
                    take: tableState?.take,
                    total: authDomainList.totalCount,
                  }}
                  pager={gridState.dataResult.data.length > 0 && Pager}
                  onDataStateChange={dataStateChange}
                  {...(gridState.dataState as any)}
                >
                  <Column key={"m365Domain"} field={"id"} title={"Domain"} minResizableWidth={30} resizable={false} headerClassName={"m365domainHeader"} />
                </DataTable>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          {authFail && (
            <Grid container item xs={12} direction="row" alignItems={"center"} justifyContent={"center"}>
              <Button data-testid="m365LinkDialogCancelAuthFails" variant="contained" color="primary" size="large" onClick={onCancel}>
                OK
              </Button>
            </Grid>
          )}
          {(fetchDomainListFail || fetchDomainListSucceed) && (
            <Grid container item xs={12} direction="row">
              <Grid container item xs={7} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                <Button data-testid="m365LinkDialogUnlinkBtn" isLoading={isUnlinkInProgress} variant="contained" color="secondary" size={"large"} onClick={handleOnUnlinkM365AuthLinkDialog}>
                  UNLINK M365 ACCOUNT
                </Button>
              </Grid>
              <Grid container item xs={5} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                <Button data-testid="m365LinkDialogConfirmDomainList" variant="contained" color="primary" size={"large"} onClick={onCancel}>
                  CLOSE
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default M365AuthLinkDialog;
