import { DialogTitle, DialogActions, Button, DialogContent, Typography, Dialog, Link } from "@barracuda/bds-core";
import React from "react";
import IInvoice from "../../models/Invoices/IInvoice";

interface IEmailInvoiceProps {
  invoice: IInvoice;
  onCancel: () => void;
  onSendEmail: (invoice: IInvoice) => void;
  showDialog: boolean;
  isActionInProgress: boolean;
  onBillingInformationLink: (event: React.SyntheticEvent) => void;
}

const EmailInvoice: React.FC<IEmailInvoiceProps> = ({ invoice, showDialog, onCancel, onSendEmail, isActionInProgress, onBillingInformationLink }) => {
  return (
    <Dialog className={"EmailInvoiceDialog"} disableEscapeKeyDown={isActionInProgress} data-testid="emailInvoiceDialog" open={showDialog} onClose={onCancel} disableBackdropClick={true} maxWidth={false}>
      <div style={{ width: "400px" }}>
        <DialogTitle data-testid="emailInvoiceDialogTitle" id="alert-dialog-title">
          Email Invoice
        </DialogTitle>
        <DialogContent>
          <Typography data-testid="emailInvoiceMessage" variant="body1">
            Invoice <b>#{invoice.number}</b> shall be sent to the current email address provided in your{" "}
            <Link
              data-testid="emailInvoiceBillingInformationLink"
              href="#"
              onClick={(event: React.SyntheticEvent) => {
                onBillingInformationLink(event);
              }}
              variant="body1"
            >
              Billing Information
            </Link>
            .
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <Button data-testid="emailInvoiceCloseButton" color="secondary" disabled={isActionInProgress} type={"submit"} onClick={onCancel}>
            CANCEL
          </Button>
          <Button
            data-testid="emailInvoiceSendEmailButton"
            type={"submit"}
            isLoading={isActionInProgress}
            disabled={isActionInProgress}
            onClick={() => {
              onSendEmail(invoice);
            }}
          >
            EMAIL NOW
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default EmailInvoice;
