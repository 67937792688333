import { CancelTokenSource } from "axios";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { addIntegrationsFromState, removeIntegrationsFromState } from "../../businessLogic/integrations";
import IAccount from "../../models/IAccount";
import IConnectWiseIntegrationInfo from "../../models/Integrations/IConnectWiseIntegrationInfo";
import IIntegration from "../../models/Integrations/IIntegration";
import IIntegrationSetup from "../../models/Integrations/IIntegrationSetup";
import IntegrationType from "../../models/IntegrationType";
import mspService from "../../service/mspService";
import { IAppState } from "../../store/store";
import { handleError } from "../actionsErrorHandler";
import { cancelGeneralActionTokenAndCreateNew, cancelLoadIntegrationExtraInfoActionTokenAndCreateNew } from "../cancelAction";

export enum IntegrationsActionTypes {
  SET_SELECTED_INTEGRATION = "SET_SELECTED_INTEGRATION",
  SET_LOADED_INTEGRATION = "SET_LOADED_INTEGRATION",
  SET_SELECTED_INTEGRATIONS_TAB = "SET_SELECTED_INTEGRATIONS_TAB",
  GET_INTEGRATIONS = "GET_INTEGRATIONS",
  SET_GET_INTEGRATIONS_CANCELED = "SET_GET_INTEGRATIONS_CANCELED",
  GET_CONNECTWISE_INTEGRATION_INFO = "GET_CONNECTWISE_INTEGRATION_INFO",
  SET_GET_CONNECTWISE_INTEGRATION_INFO_CANCELED = "SET_GET_CONNECTWISE_INTEGRATION_INFO_CANCELED",
  DELETE_INTEGRATION = "DELETE_INTEGRATION",
  ADD_INTEGRATION = "ADD_INTEGRATION",
  SET_CANCEL_LOAD_INTEGRATION_EXTRA_INFO_TOKEN = "SET_CANCEL_LOAD_INTEGRATION_EXTRA_INFO_TOKEN",
  EDIT_INTEGRATION = "EDIT_INTEGRATION",
}

export interface ISetSelectedIntegrationAction {
  type: IntegrationsActionTypes.SET_SELECTED_INTEGRATION;
  selectedIntegration: IIntegration;
}

export interface ISetLoadedIntegrationAction {
  type: IntegrationsActionTypes.SET_LOADED_INTEGRATION;
  loadedIntegration: IIntegration;
}

export interface ISetSelectedIntegrationsTabAction {
  type: IntegrationsActionTypes.SET_SELECTED_INTEGRATIONS_TAB;
  selectedIntegrationsTabName: string;
}

export interface IGetIntegrationsAction {
  type: IntegrationsActionTypes.GET_INTEGRATIONS;
  integrations: IIntegration[];
  loadingIntegrations: boolean;
  integrationsLoaded: boolean;
}

export interface ICancelGetIntegrationsAction {
  type: IntegrationsActionTypes.SET_GET_INTEGRATIONS_CANCELED;
  loadingIntegrationsCanceled: boolean;
}

export interface IGetConnectWiseIntegrationInfoAction {
  type: IntegrationsActionTypes.GET_CONNECTWISE_INTEGRATION_INFO;
  cwIntegrationInfo: IConnectWiseIntegrationInfo | undefined;
  loadingConnectWiseIntegrationInfo: boolean;
}

export interface ISetCancelLoadIntegrationExtraInfoToken {
  type: IntegrationsActionTypes.SET_CANCEL_LOAD_INTEGRATION_EXTRA_INFO_TOKEN;
  loadIntegrationExtraInfoCancellationTokenSource: CancelTokenSource;
}

export interface ICancelGetConnectWIseIntegrationAction {
  type: IntegrationsActionTypes.SET_GET_CONNECTWISE_INTEGRATION_INFO_CANCELED;
  loadingConnectWiseIntegrationsCanceled: boolean;
}

export interface IAddIntegrationAction {
  type: IntegrationsActionTypes.ADD_INTEGRATION;
  integrations: IIntegration[];
  selectedIntegration: IIntegration | undefined;
  loadedIntegration: IIntegration | undefined;
}
export interface IDeleteIntegrationAction {
  type: IntegrationsActionTypes.DELETE_INTEGRATION;
  integrations: IIntegration[];
  selectedIntegration: IIntegration | undefined;
  loadedIntegration: IIntegration | undefined;
}

export interface IEditIntegrationAction {
  type: IntegrationsActionTypes.EDIT_INTEGRATION;
}

export type IntegrationsActions = ISetSelectedIntegrationAction | ISetLoadedIntegrationAction | ISetSelectedIntegrationsTabAction | IGetIntegrationsAction | ICancelGetIntegrationsAction | IGetConnectWiseIntegrationInfoAction | ISetCancelLoadIntegrationExtraInfoToken | ICancelGetConnectWIseIntegrationAction | IDeleteIntegrationAction | IAddIntegrationAction | IEditIntegrationAction;

export const setSelectedIntegrationAction: ActionCreator<ThunkAction<any, IAppState, null, ISetSelectedIntegrationAction>> = (selectedIntegration: IIntegration) => (dispatch: Dispatch) => dispatch({ type: IntegrationsActionTypes.SET_SELECTED_INTEGRATION, selectedIntegration });

export const setLoadedIntegrationAction: ActionCreator<ThunkAction<any, IAppState, null, ISetLoadedIntegrationAction>> = (loadedIntegration: IIntegration) => (dispatch: Dispatch) => dispatch({ type: IntegrationsActionTypes.SET_LOADED_INTEGRATION, loadedIntegration });

export const setSelectedIntegrationsTabNameAction: ActionCreator<ThunkAction<any, IAppState, null, ISetSelectedIntegrationsTabAction>> = (selectedIntegrationsTabName: string) => (dispatch: Dispatch) => dispatch({ type: IntegrationsActionTypes.SET_SELECTED_INTEGRATIONS_TAB, selectedIntegrationsTabName });

export const loadIntegrationsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetIntegrationsAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const currentIntegrations = getState().integrationsState.integrations;
    try {
      const { apiUrl } = getState().generalState;
      const { mspAccountLoggedIn } = getState().generalState;
      dispatch({
        type: IntegrationsActionTypes.GET_INTEGRATIONS,
        integrations: currentIntegrations,
        loadingIntegrations: true,
        integrationsLoaded: false,
      });
      dispatch({
        type: IntegrationsActionTypes.SET_GET_INTEGRATIONS_CANCELED,
        loadingIntegrationsCanceled: false,
      });
      const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
      const integrations = await mspService.loadIntegrations(apiUrl, mspAccountLoggedIn.id, newCancelTokenSource.token);
      let updatedIntegrations: IIntegration[] = [];
      currentIntegrations.forEach((x: IIntegration) => {
        const index = integrations.integrations.findIndex((y: IIntegration) => y.type === x.type);
        if (index > -1) {
          updatedIntegrations.push(integrations.integrations[index]);
        } else {
          updatedIntegrations.push(x);
        }
      });
      dispatch({
        type: IntegrationsActionTypes.GET_INTEGRATIONS,
        integrations: updatedIntegrations,
        loadingIntegrations: false,
        integrationsLoaded: true,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: IntegrationsActionTypes.GET_INTEGRATIONS,
            integrations: currentIntegrations,
            loadingIntegrations: false,
            integrationsLoaded: false,
          });
        },
        () => {
          dispatch({
            type: IntegrationsActionTypes.SET_GET_INTEGRATIONS_CANCELED,
            loadingIntegrationsCanceled: true,
          });
        },
      );
    }
  };
};

export const setIntegrationsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetIntegrationsAction>> = (integrations: IIntegration) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      type: IntegrationsActionTypes.GET_INTEGRATIONS,
      integrations: integrations,
      loadingIntegrations: false,
      integrationsLoaded: false,
    });
  };
};

export const loadConnectWiseIntegrationInfoAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetConnectWiseIntegrationInfoAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const { mspAccountLoggedIn } = getState().generalState;
      dispatch({
        type: IntegrationsActionTypes.GET_CONNECTWISE_INTEGRATION_INFO,
        cwIntegrationInfo: undefined,
        loadingConnectWiseIntegrationInfo: true,
      });
      dispatch({
        type: IntegrationsActionTypes.SET_GET_CONNECTWISE_INTEGRATION_INFO_CANCELED,
        loadingConnectWiseIntegrationsCanceled: false,
      });
      const newCancelTokenSource = cancelLoadIntegrationExtraInfoActionTokenAndCreateNew(getState, dispatch);
      const cwIntegrationInfo = await mspService.loadConnectWiseIntegrationInfo(apiUrl, mspAccountLoggedIn.id, newCancelTokenSource.token);
      dispatch({
        type: IntegrationsActionTypes.GET_CONNECTWISE_INTEGRATION_INFO,
        cwIntegrationInfo: cwIntegrationInfo,
        loadingConnectWiseIntegrationInfo: false,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: IntegrationsActionTypes.GET_CONNECTWISE_INTEGRATION_INFO,
            cwIntegrationInfo: undefined,
            loadingConnectWiseIntegrationInfo: false,
          });
        },
        () => {
          dispatch({
            type: IntegrationsActionTypes.SET_GET_CONNECTWISE_INTEGRATION_INFO_CANCELED,
            loadingConnectWiseIntegrationsCanceled: true,
          });
        },
      );
    }
  };
};

export const setCancelLoadIntegrationExtraInfoTokenAction: ActionCreator<ThunkAction<any, IAppState, null, ISetCancelLoadIntegrationExtraInfoToken>> = (loadIntegrationExtraInfoCancellationTokenSource: CancelTokenSource) => (dispatch: Dispatch) => dispatch({ type: IntegrationsActionTypes.SET_CANCEL_LOAD_INTEGRATION_EXTRA_INFO_TOKEN, loadIntegrationExtraInfoCancellationTokenSource });

export const deleteIntegrationAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IDeleteIntegrationAction>> = (account: IAccount, integration: IIntegration) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (integration.id) {
      const { integrations } = getState().integrationsState;
      try {
        const { apiUrl } = getState().generalState;
        await mspService.deleteIntegration(apiUrl, account.id);

        const nexStateIntegrations = removeIntegrationsFromState(integrations, integration.id);

        dispatch({
          type: IntegrationsActionTypes.DELETE_INTEGRATION,
          integrations: nexStateIntegrations,
          selectedIntegration: undefined,
          loadedIntegration: undefined,
        });
        if (integration.type === IntegrationType.ConnectWise) {
          dispatch({
            type: IntegrationsActionTypes.GET_CONNECTWISE_INTEGRATION_INFO,
            cwIntegrationInfo: undefined,
            loadingConnectWiseIntegrationInfo: false,
          });
        }
        return true;
      } catch (err) {
        handleError(err, dispatch, () => {
          /*nothing to do*/
        });
        return false;
      }
    }
    return false;
  };
};

export const addIntegrationAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAddIntegrationAction>> = (account: IAccount, integrationSetup: IIntegrationSetup) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { integrations } = getState().integrationsState;
    try {
      const { apiUrl } = getState().generalState;
      const result = await mspService.addIntegration(apiUrl, account.id, integrationSetup);
      const nexStateIntegrations = addIntegrationsFromState(integrations, result.id);

      dispatch({
        type: IntegrationsActionTypes.ADD_INTEGRATION,
        integrations: nexStateIntegrations,
        selectedIntegration: nexStateIntegrations[0],
        loadedIntegration: undefined,
      });
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {});
      return false;
    }
  };
};

export const editIntegrationAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IEditIntegrationAction>> = (account: IAccount, integrationSetup: IIntegrationSetup) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      await mspService.editIntegration(apiUrl, account.id, integrationSetup);
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {});
      return false;
    }
  };
};
