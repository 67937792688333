import { DialogTitle, DialogActions, Button, DialogContent, Grid, Link, Typography, Dialog } from "@barracuda/bds-core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getAccountExcludedUsers } from "../../../actions/financeActions";
import { cancelCurrentAction } from "../../../actions/generalActions";
import IAccountsWithExclusions from "../../../models/Invoices/IAccountsWithExclusions";
import IInvoice from "../../../models/Invoices/IInvoice";
import { getDateToDisplay } from "../../../utility";
import ExcludedUsersTable from "./ExcludedUsersTable";
import ExclusionsTable from "./ExclusionsTable";

interface IViewExclusionsProps {
  invoice: IInvoice;
  onCancel: () => void;
  showDialog: boolean;
}

const ViewExclusions: React.FC<IViewExclusionsProps> = ({ invoice, showDialog, onCancel }) => {
  const dispatch = useDispatch();
  const [isViewAccounts, setIsViewAccounts] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState<IAccountsWithExclusions | undefined>();

  const onAccountSelected = (account: IAccountsWithExclusions): void => {
    setIsViewAccounts(false);
    setSelectedAccount(account);
    dispatch(getAccountExcludedUsers(account.accountId, invoice, 0, 10));
  };

  const handleBackToAccounts = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(cancelCurrentAction());
    setIsViewAccounts(true);
    setSelectedAccount(undefined);
  };

  return (
    <Dialog data-testid="viewExclusionsDialog" open={showDialog} onClose={onCancel} disableBackdropClick={true} minWidth={700} maxWidth={"sm"} style={{ zIndex: 4002 }} disableEnforceFocus>
      <div className="viewExclusions">
        <DialogTitle data-testid="viewExclusionsDialogTitle" id="alert-dialog-title">
          {!isViewAccounts && selectedAccount ? "Exclusions for " + selectedAccount.accountName : "Exclusions"}
        </DialogTitle>
        <DialogContent>
          <div>
            <Grid container>
              <Grid item container xs={12}>
                <Grid item xs={6} container justifyContent="flex-start" style={{ "align-content": "end" }}>
                  {!isViewAccounts && (
                    <Link
                      data-testid="excludedAccountPanelLnk"
                      href="#"
                      onClick={(event: React.SyntheticEvent) => {
                        handleBackToAccounts(event);
                      }}
                      variant="body1"
                    >
                      {"< Back to Accounts"}
                    </Link>
                  )}
                </Grid>
                <Grid item xs={6} container direction={"column"} justifyContent="flex-end" style={{ "align-content": "flex-end" }}>
                  <Typography data-testid="exclusionListSubtitle" variant="caption" style={{ "align-self": "end" }}>
                    EXCLUSIONS LIST DATE
                  </Typography>
                  <Typography data-testid="exclusionListDate" variant="h5" style={{ "align-self": "end" }}>
                    {getDateToDisplay(invoice.date)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                {!isViewAccounts && selectedAccount ? (
                  <div data-testid="accountsExcludedUsersTable">
                    <ExcludedUsersTable account={selectedAccount} invoice={invoice} />
                  </div>
                ) : (
                  <div data-testid="accountsWithExclusionsTable">
                    <ExclusionsTable onAccountSelected={onAccountSelected} />
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <Button data-testid="viewExclusionsDialogCloseButton" type={"submit"} onClick={onCancel}>
            CLOSE
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ViewExclusions;
