import produce from "immer";
import IUser from "../models/IUser";

export function deleteUserFromState(usersToDisplay: IUser[], user: IUser) {
  const userToDeleteIndex = usersToDisplay.findIndex(value => value.id === user.id);
  if (userToDeleteIndex < 0) {
    throw new Error(`No user with id ${user.id}`);
  }
  const nextStateUsers = produce(usersToDisplay, draft => {
    draft.splice(userToDeleteIndex, 1);
  });
  return nextStateUsers;
}
