import { Reducer } from "redux";
import { UserActionTypes, UserActions } from "../actions/userActions";
import IAccount from "../models/IAccount";
import IUser from "../models/IUser";
import IEntitlement from "../models/IEntitlement";
import { State } from "@progress/kendo-data-query";

export interface IUserState {
  usersToDisplay: IUser[];
  selectedUser?: IUser;
  userAccounts: IAccount[];
  tableState: State;
  loadingUsers: boolean;
  entitlements: IEntitlement[];
  userEntitlements: IEntitlement[];
  loadingEntitlements: boolean;
  loadingUsersCanceledForAccountId: number;
  loadingUsersExtraInfo: boolean;
}

const initialUserState: IUserState = {
  usersToDisplay: [],
  selectedUser: undefined,
  userAccounts: [],
  tableState: { sort: [{ field: "email", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  loadingUsers: false,
  entitlements: [],
  userEntitlements: [],
  loadingEntitlements: false,
  loadingUsersCanceledForAccountId: 0,
  loadingUsersExtraInfo: false,
};

export const userReducer: Reducer<IUserState, UserActions> = (state = initialUserState, action: UserActions) => {
  switch (action.type) {
    case UserActionTypes.GET_ACCOUNT_USERS: {
      return {
        ...state,
        usersToDisplay: action.usersToDisplay,
        loadingUsers: action.loadingUsers,
      };
    }
    case UserActionTypes.SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: action.selectedUser,
      };
    }
    case UserActionTypes.SET_TABLE_PROPS: {
      return {
        ...state,
        tableState: action.tableState,
      };
    }
    case UserActionTypes.ADD_USER:
    case UserActionTypes.EDIT_USER:
    case UserActionTypes.DELETE_USER: {
      return {
        ...state,
        usersToDisplay: action.usersToDisplay,
      };
    }
    case UserActionTypes.GET_ENTITLEMENTS: {
      return {
        ...state,
        userEntitlements: action.userEntitlements,
        loadingEntitlements: action.loadingEntitlements,
      };
    }
    case UserActionTypes.GET_ENTITLEMENTS_FOR_ACCOUNT: {
      return {
        ...state,
        entitlements: action.entitlements,
        loadingEntitlements: action.loadingEntitlements,
      };
    }
    case UserActionTypes.SET_LOADING_USERS_CANCELED: {
      return {
        ...state,
        loadingUsersCanceledForAccountId: action.loadingUsersCanceledForAccountId,
      };
    }
    case UserActionTypes.SET_LOADING_USERS_EXTRA_INFO: {
      return {
        ...state,
        loadingUsersExtraInfo: action.loadingUsersExtraInfo,
      };
    }
    default:
      return state;
  }
};
