import React from "react";
import ProductStatusIcon from "../ProductStatusIcon";

const AssignedProductsStatusCell = (props: any) => {
  return (
    <td>
      <ProductStatusIcon hasPendingSerials={props.dataItem.hasPendingSerials} hasSerials={props.dataItem.hasSerials} noOfErrors={props.dataItem.totalErrors} showActivatedIcon={true} showAvailableIcon={true} noOfRmaSerials={props.dataItem.noOfRmaSerials}></ProductStatusIcon>
    </td>
  );
};

export default AssignedProductsStatusCell;
