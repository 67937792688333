import { Dispatch } from "redux";
import { ApiError } from "../errors/ApiError";
import { CommunicationError } from "../errors/CommunicationError";
import { OperationCanceledError } from "../errors/OperationCanceledError";
import ActionMessageType from "../models/ActionMessageType";
import { GeneralActionTypes } from "./generalActions";

export const GENERAL_ERROR_MESSGE = "Something went wrong. Please refresh the page and try again.";
export const COMMUNICATION_ERROR_MESSAGE = "Could not communicate with server. Please refresh the page and try again.";
export const RESSELLER_ERROR_CODE = "error_reseller";

export function handleError(
  error: any,
  dispatch: Dispatch,
  callback: () => void,
  canceledOperationCallback: () => void = () => {
    /* no action needed*/
  },
  displayInSnackBar: boolean = false,
) {
  if (error instanceof CommunicationError) {
    displayInSnackBar ? dispatch({ type: GeneralActionTypes.SET_SNACKBAR_MESSAGE, snackBarMessage: { message: COMMUNICATION_ERROR_MESSAGE, type: ActionMessageType.Error } }) : dispatch({ type: GeneralActionTypes.ERROR, errorMessage: COMMUNICATION_ERROR_MESSAGE });
  } else if (error instanceof ApiError) {
    handleApiError(error, dispatch, displayInSnackBar);
  }
  if (!(error instanceof OperationCanceledError)) {
    callback();
  } else {
    canceledOperationCallback();
  }
}

function handleApiError(error: ApiError, dispatch: Dispatch, displayInSnackBar: boolean) {
  let customError = GENERAL_ERROR_MESSGE;
  if (error.errorCode !== undefined && error.errorCode === RESSELLER_ERROR_CODE) {
    dispatch({ type: GeneralActionTypes.ERROR_AFTER_LOGIN, apiError: error });
    return;
  } else if (error?.message !== undefined && error?.errorMessage !== undefined && error?.errorMessage !== "") {
    customError = error.errorMessage;
  }
  displayInSnackBar ? dispatch({ type: GeneralActionTypes.SET_SNACKBAR_MESSAGE, snackBarMessage: { message: customError, type: ActionMessageType.Error } }) : dispatch({ type: GeneralActionTypes.ERROR, errorMessage: customError });
}
