import { CancelTokenSource } from "axios";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApiError } from "../errors/ApiError";
import ActionMessageType from "../models/ActionMessageType";
import IAccount from "../models/IAccount";
import ILoggedUser from "../models/ILoggedUser";
import ISnackBarMessage from "../models/ISnackBarMessage";
import MspType from "../models/MspType";
import { IGeneralState } from "../reducers/generalReducer";
import mspService from "../service/mspService";
import { IAppState } from "../store/store";
import { TokenStorage } from "../TokenStorage";
import { accountIsBillingAggregator } from "../Utilities/accountsHelper";
import { isTokenExpiredError, matchApiToEcho } from "../utility";
import { AccountActionTypes } from "./accountActions";
import { handleError } from "./actionsErrorHandler";

export enum GeneralActionTypes {
  GET_API_ECHO_URL = "GET_API_ECHO_URL",
  GENERATE_CUSTOM_ACCESS_TOKEN = "GENERATE_CUSTOM_ACCESS_TOKEN",
  REFRESH_CUSTOM_ACCESS_TOKEN = "REFRESH_CUSTOM_ACCESS_TOKEN",
  LOAD_LOGGEDIN_MSP = "LOAD_LOGGEDIN_MSP",
  SET_SELECTED_TAB = "SET_SELECTED_TAB",
  LOAD_ACTION = "LOAD_ACTION",
  SET_NO_OF_LOADED_SUBPARTNERS = "SET_NO_OF_LOADED_SUBPARTNERS",
  SET_NO_OF_SUBPARTNERS = "SET_NO_OF_SUBPARTNERS",
  ERROR = "ERROR",
  ERROR_AFTER_LOGIN = "ERROR_AFTER_LOGIN",
  SET_SNACKBAR_MESSAGE = "SET_SNACKBAR_MESSAGE",
  SET_VIEW_MPS_ACCOUNTS = "SET_VIEW_MPS_ACCOUNTS",
  LOAD_LOGGED_USER = "LOAD_LOGGED_USER",
  SET_HAS_SUBPARTNERS = "SET_HAS_SUBPARTNERS",
  SET_CUSTOMER_SEARCH_RESULTS = "SET_CUSTOMER_SEARCH_RESULTS",
  SET_VIEW_BILLING_EXCLUSIONS_LIST = "SET_VIEW_BILLING_EXCLUSIONS_LIST",
  SET_CANCEL_TOKEN = "SET_CANCEL_TOKEN",
  GET_HAS_USERS = "GET_HAS_USERS",
  SET_VIEW_INTEGRATIONS = "SET_VIEW_INTEGRATIONS",
  SET_CUSTOMER_FILTER_RESULTS = "SET_CUSTOMER_FILTER_RESULTS",
  SET_SHOW_PARTNER_RESOURCES = "SET_SHOW_PARTNER_RESOURCES",
}

export interface IGetApiEchoUrlAction {
  type: GeneralActionTypes.GET_API_ECHO_URL;
  apiUrl: string;
  echoUrl: string;
}

export interface IGenerateCustomAccessTokenAction {
  type: GeneralActionTypes.GENERATE_CUSTOM_ACCESS_TOKEN;
  accessToken: string;
}

export interface IrefreshEchoV3AccessTokenAction {
  type: GeneralActionTypes.REFRESH_CUSTOM_ACCESS_TOKEN;
  accessToken: string;
}

export interface ILoadLoggedInMsp {
  type: GeneralActionTypes.LOAD_LOGGEDIN_MSP;
  mspAccountLoggedIn: IAccount;
  isMspLoggedIn: boolean;
  hasSubpartners: boolean;
  isBaLoggedIn: boolean;
}

export interface ISetSelectedTabAction {
  type: GeneralActionTypes.SET_SELECTED_TAB;
  selectedTabName: string;
}

export interface ILoadLoggedInUser {
  type: GeneralActionTypes.LOAD_LOGGED_USER;
  loggedUser: ILoggedUser;
  isDefaultUser: boolean | undefined;
}

export interface ILoadAction {
  type: GeneralActionTypes.LOAD_ACTION;
  loading: boolean;
}

export interface ISetNoOfLoadedSubpartners {
  type: GeneralActionTypes.SET_NO_OF_LOADED_SUBPARTNERS;
  noOfLoadedSubpartners: number;
}

export interface ISetNoOfSubpartners {
  type: GeneralActionTypes.SET_NO_OF_SUBPARTNERS;
  noOfSubpartners: number;
}

export interface IErrorAction {
  type: GeneralActionTypes.ERROR;
  errorMessage: string;
}

export interface IErrorAfterLoginAction {
  type: GeneralActionTypes.ERROR_AFTER_LOGIN;
  apiError: ApiError;
}

export interface ISetSnackBarMessageAction {
  type: GeneralActionTypes.SET_SNACKBAR_MESSAGE;
  snackBarMessage: ISnackBarMessage;
}

export interface ISetViewMspAccountsAction {
  type: GeneralActionTypes.SET_VIEW_MPS_ACCOUNTS;
  viewMspAccounts: boolean;
}

export interface ISetViewCustomerSearchResultsAction {
  type: GeneralActionTypes.SET_CUSTOMER_SEARCH_RESULTS;
  viewSearchResults: boolean;
}

export interface ISetHasSubpartnersAction {
  type: GeneralActionTypes.SET_HAS_SUBPARTNERS;
  hasSubpartners: boolean;
}

export interface ISetViewBillingExclusionsListAction {
  type: GeneralActionTypes.SET_VIEW_BILLING_EXCLUSIONS_LIST;
  isBillingExclusionsDisplayed: boolean;
}

export interface ISetCancelTokenAction {
  type: GeneralActionTypes.SET_CANCEL_TOKEN;
  cancellationTokenSource: CancelTokenSource;
}

export interface IHasUsersAction {
  type: GeneralActionTypes.GET_HAS_USERS;
  hasUsers: boolean;
}

export interface ISetViewIntegrationsAction {
  type: GeneralActionTypes.SET_VIEW_INTEGRATIONS;
  viewIntegrations: boolean;
}

export interface ISetViewCustomerFilterResultsAction {
  type: GeneralActionTypes.SET_CUSTOMER_FILTER_RESULTS;
  viewFilterResults: boolean;
}

export interface ISetShowPartnerResourcesAction {
  type: GeneralActionTypes.SET_SHOW_PARTNER_RESOURCES;
  showPartnerResources: boolean;
}

export type GeneralActions = IGetApiEchoUrlAction | ILoadLoggedInMsp | ISetSelectedTabAction | ILoadAction | ISetNoOfLoadedSubpartners | ISetNoOfSubpartners | IErrorAction | IErrorAfterLoginAction | ISetSnackBarMessageAction | ISetViewMspAccountsAction | ILoadLoggedInUser | ISetHasSubpartnersAction | ISetViewCustomerSearchResultsAction | ISetViewBillingExclusionsListAction | ISetCancelTokenAction | IHasUsersAction | ISetViewIntegrationsAction | ISetShowPartnerResourcesAction | ISetViewCustomerFilterResultsAction;

export const setError: ActionCreator<ThunkAction<any, IGeneralState, null, IErrorAction>> = (errorMessage: string) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.ERROR, errorMessage });

export const setErrorAfterLogin: ActionCreator<ThunkAction<any, IGeneralState, null, IErrorAfterLoginAction>> = (apiError: ApiError) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.ERROR_AFTER_LOGIN, apiError });

export const setSnackBarMessage: ActionCreator<ThunkAction<any, IGeneralState, null, ISetSnackBarMessageAction>> = (snackBarMessage: ISnackBarMessage) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_SNACKBAR_MESSAGE, snackBarMessage });

export const removeSnackBarMessage: ActionCreator<ThunkAction<any, IGeneralState, null, ISetSnackBarMessageAction>> = () => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_SNACKBAR_MESSAGE, snackBarMessage: { message: "", type: ActionMessageType.Unknown } });

export const getApiUrlAction: ActionCreator<ThunkAction<Promise<any>, IAppState, IGeneralState, IGetApiEchoUrlAction>> = (urls: any) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const allUrls = JSON.parse(urls);
      const possibleApis = allUrls.map((item: any) => item.api_url);
      const foundUrlIndex = await getApiUrlIndexRecursive(possibleApis);
      if (foundUrlIndex > -1) {
        dispatch({
          type: GeneralActionTypes.GET_API_ECHO_URL,
          apiUrl: possibleApis[foundUrlIndex],
          echoUrl: matchApiToEcho(possibleApis[foundUrlIndex], allUrls),
        });
        TokenStorage.setApiUrl(possibleApis[foundUrlIndex]);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      handleError(err, dispatch, () => {
        /* no action needed*/
      });
    }
  };
};

export const generateCustomAccessTokenAction: ActionCreator<ThunkAction<Promise<any>, IAppState, IGeneralState, IGenerateCustomAccessTokenAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const token = TokenStorage.getBccIdToken();
      if (token) {
        const response = await mspService.generateCustomAccessToken(apiUrl, token);
        TokenStorage.storeEchoV3AccessToken(response.accessToken);
        TokenStorage.storeEchoV3RefreshToken(response.refreshToken);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      handleError(err, dispatch, () => {
        /* no action needed*/
      });
    }
  };
};

async function getApiUrlIndexRecursive(possibleApis: string[]): Promise<any> {
  let tokenExpired = false;

  const apiPromises = possibleApis.map(async (api: string) => {
    return mspService.checkUser(api);
  });
  const results = await Promise.all(
    apiPromises.map(async apiPromise => {
      try {
        return await apiPromise;
      } catch (error: any) {
        if (isTokenExpiredError(error)) {
          tokenExpired = true;
        } else {
          //  throw error;
        }
      }
    }),
  );

  const apiIndex = getFirstSuccessfull(results);
  if (tokenExpired && apiIndex < 0) {
    return TokenStorage.refreshBccAccessToken().then(async () => {
      return getApiUrlIndexRecursive(possibleApis);
    });
  } else {
    return apiIndex;
  }
}

export const preFetch = (url: string) => {
  return mspService.preFetch(url);
};

export const setSelectedTabName: ActionCreator<ThunkAction<any, IGeneralState, null, ISetSelectedTabAction>> = (selectedTabName: string) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_SELECTED_TAB, selectedTabName });

export const setLoggedUser: ActionCreator<ThunkAction<Promise<any>, IAppState, IGeneralState, ILoadLoggedInUser>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const loggedUser = await mspService.loadLoggedUser(apiUrl);
      dispatch({
        type: GeneralActionTypes.LOAD_LOGGED_USER,
        loggedUser: loggedUser,
        isDefaultUser: loggedUser.email.endsWith("@barracudamsp"),
      });
      TokenStorage.setUserId(loggedUser.id);
      return loggedUser.email.endsWith("@barracudamsp");
    } catch (err) {
      handleError(err, dispatch, () => {
        /* no action needed*/
      });
    }
  };
};

export const loadLoggedInMsp: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ILoadLoggedInMsp>> = (isDefaultUser: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({ type: GeneralActionTypes.LOAD_ACTION, loading: true });
      const { newMspAccountLoggedIn, subpartners } = await mspService.loadPartner(apiUrl);

      if (accountIsBillingAggregator(newMspAccountLoggedIn)) {
        dispatch({
          type: AccountActionTypes.SET_MSP_ACCOUNTS,
          mspAccounts: newMspAccountLoggedIn.accounts,
          itemsToDisplay: [newMspAccountLoggedIn, ...newMspAccountLoggedIn.accounts],
        });
        dispatch({
          mspAccountLoggedIn: newMspAccountLoggedIn,
          type: GeneralActionTypes.LOAD_LOGGEDIN_MSP,
          isMspLoggedIn: true,
          hasSubpartners: false,
          isBaLoggedIn: true,
        });
      } else {
        if (!isDefaultUser) {
          const allChildren = await mspService.fetchAccountAllChildren(apiUrl, newMspAccountLoggedIn, undefined);
          const mspAccounts = allChildren.accounts.filter((x: IAccount) => x.type === MspType.Customer || x.type === MspType.Subpartner || x.type === MspType.Partner);
          dispatch({
            type: AccountActionTypes.GET_ALL_CHILDREN_ACCOUNT_NAMES,
            loadingAllChildrenAccountNames: false,
            accountsNames: mspAccounts,
          });
        }
        dispatch({
          type: GeneralActionTypes.SET_NO_OF_SUBPARTNERS,
          noOfSubpartners: subpartners.length,
        });
        const hasSubpartners = subpartners.length > 0;
        dispatch({
          type: AccountActionTypes.SET_MSP_ACCOUNTS,
          mspAccounts: [newMspAccountLoggedIn, ...subpartners],
          itemsToDisplay: hasSubpartners ? [newMspAccountLoggedIn, ...subpartners] : [newMspAccountLoggedIn, ...newMspAccountLoggedIn.accounts],
        });
        dispatch({
          mspAccountLoggedIn: newMspAccountLoggedIn,
          type: GeneralActionTypes.LOAD_LOGGEDIN_MSP,
          isMspLoggedIn: true,
          hasSubpartners: hasSubpartners && !isDefaultUser,
        });
      }
      return newMspAccountLoggedIn;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: GeneralActionTypes.LOAD_ACTION,
          loading: false,
        });
      });
      return undefined;
    }
  };
};

export const loadAction: ActionCreator<ThunkAction<any, IGeneralState, null, ILoadAction>> = (shouldLoad: boolean) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.LOAD_ACTION, loading: shouldLoad });

export const setNoOfLoadedSubpartners: ActionCreator<ThunkAction<any, IGeneralState, null, ISetNoOfLoadedSubpartners>> = (noOfLoadedSubpartners: number) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_NO_OF_LOADED_SUBPARTNERS, noOfLoadedSubpartners });

export const setViewMspAccountsAction: ActionCreator<ThunkAction<any, IGeneralState, null, ISetViewMspAccountsAction>> = (viewMspAccounts: boolean) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_VIEW_MPS_ACCOUNTS, viewMspAccounts });

export const setViewCustomerSearchResultsAction: ActionCreator<ThunkAction<any, IGeneralState, null, ISetViewCustomerSearchResultsAction>> = (viewSearchResults: boolean) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_CUSTOMER_SEARCH_RESULTS, viewSearchResults });

export const setHasSubpartnersAction: ActionCreator<ThunkAction<any, IGeneralState, null, ISetHasSubpartnersAction>> = (hasSubpartners: boolean) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_HAS_SUBPARTNERS, hasSubpartners });

export const setViewBillingExclusionsListAction: ActionCreator<ThunkAction<any, IGeneralState, null, ISetViewBillingExclusionsListAction>> = (isBillingExclusionsDisplayed: boolean) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_VIEW_BILLING_EXCLUSIONS_LIST, isBillingExclusionsDisplayed });

export const setCancelTokenAction: ActionCreator<ThunkAction<any, IGeneralState, null, ISetCancelTokenAction>> = (cancellationTokenSource: CancelTokenSource) => (dispatch: Dispatch) => dispatch({ type: GeneralActionTypes.SET_CANCEL_TOKEN, cancellationTokenSource });

export const cancelCurrentAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetCancelTokenAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const cancelTokenSource = getState().generalState.cancellationTokenSource;
    if (cancelTokenSource) {
      cancelTokenSource?.cancel("Operation canceled.");
    }
    dispatch({
      cancellationTokenSource: undefined,
      type: GeneralActionTypes.SET_CANCEL_TOKEN,
    });
  };
};

function getFirstSuccessfull(results: ({ status: number } | undefined)[]) {
  return results.findIndex((x: { status: number } | undefined) => x !== undefined && x.status === 204);
}

export const hasUsersAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IHasUsersAction>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      const accountUsers = await mspService.loadAccountUsers(apiUrl, account);
      dispatch({
        type: GeneralActionTypes.GET_HAS_USERS,
        hasUsers: accountUsers.length > 0,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          /* do nothing */
        },
        () => {
          /* do nothing */
        },
      );
      return false;
    }
  };
};

export const setViewIntegrationsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetViewIntegrationsAction>> = (viewIntegrations: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      viewIntegrations: viewIntegrations,
      type: GeneralActionTypes.SET_VIEW_INTEGRATIONS,
    });
  };
};

export const setShowPartnerResourcesAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetShowPartnerResourcesAction>> = (showPartnerResources: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({
      showPartnerResources: showPartnerResources,
      type: GeneralActionTypes.SET_SHOW_PARTNER_RESOURCES,
    });
  };
};
