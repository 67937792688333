import React from "react";
import ConnectWiseLogo from "../../assets/ConnectWiseManageLogo1.png";
import AutoTaskLogo from "../../assets/AutoTaskPSALogo1.png";
import IntegrationType from "../../models/IntegrationType";

interface IIntegrationsIconProps {
  entityType: IntegrationType;
}

const IntegrationsIcon: React.FC<IIntegrationsIconProps> = ({ entityType }) => {
  return (
    <div>
      {entityType === IntegrationType.ConnectWise ? (
        <div data-testid="customerIcon">
          <img data-testid={"ConnectWiseLogoIcon"} src={ConnectWiseLogo} alt="ConnectWiseLogo" className="center ConnectWiseLogoIcon" />
        </div>
      ) : (
        <div data-testid="customerIcon">
          <img data-testid={"AutoTaskLogoIcon"} src={AutoTaskLogo} alt="AutoTaskLogo" className="center ConnectWiseLogoIcon" />
        </div>
      )}
    </div>
  );
};

export default IntegrationsIcon;
