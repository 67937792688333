import React from "react";
import * as FeedBackIcons from "@barracuda/bds-core/dist/Icons/Feedback";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Tooltip from "@barracuda/bds-core/dist/Tooltip";
import { getProductStatusIconTooltip } from "../../businessLogic/components/Products/ProductStatusIcon";

export const getProductStatusIcon = (hasPendingSerials: boolean | undefined, hasSerials: boolean, noOfErrors: number, showActivatedIcon: boolean, showAvailableIcon: boolean, noOfRmaSerials: number): JSX.Element | null => {
  if (!hasSerials && showAvailableIcon) {
    return (
      <div data-testid="availableIcon">
        <PowerSettingsNewIcon htmlColor={"gray"}></PowerSettingsNewIcon>
      </div>
    );
  }
  if (noOfErrors > 0) {
    return (
      <div data-testid="errorIcon" className="ProductStatusIcon" style={{ display: "inline-flex" }}>
        <FeedBackIcons.Critical className="DarkRedColor"></FeedBackIcons.Critical>
        {noOfErrors > 1 && (
          <span data-testid="errorCount" className="DarkRedColor" style={{ fontWeight: "bold" }}>
            {`(${noOfErrors})`}
          </span>
        )}
      </div>
    );
  } else if (noOfRmaSerials > 0) {
    return (
      <div data-testid="rmaIcon" className="ProductStatusIcon" style={{ display: "inline-flex" }}>
        <FeedBackIcons.Info className="DarkRedColor"></FeedBackIcons.Info>
        {noOfRmaSerials > 1 && (
          <span data-testid="rmaCount" className="DarkRedColor" style={{ fontWeight: "bold" }}>
            {`(${noOfRmaSerials})`}
          </span>
        )}
      </div>
    );
  } else if (hasPendingSerials) {
    return (
      <div data-testid="pendingIcon">
        <HourglassEmptyIcon htmlColor={"gray"}></HourglassEmptyIcon>
      </div>
    );
  } else {
    return showActivatedIcon ? (
      <div data-testid="activatedIcon">
        <FeedBackIcons.Success className="DarkGreenColor"></FeedBackIcons.Success>
      </div>
    ) : null;
  }
};

interface IProductStatusIconProps {
  hasPendingSerials: boolean;
  hasSerials: boolean;
  noOfErrors: number;
  showActivatedIcon: boolean;
  showAvailableIcon: boolean;
  noOfRmaSerials: number;
}

const ProductStatusIcon: React.FC<IProductStatusIconProps> = ({ hasPendingSerials, hasSerials, noOfErrors, showActivatedIcon, showAvailableIcon, noOfRmaSerials }) => {
  return (
    <Tooltip title={getProductStatusIconTooltip(hasPendingSerials, hasSerials, noOfErrors, showAvailableIcon, noOfRmaSerials)}>
      <div className="ProductStatusIcon" style={{ display: "inline-flex" }}>
        {getProductStatusIcon(hasPendingSerials, hasSerials, noOfErrors, showActivatedIcon, showAvailableIcon, noOfRmaSerials)}
      </div>
    </Tooltip>
  );
};
export default ProductStatusIcon;
