import { Typography, Button } from "@barracuda/bds-core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBilledUsersAction, importAuditUsersExclusionFile } from "../../../actions/auditUsersActions";
import { MAX_CSV_SIZE_TO_IMPORT, MAX_NO_OF_BILLING_USERS_TO_IMPORT } from "../../../config";
import IAccount from "../../../models/IAccount";
import IAuditUserImportAction from "../../../models/Products/IAuditUserImportAction";
import { IAppState } from "../../../store/store";
import { fileHasCorrectSize, processFile } from "../../../Utilities/auditUsersHelper";
import { getPercentageSubpartnersLoadedProgress } from "../../../utility";
import CustomMessageDialog from "../../CustomMessageDialog";
import TruncatedText from "../../TruncatedText";

export enum CsvImportErrorType {
  None,
  CSVFormat,
  CSVSize,
  CSVData,
  CSVTooManyEntries,
  CSVNoEntries,
}
const ImportCsv: React.FC = () => {
  const dispatch = useDispatch();
  const [loadingFile, setLoadingFile] = useState(false);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const importActions = useSelector((state: IAppState) => state.auditUsersState.importActions);
  const noOfActionsInProgress = useSelector((state: IAppState) => state.auditUsersState.noOfActionsInProgress);
  const [progress, setProgress] = useState(0);
  const [importFileFailedMessage, setImportFileFailedMessage] = useState<JSX.Element | undefined>();
  const [accountIdImportFinished, setAccountIdImportFinished] = useState<number | undefined>();

  useEffect(() => {
    const currentActionIndex = importActions.findIndex((x: IAuditUserImportAction) => x.account.id === selectedAccount?.id);
    if (currentActionIndex > -1) {
      setProgress(getPercentageSubpartnersLoadedProgress(importActions[currentActionIndex].noOfImportedEmails, importActions[currentActionIndex].noOfEmailsToImport));
    } else {
      setProgress(getPercentageSubpartnersLoadedProgress(0, 0));
    }
  }, [importActions, selectedAccount]);

  useEffect(() => {
    if (accountIdImportFinished !== undefined) {
      if (selectedAccount?.id === accountIdImportFinished) {
        dispatch(getBilledUsersAction(selectedAccount));
        setAccountIdImportFinished(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountIdImportFinished, selectedAccount]);

  const renderProgressPercentage = (progressPercentage: number) => {
    if (progressPercentage === 0 || progressPercentage === 100) {
      return null;
    } else {
      return (
        <Typography variant="body1" component="div" color="text.secondary">
          {`${Math.round(progressPercentage)}%`}
        </Typography>
      );
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      if (importActions.length > 0) {
        const accountActionIndex = importActions.findIndex((x: IAuditUserImportAction) => x.account.id === selectedAccount?.id);
        if (accountActionIndex > -1) {
          setLoadingFile(true);
        } else {
          setLoadingFile(false);
        }
      } else {
        setLoadingFile(false);
      }
    }
  }, [importActions, selectedAccount]);

  const onFileChanged = (e: any) => {
    setAccountIdImportFinished(undefined);
    if (selectedAccount) {
      const files = e.target.files;
      if (files && files.length > 0) {
        const file = e.target.files[0];
        e.target.value = "";

        if (!fileHasCorrectSize(file)) {
          showError(CsvImportErrorType.CSVSize, file.name);
        } else {
          setLoadingFile(true);
          let fr = new FileReader();
          fr.onloadend = () => onFileLoaded(file, fr.result, selectedAccount);
          fr.readAsArrayBuffer(file);
        }
      }
    }
  };

  const onFileLoaded = (file: any, content: any, account: IAccount) => {
    let isValidCsv = true;
    const arrayBuffer = content as ArrayBuffer;
    let view2 = new Int8Array(arrayBuffer);
    for (let i = 1; i < view2.length; i++) {
      if (view2[i] === 0x00 && view2[i - 1] === 0x00) {
        isValidCsv = false;
        break;
      }
    }
    if (!isValidCsv) {
      showError(CsvImportErrorType.CSVFormat, file.name);
    } else {
      let fr = new FileReader();
      fr.onloadend = () => onFileTextLoaded(fr.result, file.name, account);
      fr.readAsText(file);
    }
  };

  const importFile = (account: IAccount, filename: string, users: string[]) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(importAuditUsersExclusionFile(account, filename, users));
      resolve(success);
    });

  const onFileTextLoaded = (content: any, filename: string, account: IAccount) => {
    const processedFileDetails = processFile(content);
    if (!processedFileDetails.isValidCsv) {
      showError(CsvImportErrorType.CSVFormat, filename);
    } else {
      if (processedFileDetails.users.length > 0) {
        if (processedFileDetails.users.length > MAX_NO_OF_BILLING_USERS_TO_IMPORT) {
          showError(CsvImportErrorType.CSVTooManyEntries, filename);
        } else {
          importFile(account, filename, processedFileDetails.users).then(success => {
            setLoadingFile(false);
            setAccountIdImportFinished(account.id);
          });
        }
      } else if (processedFileDetails.users.length === 0) {
        showError(CsvImportErrorType.CSVNoEntries, filename);
      } else {
        showError(CsvImportErrorType.CSVData, filename);
      }
    }
  };

  const showError = (csvImportErrorType: CsvImportErrorType, filename: string) => {
    const message = getProcessCsvErrorMessage(csvImportErrorType, filename);
    setImportFileFailedMessage(message);
    setLoadingFile(false);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Button variant="contained" accept={".csv"} isLoading={loadingFile} disabled={loadingFile || noOfActionsInProgress > 0} component="label" onChange={(e: any) => onFileChanged(e)}>
        IMPORT EXCLUSIONS CSV
        <input type="file" data-testid="exclusionCsvUpload" accept={".csv"} hidden disabled={loadingFile || noOfActionsInProgress > 0} />
      </Button>
      {renderProgressPercentage(progress)}
      {importFileFailedMessage && <CustomMessageDialog message={importFileFailedMessage} onCloseMessage={() => setImportFileFailedMessage(undefined)}></CustomMessageDialog>}
    </div>
  );
};

export default ImportCsv;

export function getProcessCsvErrorMessage(csvImportErrorType: CsvImportErrorType, filename: string): JSX.Element | undefined {
  let message = undefined;
  switch (csvImportErrorType) {
    case CsvImportErrorType.CSVData:
      message = (
        <Typography variant={"body1"}>
          File <TruncatedText value={filename} bold={true} maxLength={20} /> does not contain any valid emails.
        </Typography>
      );
      break;
    case CsvImportErrorType.CSVFormat:
      message = (
        <Typography variant={"body1"}>
          File <TruncatedText value={filename} bold={true} maxLength={20} /> format is not right, please provide a CSV file with 1 column and no header.
        </Typography>
      );
      break;
    case CsvImportErrorType.CSVSize:
      message = (
        <Typography variant={"body1"}>
          File <TruncatedText value={filename} bold={true} maxLength={20} /> is too large, please use a CSV file with size less than {MAX_CSV_SIZE_TO_IMPORT / 1024}MB.
        </Typography>
      );
      break;
    case CsvImportErrorType.CSVTooManyEntries:
      message = (
        <Typography variant={"body1"}>
          File <TruncatedText value={filename} bold={true} maxLength={20} /> has more than {MAX_NO_OF_BILLING_USERS_TO_IMPORT} users, please use a CSV file which has less than or equals to {MAX_NO_OF_BILLING_USERS_TO_IMPORT} users.
        </Typography>
      );
      break;
    case CsvImportErrorType.CSVNoEntries:
      message = <Typography variant={"body1"}>Imported .CSV is empty.</Typography>;
      break;
    default:
      message = undefined;
  }
  return message;
}
