import React from "react";
import AccountIcon from "./AccountIcon";
import MspType from "../../models/MspType";
import * as CoreIcons from "@barracuda/bds-core/dist/Icons/Core";
import { Typography, CircularProgress } from "@barracuda/bds-core";
import Grid from "@barracuda/bds-core/dist/Grid";
import Tooltip from "@barracuda/bds-core/dist/Tooltip";
import { getAccountDisplayType } from "../../Utilities/accountsHelper";

const AccountCell = (props: any) => {
  const shouldIndent = props.shouldUseIndentation; // && shouldUseIndent(props.mspAccountLoggedIn, props.dataItem, props.viewMspAccounts);
  return (
    <td onClick={() => props.onClick(props)} className={props.shouldUseIndentation ? "useIndentation" : "noIndentation"}>
      <Grid item container>
        {props.shouldUseIndentation && <Grid container item xs={1}></Grid>}
        {props.displayAccountIcon && (
          <Grid container item xs={1} style={{ justifyContent: "flex-end", paddingRight: "11px" }}>
            <Tooltip title={getAccountDisplayType(props.dataItem.type)}>
              <div style={{ position: "relative" }}>
                <AccountIcon entityType={props.dataItem.type}></AccountIcon>
              </div>
            </Tooltip>
          </Grid>
        )}
        <Grid container item xs={shouldIndent ? 8 : 9}>
          <Tooltip title={props.dataItem.name}>
            <Typography variant="body1" gutterBottom noWrap className={props.shouldUseIndentation ? "centerVertically" : "noIndentation centerVertically"}>
              {props.dataItem.name}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid container item xs={1} style={{ display: "grid", justifyContent: "flex-end" }}>
          {props.loading && <CircularProgress data-testid="loadingSubpartner" size="25px" />}
        </Grid>
        <Grid container item xs={1} className="AccountIcons">
          {props.dataItem.type !== MspType.Customer && props.displayExpandIcon && <CoreIcons.PageNext />}
        </Grid>
      </Grid>
    </td>
  );
};

export default AccountCell;
