import { isStatusPending } from "../../Utilities/productsHelper";
import ISerial from "./ISerial";
import ProductFamily from "./ProductFamily";
import SerialStatus from "./SerialStatus";

export interface IOrderSummary {
  totalUsers: number;
  totalErrors: number;
  hasPendingSerials: boolean;
  finalSerials: ISerial[];
  noOfRmaSerials: number;
}

export const getOrderSummary = (serials: ISerial[] | undefined, prodFamilyName: string): IOrderSummary => {
  if (serials && serials.length > 0) {
    let hasPendingSerials = false;
    let noOfRmaSerials = 0;
    let totalUsers = 0;
    let totalErrors = 0;
    let finalSerials: ISerial[] = serials.filter((serial: ISerial) => serial.status !== SerialStatus.CANCELED);
    if (prodFamilyName.includes(ProductFamily.ESSENTIALS_SERIVICES) || prodFamilyName.includes(ProductFamily.CONTENT_SHIELD)) {
      finalSerials = finalSerials.filter((serial: ISerial) => serial.status !== SerialStatus.RMA_ISSUED && serial.status !== SerialStatus.RMA_NEEDED && serial.status !== SerialStatus.RMA_REQUESTED);
    }
    finalSerials.forEach((serial: ISerial) => {
      totalUsers += serial.activatedSeats;
      if (serial.status === SerialStatus.ACTIVATION_FAILED || serial.status === SerialStatus.PROVISION_FAILED) {
        totalErrors++;
      }
      if (isStatusPending(serial.status)) {
        hasPendingSerials = true;
      }
      if (serial.status === SerialStatus.RMA_ISSUED || serial.status === SerialStatus.RMA_NEEDED || serial.status === SerialStatus.RMA_REQUESTED) {
        noOfRmaSerials++;
      }
    });
    return {
      totalUsers: totalUsers,
      totalErrors: totalErrors,
      finalSerials: finalSerials,
      hasPendingSerials: hasPendingSerials,
      noOfRmaSerials: noOfRmaSerials,
    };
  } else {
    return {
      totalUsers: 0,
      totalErrors: 0,
      finalSerials: [],
      hasPendingSerials: false,
      noOfRmaSerials: 0,
    };
  }
};
