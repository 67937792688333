import React, { useState, useEffect } from "react";
import { Card, Tabs, TabPanel, Tab } from "@barracuda/bds-core";
import { useDispatch, useSelector } from "react-redux";
import IntegrationTabs from "../../models/Integrations/IntegrationTabs";
import { IAppState } from "../../store/store";
import { getDetailsTab } from "../../utility";
import { TabProps } from "@barracuda/bds-core/dist/Tabs";
import IntegrationsAccountsTab from "./Accounts/IntegrationsAccountsTab";
import IntegrationsBillingTab from "./Billing/IntegrationsBillingTab";
import IntegrationsLogsTab from "./Logs/IntegrationsLogsTab";
import IntegrationsDetailsTab from "./Details/IntegrationsDetailsTab";
import { loadConnectWiseIntegrationInfoAction, setLoadedIntegrationAction, setSelectedIntegrationsTabNameAction } from "../../actions/integrations/integrationsActions";
import { getIntegrationAccountsAction, setIntegrationAccountsTablePropsAction, setShowUnlinkedAccountsAction } from "../../actions/integrations/integrationsAccountsActions";
import { getIntegrationLogsAction } from "../../actions/integrations/integrationsLogsActions";
import IntegrationStatus from "../../models/Integrations/IntegrationStatus";
import { getIntegrationBillingMapsAction } from "../../actions/integrations/integrationsBillingActions";
import IntegrationsTicketsTab from "./Tickets/IntegrationsTicketsTab";

export interface IDetailsTabs {
  id: number;
  tab: TabProps;
}

export interface IIntegrationTabsProps {
  integrationAccountsTabLoaded: boolean;
  integrationDetailsTabLoaded: boolean;
  integrationBillingTabLoaded: boolean;
  integrationLogsTabLoaded: boolean;
  setTabLoaded: (loaded: boolean, tab: IntegrationTabs) => void;
}

const IntegrationsTabs: React.FC<IIntegrationTabsProps> = ({ integrationAccountsTabLoaded, integrationDetailsTabLoaded, integrationBillingTabLoaded, integrationLogsTabLoaded, setTabLoaded }) => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const selectedIntegration = useSelector((state: IAppState) => state.integrationsState.selectedIntegration);
  const loadedIntegration = useSelector((state: IAppState) => state.integrationsState.loadedIntegration);
  const selectedIntegrationsTabName = useSelector((state: IAppState) => state.integrationsState.selectedIntegrationsTabName);
  const loadingIntegrationAccountsCanceled = useSelector((state: IAppState) => state.integrationsAccountsState.loadingIntegrationAccountsCanceled);
  const loadingIntegrationLogsCanceled = useSelector((state: IAppState) => state.integrationsLogsState.loadingIntegrationLogsCanceled);
  const loadingIntegrationBillingMapCanceled = useSelector((state: IAppState) => state.integrationsBillingMapState.loadingIntegrationBillingMapCanceled);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);

  const alltabs: IDetailsTabs[] = [
    {
      id: 0,
      tab: {
        label: IntegrationTabs.Accounts,
        value: <IntegrationsAccountsTab />,
      },
    },
    {
      id: 1,
      tab: {
        label: IntegrationTabs.Billing,
        value: <IntegrationsBillingTab />,
      },
    },
    {
      id: 2,
      tab: {
        label: IntegrationTabs.Logs,
        value: <IntegrationsLogsTab />,
      },
    },
    {
      id: 3,
      tab: {
        label: IntegrationTabs.Details,
        value: <IntegrationsDetailsTab />,
      },
    },
    {
      id: 4,
      tab: {
        label: IntegrationTabs.Tickets,
        value: <IntegrationsTicketsTab />,
      },
    },
  ];

  const [tabs, setTabs] = useState(alltabs);

  const selectedDetailTab = getDetailsTab(tabs, selectedIntegrationsTabName);
  const [selectedTab, setSelectedTab] = useState(selectedDetailTab);
  const handleChange: any = (_e: React.SyntheticEvent, newValue: any) => {
    let resultValue = tabs.filter(x => x.id === newValue)[0];
    if (resultValue !== null && resultValue !== undefined) {
      setSelectedTab(resultValue);
      dispatch(setSelectedIntegrationsTabNameAction(resultValue.tab.label));
    }
  };

  const [tabObject, setTabObject] = useState(selectedDetailTab);

  useEffect(() => {
    setTabs(alltabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegration]);

  useEffect(() => {
    for (let i = 0; i < tabs.length; i++) {
      tabs[i].id = i;
    }
  }, [tabs]);

  useEffect(() => {
    if (selectedIntegration) {
      const anyTabsAlreadyLoaded = integrationAccountsTabLoaded || integrationDetailsTabLoaded || integrationBillingTabLoaded || integrationLogsTabLoaded;
      if (!anyTabsAlreadyLoaded) {
        let defaultTab;
        switch (selectedIntegration.status) {
          case IntegrationStatus.Active:
            defaultTab = IntegrationTabs.Accounts;
            break;
          case IntegrationStatus.NewLogs:
            defaultTab = IntegrationTabs.Logs;
            break;
          default:
            defaultTab = IntegrationTabs.Details;
        }
        setSelectedTab(getDetailsTab(tabs, defaultTab));
        dispatch(setSelectedIntegrationsTabNameAction(defaultTab));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegration, integrationAccountsTabLoaded, integrationDetailsTabLoaded, integrationBillingTabLoaded, integrationLogsTabLoaded]);

  useEffect(() => {
    setSelectedTab(selectedDetailTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegrationsTabName]);

  useEffect(() => {
    setTabObject(selectedDetailTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (selectedIntegration && selectedTab && selectedAccount) {
      let integrationHasChanged = false;
      if (loadedIntegration?.id !== selectedIntegration.id) {
        integrationHasChanged = true;
        dispatch(setLoadedIntegrationAction(selectedIntegration));
        setTabLoaded(false, IntegrationTabs.Accounts);
        setTabLoaded(false, IntegrationTabs.Billing);
        setTabLoaded(false, IntegrationTabs.Logs);
        setTabLoaded(false, IntegrationTabs.Details);
      }
      if (selectedTab.tab.label === IntegrationTabs.Accounts) {
        if (integrationHasChanged || !integrationAccountsTabLoaded || loadingIntegrationAccountsCanceled) {
          setTabLoaded(true, IntegrationTabs.Accounts);
          dispatch(getIntegrationAccountsAction(selectedAccount, 0, 10, "mspAccountName", true, false, true));
          dispatch(setShowUnlinkedAccountsAction(false));
          dispatch(setIntegrationAccountsTablePropsAction({ sort: [{ field: "mspAccountName", dir: "asc" }], take: 10, skip: 0, filter: undefined }));
        }
      } else if (selectedTab.tab.label === IntegrationTabs.Billing) {
        if (integrationHasChanged || !integrationBillingTabLoaded || loadingIntegrationBillingMapCanceled) {
          setTabLoaded(true, IntegrationTabs.Billing);
          dispatch(getIntegrationBillingMapsAction(selectedAccount));
        }
      } else if (selectedTab.tab.label === IntegrationTabs.Logs) {
        if (integrationHasChanged || !integrationLogsTabLoaded || loadingIntegrationLogsCanceled) {
          setTabLoaded(true, IntegrationTabs.Logs);
          dispatch(getIntegrationLogsAction(mspAccountLoggedIn));
        }
      } else if (selectedTab.tab.label === IntegrationTabs.Details) {
        if ((integrationHasChanged || !integrationDetailsTabLoaded || loadingIntegrationLogsCanceled) && selectedIntegration.id !== undefined) {
          setTabLoaded(true, IntegrationTabs.Details);
          dispatch(loadConnectWiseIntegrationInfoAction());
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, selectedIntegration, integrationAccountsTabLoaded, integrationLogsTabLoaded, integrationBillingTabLoaded, integrationDetailsTabLoaded]);

  return (
    <Card>
      <Tabs value={selectedTab?.id} onChange={handleChange} indicatorColor="primary" textColor="primary">
        {tabs.map((t, index) => (
          <Tab key={"integrationTab" + index} data-testid={"integrationTab" + t.tab?.label?.toString().toLocaleLowerCase().replace(/\s/g, "")} label={t.tab?.label} />
        ))}
      </Tabs>
      <TabPanel>{tabObject?.tab.value}</TabPanel>
    </Card>
  );
};

export default IntegrationsTabs;
