import { TextField, IconButton } from "@barracuda/bds-core";
import Grid from "@barracuda/bds-core/dist/Grid";
import { InputAdornment } from "@material-ui/core";
import React, { useState } from "react";
import * as CoreIcons from "@barracuda/bds-core/dist/Icons/Core";

interface ISearchSubpartnerProps {
  onSubpartnerSearched: (s: string) => void;
  onCancelSearch: () => void;
  onFocusSearch: (isFocused: boolean) => void;
}

const SearchSubpartner: React.FC<ISearchSubpartnerProps> = ({ onSubpartnerSearched, onCancelSearch, onFocusSearch }) => {
  const [showClearButton, setShowClearButton] = useState(false);
  const [name, setName] = useState("");

  const onHandleClearSearch = () => {
    setName("");
    onSubpartnerSearched("");
    onCancelSearch();
    setShowClearButton(false);
  };

  const onSubpartnerSearchedChanged = (searchedName: string) => {
    setName(searchedName);
    const searchedValue = searchedName.trim();
    if (searchedValue.length === 0) {
      onHandleClearSearch();
    } else {
      onSubpartnerSearched(searchedValue);
      setShowClearButton(true);
    }
  };

  const renderAddUserButton = () => {
    let adornment = null;
    if (showClearButton === true) {
      adornment = (
        <InputAdornment position="end">
          <IconButton data-testid={"clearSearchSubpartners"} onClick={onHandleClearSearch}>
            <CoreIcons.Close></CoreIcons.Close>
          </IconButton>
        </InputAdornment>
      );
    }
    return adornment;
  };
  const handleOnEnterSearchBar = () => {
    onFocusSearch(true);
  };
  const handleOnLeaveSearchBar = () => {
    onFocusSearch(false);
  };
  return (
    <Grid className={"auditSearch"} item container xs={12} style={{ paddingBottom: "10px" }}>
      <TextField
        onFocus={handleOnEnterSearchBar}
        onBlur={handleOnLeaveSearchBar}
        data-testid="searchSubpartnerTxt"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CoreIcons.Filter />
            </InputAdornment>
          ),
          endAdornment: renderAddUserButton(),
        }}
        placeholder="Filter By Subpartner Name"
        size={"large"}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onSubpartnerSearchedChanged(ev.target.value)}
        value={name}
      />
    </Grid>
  );
};

export default SearchSubpartner;
