import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, CheckboxLabel, LinearProgress } from "@barracuda/bds-core";
import Grid from "@barracuda/bds-core/dist/Grid";
import IAccount from "../../../models/IAccount";
import AddEditAddressDialog from "./AddEditAddressDialog";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import { IFieldValidator, validateAddress } from "./addressValidator";
import MspType from "../../../models/MspType";
import { enterKey, isStringNullOrEmpty } from "../../../utility";
import ICountry from "../../../models/ICountry";
import IAccountAddress from "../../../models/IAccountAddress";
import { AccountCompanyNameValidationRule } from "../../../fieldsValidationRules";
import AddEditAccountDisplayName from "./AddEditAccountDisplayName";
import IAddress from "../../../models/IAddress";

interface ISubmitDialogProps {
  onSubmit: (partnerId: number, entity: IAccount, linkToM365?: boolean) => void;
  onCancel: () => void;
  showDialog: boolean;
  selectedItem: IAccount;
  addressToDisplay: IAccountAddress | undefined;
  mspType: MspType;
  isActionInProgress: boolean;
  isEdit: boolean;
  account?: IAccount;
  dialogStep: number;
  title: string;
  loadingAddEditDialog: boolean;
}

const AddEditAccountDialog: React.FC<ISubmitDialogProps> = ({ showDialog, dialogStep, onCancel, onSubmit, selectedItem, addressToDisplay, mspType, isActionInProgress, isEdit, account, title, loadingAddEditDialog }) => {
  const countries = useSelector((state: IAppState) => state.accountState.countries);
  const [dialogSteps, setDialogSteps] = useState(["Account details and contact info"]);
  const [loadedCountries, setLoadedCountries] = useState<ICountry[]>(countries);
  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const isPartner = mspType === MspType.Partner;
  const isBa = mspType === MspType.BillingAggregator;
  const [scrollToElement, setScrollToElement] = useState<IFieldValidator>();
  const [scrollToAccountName, setScrollToAccountName] = useState<any>();
  const [nextButtonLabel, setNextButtonLable] = useState("");

  useEffect(() => {
    setLoadedCountries(countries);
  }, [countries]);

  const [accountNameError, setAccountNameError] = useState("");
  const [accountName, setAccountName] = useState("");
  const accountNameRef = useRef<any>();

  const onAccountNameChanged = (newValue: string) => {
    setAccountNameError("");
    setAccountName(newValue);
    setScrollToAccountName(undefined);
  };

  const [contactAddressName, setContactAddressName] = useState("");
  const [contactAddressCompany, setContactAddressCompany] = useState("");
  const [contactAddressEmail, setContactAddressEmail] = useState("");
  const [contactAddressStreet, setContactAddressStreet] = useState("");
  const [contactAddressStreet2, setContactAddressStreet2] = useState<string | undefined>("");
  const [contactAddressStreet3, setContactAddressStreet3] = useState<string | undefined>();
  const [contactAddressStreet4, setContactAddressStreet4] = useState<string | undefined>();
  const [contactAddressPhone, setContactAddressPhone] = useState("");
  const [contactAddressCity, setContactAddressCity] = useState("");
  const [contactAddressZip, setContactAddressZip] = useState("");
  const [selectedContactAddressCountryId, setContactAddressCountryId] = useState(0);
  const [selectedContactAddressStateId, setSelectedContactAddressStateId] = useState(0);
  const [contactAddressCustomState, setContactAddressCustomState] = useState("");
  const [contactAddressErrors, setContactAddressErrors] = useState<IFieldValidator[]>([]);
  const [isM365Linked, setIsM365Linked] = useState(false);
  const onContactNameChanged = (newValue: string) => {
    setContactAddressName(newValue);
  };

  const onContactCompanyChanged = (newValue: string) => {
    setContactAddressCompany(newValue);
  };

  const onContactEmailChanged = (newValue: string) => {
    setContactAddressEmail(newValue);
  };

  const onContactStreetChanged = (newValue: string) => {
    setContactAddressStreet(newValue);
  };

  const onContactStreet2Changed = (newValue: string) => {
    setContactAddressStreet2(newValue);
  };

  const onContactStreet3Changed = (newValue: string) => {
    setContactAddressStreet3(newValue);
  };

  const onContactStreet4Changed = (newValue: string) => {
    setContactAddressStreet4(newValue);
  };

  const onContactPhoneChanged = (newValue: string) => {
    setContactAddressPhone(newValue);
  };

  const onContactCountryIdChanged = (newValue: number) => {
    setContactAddressCountryId(newValue);
    setContactAddressCustomState("");
  };

  const onContactCityChanged = (newValue: string) => {
    setContactAddressCity(newValue);
  };

  const onContactZipChanged = (newValue: string) => {
    setContactAddressZip(newValue);
  };

  const onContactAddressStateIdChanged = (newValue: number) => {
    setSelectedContactAddressStateId(newValue);
  };

  const onContactAddressCustomStateChanged = (newValue: string) => {
    setContactAddressCustomState(newValue);
  };

  const [billingAddressName, setBillingAddressName] = useState("");
  const [billingAddressEmail, setBillingAddressEmail] = useState("");
  const [billingAddressStreet, setBillingAddressStreet] = useState("");
  const [billingAddressStreet2, setBillingAddressStreet2] = useState<string | undefined>("");
  const [billingAddressStreet3, setBillingAddressStreet3] = useState<string | undefined>("");
  const [billingAddressStreet4, setBillingAddressStreet4] = useState<string | undefined>("");
  const [billingAddressPhone, setBillingAddressPhone] = useState("");
  const [billingAddressCity, setBillingAddressCity] = useState("");
  const [billingAddressZip, setBillingAddressZip] = useState("");
  const [selectedBillingAddressCountryId, setSelectedBillingAddressCountryId] = useState(0);
  const [selectedBillingAddressStateId, setSelectedBillingAddressStateId] = useState(0);
  const [billingAddressCustomState, setBillingAddressCustomState] = useState("");
  const [billingAddressErrors, setBillingAddressErrors] = useState<IFieldValidator[]>([]);

  const onBillingNameChanged = (newValue: string) => {
    setBillingAddressName(newValue);
  };

  const onBillingEmailChanged = (newValue: string) => {
    setBillingAddressEmail(newValue);
  };

  const onBillingStreetChanged = (newValue: string) => {
    setBillingAddressStreet(newValue);
  };

  const onBillingStreet2Changed = (newValue: string) => {
    setBillingAddressStreet2(newValue);
  };

  const onBillingStreet3Changed = (newValue: string) => {
    setBillingAddressStreet3(newValue);
  };

  const onBillingStreet4Changed = (newValue: string) => {
    setBillingAddressStreet4(newValue);
  };

  const onBillingPhoneChanged = (newValue: string) => {
    setBillingAddressPhone(newValue);
  };

  const onBillingAddressCountryIdChanged = (newValue: number) => {
    setSelectedBillingAddressCountryId(newValue);
    setBillingAddressCustomState("");
  };

  const onBillingCityChanged = (newValue: string) => {
    setBillingAddressCity(newValue);
  };

  const onBillingZipChanged = (newValue: string) => {
    setBillingAddressZip(newValue);
  };

  const onBillingAddressStateIdChanged = (newValue: number) => {
    setSelectedBillingAddressStateId(newValue);
  };

  const onBillingAddressCustomStateChanged = (newValue: string) => {
    setBillingAddressCustomState(newValue);
  };

  const handleScrollToError = (errors: IFieldValidator[]) => {
    if (accountNameError.length === 0) {
      let result = errors.reduce(function (prev, curr) {
        return prev.index < curr.index && prev.error.length > 0 ? prev : curr;
      });
      setScrollToElement(result);
    }
  };

  useEffect(() => {
    if (accountNameRef.current && scrollToAccountName) {
      accountNameRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [scrollToAccountName]);

  useEffect(() => {
    if (isEdit && addressToDisplay !== undefined && !loadingAddEditDialog) {
      setAccountName(selectedItem.name);
      if (addressToDisplay.contact) {
        setContactAddress(addressToDisplay.contact);
        if (addressToDisplay.isContactBillingSame) {
          setBillingAddress(addressToDisplay.contact);
        } else if (addressToDisplay.billing) {
          setBillingAddress(addressToDisplay.billing);
        }
      } else if (addressToDisplay.billing) {
        setBillingAddress(addressToDisplay.billing);
      }
    }
    // eslint-disable-next-line
  }, [isEdit, account, loadingAddEditDialog]);

  function setContactAddress(address: IAddress) {
    setContactAddressName(address.name);
    setContactAddressCompany(address.company ?? "");
    setContactAddressEmail(address.email);
    setContactAddressStreet(address.street);
    setContactAddressStreet2(address.street2);
    setContactAddressStreet3(address.street3);
    setContactAddressStreet4(address.street4);
    setContactAddressPhone(address.phone);
    setContactAddressZip(address.zip);
    setContactAddressCity(address.city);
    setContactAddressCountryId(getCountryId(address.country));
    setSelectedContactAddressStateId(getStateId(address.country, address.state));
    if (getStateId(address.country, address.state) === 0) {
      setContactAddressCustomState(address.state);
    }
  }

  function setBillingAddress(address: IAddress) {
    setBillingAddressName(address.name);
    setBillingAddressEmail(address.email);
    setBillingAddressStreet(address.street);
    setBillingAddressStreet2(address.street2);
    setBillingAddressStreet3(address.street3);
    setBillingAddressStreet4(address.street4);
    setBillingAddressPhone(address.phone);
    setBillingAddressZip(address.zip);
    setBillingAddressCity(address.city);
    setSelectedBillingAddressCountryId(getCountryId(address.country));
    setSelectedBillingAddressStateId(getStateId(address.country, address.state));
    if (getStateId(address.country, address.state) === 0) {
      setBillingAddressCustomState(address?.state);
    }
  }

  const onLoadingStates = (isLoading: boolean) => {
    setIsLoadingStates(isLoading);
  };

  const handleOnOpenDropDown = (isOpen: boolean) => {
    setDropdownIsOpen(isOpen);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <div data-testid="addEditAccountContactInfo" ref={accountNameRef}>
            <AddEditAccountDisplayName accountName={accountName} accountNameError={accountNameError} title={title} isActionInProgress={isActionInProgress} onAccountNameChanged={onAccountNameChanged} />
            <AddEditAddressDialog countries={loadedCountries} isBilling={false} city={contactAddressCity} countryId={selectedContactAddressCountryId} email={contactAddressEmail} name={contactAddressName} company={contactAddressCompany} phone={contactAddressPhone} stateId={selectedContactAddressStateId} customState={contactAddressCustomState} street={contactAddressStreet} street2={contactAddressStreet2} street3={contactAddressStreet3} street4={contactAddressStreet4} zip={contactAddressZip} errors={contactAddressErrors} onCompanyChanged={onContactCompanyChanged} onNameChanged={onContactNameChanged} onStreetChanged={onContactStreetChanged} onStreet2Changed={onContactStreet2Changed} onStreet3Changed={onContactStreet3Changed} onStreet4Changed={onContactStreet4Changed} onCityChanged={onContactCityChanged} onZipChanged={onContactZipChanged} onPhoneChanged={onContactPhoneChanged} onEmailChanged={onContactEmailChanged} onCountryIdChanged={onContactCountryIdChanged} onStateIdChanged={onContactAddressStateIdChanged} onCustomStateChanged={onContactAddressCustomStateChanged} disabled={isActionInProgress} handleLoadingStates={onLoadingStates} onOpenDropDown={handleOnOpenDropDown} scrollToElement={scrollToElement} />
            {mspType.includes(MspType.Customer) && !isEdit && <CheckboxLabel style={{ padding: "10px" }} label="Link M365" size="small" disabled={isActionInProgress} control={<Checkbox data-testid="linkM365CheckBox" size="medium" checked={isM365Linked} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onHandleLinkM365(ev.currentTarget.checked)} />} />}
          </div>
        );
      case 1:
        return (
          <div data-testid="addEditAccountBillingInfo">
            {isBa && (
              <div ref={accountNameRef}>
                <AddEditAccountDisplayName accountName={accountName} accountNameError={accountNameError} title={title} isActionInProgress={isActionInProgress} onAccountNameChanged={onAccountNameChanged} />
              </div>
            )}
            <AddEditAddressDialog
              countries={loadedCountries}
              isBilling={true}
              city={billingAddressCity}
              countryId={selectedBillingAddressCountryId}
              email={billingAddressEmail}
              name={billingAddressName}
              company={""}
              phone={billingAddressPhone}
              stateId={selectedBillingAddressStateId}
              street={billingAddressStreet}
              street2={billingAddressStreet2}
              street3={billingAddressStreet3}
              street4={billingAddressStreet4}
              customState={billingAddressCustomState}
              zip={billingAddressZip}
              errors={billingAddressErrors}
              onCompanyChanged={() => {
                /* no action needed*/
              }}
              onNameChanged={onBillingNameChanged}
              onStreetChanged={onBillingStreetChanged}
              onStreet2Changed={onBillingStreet2Changed}
              onStreet3Changed={onBillingStreet3Changed}
              onStreet4Changed={onBillingStreet4Changed}
              onCityChanged={onBillingCityChanged}
              onZipChanged={onBillingZipChanged}
              onPhoneChanged={onBillingPhoneChanged}
              onEmailChanged={onBillingEmailChanged}
              onStateIdChanged={onBillingAddressStateIdChanged}
              onCountryIdChanged={onBillingAddressCountryIdChanged}
              onCustomStateChanged={onBillingAddressCustomStateChanged}
              disabled={isActionInProgress}
              handleLoadingStates={onLoadingStates}
              onOpenDropDown={handleOnOpenDropDown}
              scrollToElement={scrollToElement}
            />
          </div>
        );
      default:
        return "Unknown step";
    }
  }
  const [activeStep, setActiveStep] = useState(dialogStep);

  const addressHasCustomState = (selectedCountryId: number): boolean => {
    return selectedCountryId > 0 ? loadedCountries[selectedCountryId - 1].states.length === 0 : false;
  };

  const getSelectedCountryName = (selectedCountryId: number): string => {
    return selectedCountryId > 0 ? loadedCountries[selectedCountryId - 1].countryName : "";
  };

  const getCountryId = (countryName: string): number => {
    return loadedCountries.findIndex(value => value.countryName === countryName) + 1;
  };

  const getStateId = (countryName: string, stateName: string): number => {
    const countryId = getCountryId(countryName);
    return loadedCountries[countryId - 1].states.findIndex(value => value.state === stateName) + 1;
  };

  const addressStateZip = (address: any, state: any, zip: any) => {
    let result;
    if (state === "" && zip === "") {
      if (address.hasCustomState) {
        result = { ...address };
      } else {
        result = { ...address, state: state, zip: zip };
      }
    } else if (state !== "" && zip === "") {
      if (address.hasCustomState) {
        result = { ...address, state: state };
      } else {
        result = { ...address, state: state, zip: zip };
      }
    } else if (state === "" && zip !== "") {
      result = { ...address, zip: zip };
    } else if (state !== "" && zip !== "") {
      result = { ...address, state: state, zip: zip };
    }
    return result;
  };
  const validateAccountName = (accName: string) => {
    let isErrorAccountName = false;
    if (accName.length < 1) {
      setAccountNameError("Enter display name");
      isErrorAccountName = true;
    } else if (accName.split(/\s+/).length > 5) {
      if (!AccountCompanyNameValidationRule.RegularExpressionMoreThanFiveWords.test(accName)) {
        setAccountNameError("Display name contains invalid characters");
        isErrorAccountName = true;
      }
    } else if (accName.split(/\s+/).length < 6) {
      if (!AccountCompanyNameValidationRule.RegularExpressionUnderFiveWords.test(accName)) {
        setAccountNameError("Display name contains invalid characters");
        isErrorAccountName = true;
      }
    }
    if (isErrorAccountName) {
      setScrollToAccountName(accountNameRef);
    }
    return isErrorAccountName;
  };

  const handleNext = () => {
    const selectedCountry = getSelectedCountryName(selectedContactAddressCountryId);
    const selectedState = getSelectedStateName(loadedCountries, contactAddressCustomState, selectedContactAddressCountryId, selectedContactAddressStateId);
    const contactAddress = {
      company: contactAddressCompany.trim(),
      name: contactAddressName.trim(),
      email: contactAddressEmail.trim(),
      street: contactAddressStreet.trim(),
      street2: contactAddressStreet2?.trim(),
      street3: contactAddressStreet3?.trim(),
      street4: contactAddressStreet4?.trim(),
      phone: contactAddressPhone.trim(),
      city: contactAddressCity.trim(),
      country: selectedCountry.trim(),
      hasCustomState: addressHasCustomState(selectedContactAddressCountryId),
    };
    const resultAddressContact = addressStateZip(contactAddress, selectedState, contactAddressZip.trim());
    const entity = {
      partnerId: isEdit ? selectedItem.partnerId : selectedItem.id,
      id: isEdit ? selectedItem.id : 0,
      name: accountName,
      addresses: {
        isContactBillingSame: true,
        contact: resultAddressContact,
      },
      type: isEdit ? selectedItem.type : mspType,
      accounts: isEdit ? selectedItem.accounts : [],
      userAdminFlag: false,
      userBillFlag: false,
    };
    switch (activeStep) {
      case 0: {
        let isError = false;
        const contactAddressValidationErrors = validateAddress(entity.addresses.contact, true);
        isError = validateAccountName(accountName);
        if (contactAddressValidationErrors.length === 0) {
          if (!isError) {
            if (dialogSteps.length === 1) {
              onSubmit(selectedItem.id, entity as any, isM365Linked);
            } else {
              setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
          }
          setContactAddressErrors([]);
        } else {
          setContactAddressErrors(contactAddressValidationErrors);
          handleScrollToError(contactAddressValidationErrors);
        }
        break;
      }
      case 1: {
        let isError = false;
        const billingEntity = billingEntityObject();
        if (isBa) {
          isError = validateAccountName(accountName);
        }
        if (billingEntity.billingAddressValidationErrors.length === 0) {
          if (!isError) {
            const entityWithBilling = {
              ...entity,
              addresses: {
                isContactBillingSame: false,
                ...(!isBa && { contact: entity.addresses.contact }),
                billing: billingEntity.resultAddressBilling,
              },
            };
            onSubmit(selectedItem.id, entityWithBilling as any);
          }
        } else {
          setBillingAddressErrors(billingEntity.billingAddressValidationErrors);
          handleScrollToError(billingEntity.billingAddressValidationErrors);
        }
        break;
      }
      default:
        break;
    }
  };

  const billingEntityObject = () => {
    const selectedCountry = getSelectedCountryName(selectedBillingAddressCountryId);
    const selectedState = getSelectedStateName(loadedCountries, billingAddressCustomState, selectedBillingAddressCountryId, selectedBillingAddressStateId);
    const billingAddress = {
      name: billingAddressName.trim(),
      email: billingAddressEmail.trim(),
      street: billingAddressStreet.trim(),
      street2: billingAddressStreet2?.trim(),
      street3: billingAddressStreet3?.trim(),
      street4: billingAddressStreet4?.trim(),
      phone: billingAddressPhone.trim(),
      city: billingAddressCity.trim(),
      country: selectedCountry.trim(),
      hasCustomState: addressHasCustomState(selectedBillingAddressCountryId),
    };

    const resultAddressBilling = addressStateZip(billingAddress, selectedState, billingAddressZip.trim());
    const billingAddressValidationErrors = validateAddress(resultAddressBilling, false);
    const result = {
      resultAddressBilling,
      selectedState,
      selectedCountry,
      billingAddressValidationErrors,
    };
    return result;
  };
  const handleBack = () => {
    setBillingAddressErrors([]);
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  useEffect(() => {
    if (isPartner) {
      setDialogSteps(["Account details and contact info", "Billing info"]);
    } else if (isBa) {
      setDialogSteps(["Account details and billing info"]);
    } else {
      setDialogSteps(["Account details and contact info"]);
    }
  }, [isPartner, isBa]);

  useEffect(() => {
    setNextButtonLable(getAddEditConfirmButtonText(isBa, activeStep, dialogSteps.length));
  }, [isBa, activeStep, dialogSteps]);

  const onHandleLinkM365 = (checked: boolean) => {
    setIsM365Linked(checked);
  };

  return (
    <Dialog className="addEditAccountDialog" disableEscapeKeyDown={isActionInProgress} onKeyUp={(event: any) => (!dropdownIsOpen ? enterKey(event, handleNext) : "")} data-testid="addEditAccountDialog" disableBackdropClick={true} open={showDialog} onClose={onCancel} maxWidth={"sm"} style={{ zIndex: 4002 }}>
      <div className="addEditAccount">
        <DialogTitle data-testid="addEditAccountDialogTitle" id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          {loadingAddEditDialog ? (
            <div data-testid="loadingAddEditDialog" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <LinearProgress />
            </div>
          ) : (
            <div className="DialogContentDiv" style={{ padding: 15 }}>
              <Grid item xs={12}>
                {getStepContent(activeStep)}
              </Grid>
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <Button data-testid="cancelAddEditAccountBtn" variant={loadingAddEditDialog ? "contained" : "text"} size="large" disabled={isActionInProgress} onClick={onCancel}>
            CANCEL
          </Button>
          {isPartner && !loadingAddEditDialog && (
            <Button data-testid="backAddEditAccountBtn" variant="text" size="large" disabled={isActionInProgress || activeStep === 0} onClick={handleBack}>
              BACK
            </Button>
          )}
          {!loadingAddEditDialog && (
            <Button data-testid="confirmNextAddEditAccountBtn" type={"submit"} isLoading={isActionInProgress} disabled={isActionInProgress || isLoadingStates} onClick={() => handleNext()}>
              {nextButtonLabel}
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddEditAccountDialog;

export function getAddEditConfirmButtonText(isBa: boolean, activeStep: number, dialogStepsLength: number): string {
  if (isBa || activeStep === dialogStepsLength - 1) {
    return "CONFIRM";
  }
  return "NEXT";
}

export function getSelectedStateName(countriesList: ICountry[], custState: string, selCountryId: number, selStateId: number): string {
  if (!isStringNullOrEmpty(custState)) {
    return custState;
  }
  if (selStateId > 0) {
    return countriesList[selCountryId - 1].states[selStateId - 1].state;
  }
  return "";
}
