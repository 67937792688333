import React from "react";
import * as CoreIcons from "@barracuda/bds-core/dist/Icons/Core";
import Grid from "@barracuda/bds-core/dist/Grid";
import Tooltip from "@barracuda/bds-core/dist/Tooltip";

const AuditUser = (props: any) => {
  return (
    <td>
      <Tooltip title={props.dataItem.user}>
        <Grid item container>
          <Grid className={props.dataItem.isExcluded ? "grayedOut" : "none"} container item xs={1} style={{ alignContent: "center" }}>
            <CoreIcons.Account />
          </Grid>
          <Grid container item xs={11}>
            <div className={(props.dataItem.isExcluded ? "grayedOut" : "none") + " textNoWrap"}>{props.dataItem.user}</div>
          </Grid>
        </Grid>
      </Tooltip>
    </td>
  );
};

export default AuditUser;
