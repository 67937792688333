import React from "react";
import { useDispatch } from "react-redux";
import { cancelCurrentAction, setShowPartnerResourcesAction } from "../../actions/generalActions";
import { Button } from "@barracuda/bds-core";

const PartnerResourcesButton = () => {
  const dispatch = useDispatch();

  const handleSetShowPartnerResources = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(setShowPartnerResourcesAction(true));
    dispatch(cancelCurrentAction());
  };

  // Only display for partners and sub-partners
  return (
    <div style={{ paddingTop: "4px" }}>
      <Button data-testid="partnerResourcesBtn" variant={"contained"} color={"secondary"} size={"medium"} onClick={(event: React.SyntheticEvent) => handleSetShowPartnerResources(event)}>
        Partner Resources
      </Button>
    </div>
  );
};

export default PartnerResourcesButton;
