import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@barracuda/bds-core";
import React from "react";
import { enterKey } from "../../../utility";
interface ISubmitDeactivateDialogProps {
  onCancel: () => void;
  showDeactivateDialog: boolean;
  isActionInProgress: boolean;
  dialogTitle: string | undefined;
}
const ShowDeactivateDialog: React.FC<ISubmitDeactivateDialogProps> = ({ showDeactivateDialog, onCancel, isActionInProgress, dialogTitle }) => {
  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onCancel)} disableEscapeKeyDown={isActionInProgress} className="deactivateDialog" data-testid="deactivateDialog" disableBackdropClick={true} open={showDeactivateDialog} onClose={onCancel}>
      <DialogTitle data-testid="deactivateTitle"> {"DEACTIVATE " + dialogTitle} </DialogTitle>
      <DialogContent style={{ paddingTop: 26, paddingBottom: 19, paddingRight: 27, paddingLeft: 27 }}>
        <div data-testid="deactivateMessage">
          <Typography data-testid="deactivateMessage" variant="h6">
            To deactivate,{" "}
            <a href="https://barracudamsp.com/essentials-change/" rel="noopener noreferrer" target="_blank">
              click here
            </a>{" "}
            or call: 1-800-569-0155, Option 1
            <br />
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button data-testid="okDeactivate" primary="true" size="large" disabled={isActionInProgress} onClick={onCancel} style={{ marginRight: 16 }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowDeactivateDialog;
