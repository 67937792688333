import React, { useEffect, useState } from "react";
import Chip from "@material-ui/core/Chip";
import { IAppState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { computeChipsNamesFromFilters, removeFilterFromState } from "../../../businessLogic/components/Accounts/FilterAccounts/FilterAccountListDialog";
import { setAccountFiltersAction } from "../../../actions/accountActions";
import FilterAccountsOptions from "../../../models/FilterAccountsOptions";

interface IAccountFilterChipsProps {
  disabled: boolean;
}

const AccountFilterChips: React.FC<IAccountFilterChipsProps> = ({ disabled }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state: IAppState) => state.accountState.filters);
  const [filterNames, setFilterNames] = useState<string[]>([]);

  useEffect(() => {
    setFilterNames(computeChipsNamesFromFilters(filters));
  }, [filters]);

  const onChipDelete = (name: string) => {
    dispatch(setAccountFiltersAction(removeFilterFromState(filters, name)));
  };

  return (
    <div style={{ padding: "10px" }} data-testid="chipsSection">
      {filterNames.map((value, index) => (
        <Chip data-testid={"chipElement" + index} key={"FilteringChip" + index} label={value === FilterAccountsOptions.LoginUserAssociated ? "Associated with: " + filters.associatedLogin : value} onDelete={() => onChipDelete(value)} disabled={disabled} />
      ))}
    </div>
  );
};

export default AccountFilterChips;
