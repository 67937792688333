import React, { useEffect, useState } from "react";
import IAccount from "../../models/IAccount";
import { Grid } from "@barracuda/bds-core";
import AddressCard from "./AddressCard";
import EditAccountAddressButton from "./EditAccountAddressButton";
import IAccountAddress from "../../models/IAccountAddress";
import IAddress from "../../models/IAddress";
import { isBillingInfoAvailable, isBillingInfoOnly, shouldDisplayEditContactButton } from "../../Utilities/accountsHelper";
import ICountry from "../../models/ICountry";

interface IAddressesCardPanelProps {
  mspAccountLoggedIn: IAccount;
  selectedAccount: IAccount | undefined;
  addressToDisplay: IAccountAddress | undefined | null;
  handleOpenEditDialog: (isBilling: boolean) => void;
  handleSetHeight: (value: number) => void;
  loadingEdit: boolean;
  addressCardHeight: number;
  countries: ICountry[];
}

const AddressesCardPanel: React.FC<IAddressesCardPanelProps> = ({ mspAccountLoggedIn, selectedAccount, addressToDisplay, handleOpenEditDialog, handleSetHeight, loadingEdit, addressCardHeight, countries }) => {
  const [displayBillingInsteadOfContact, setDisplayBillingInsteadOfContact] = useState(false);
  const [displayBillingCard, setDisplayBillingCard] = useState(false);
  const [displayEditContactButton, setDisplayEditContactButton] = useState(false);

  useEffect(() => {
    if (selectedAccount) {
      const displayBillingInContactCard = isBillingInfoOnly(mspAccountLoggedIn, selectedAccount);
      if (displayBillingInContactCard) {
        setDisplayBillingInsteadOfContact(true);
        setDisplayBillingCard(false);
      } else {
        setDisplayBillingInsteadOfContact(false);
        if (addressToDisplay?.isContactBillingSame) {
          setDisplayBillingCard(false);
        } else {
          setDisplayBillingCard(isBillingInfoAvailable(mspAccountLoggedIn, selectedAccount));
        }
      }
      setDisplayEditContactButton(shouldDisplayEditContactButton(mspAccountLoggedIn, selectedAccount));
    }
  }, [mspAccountLoggedIn, selectedAccount, addressToDisplay]);

  return (
    <Grid container item xs={12}>
      <Grid container item xs={6}>
        <Grid item xs={11}>
          <div data-testid="contactAddressCard">
            <AddressCard isBilling={displayBillingInsteadOfContact} address={displayBillingInsteadOfContact ? addressToDisplay?.billing : addressToDisplay?.contact} onSetHeight={handleSetHeight} countries={countries} />
          </div>
        </Grid>
        {displayEditContactButton && (
          <Grid container item xs={1} style={{ justifyContent: "start", marginTop: "16px" }}>
            <Grid item className={"editAccountBtnContactColumn"}>
              <EditAccountAddressButton loggedInAccount={mspAccountLoggedIn} isBilling={displayBillingInsteadOfContact} handleOpenEditDialog={() => handleOpenEditDialog(displayBillingInsteadOfContact)} selectedAccount={selectedAccount} />
            </Grid>
          </Grid>
        )}
      </Grid>

      {displayBillingCard && (
        <Grid container item xs={6}>
          <Grid container item xs={1} style={{ justifyContent: "end", marginTop: "16px" }}>
            <Grid item className={"editAccountBtnBillingColumn"}>
              <EditAccountAddressButton loggedInAccount={mspAccountLoggedIn} isBilling={true} handleOpenEditDialog={() => handleOpenEditDialog(true)} selectedAccount={selectedAccount} />
            </Grid>
          </Grid>
          <Grid item xs={11}>
            <div data-testid="billingAddressCard">
              <AddressCard isBilling={true} address={getBillingAddressToDisplay(addressToDisplay)} onSetHeight={handleSetHeight} height={addressCardHeight} countries={countries} />
            </div>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default AddressesCardPanel;

export function getBillingAddressToDisplay(addressToDisplay: IAccountAddress | null | undefined): IAddress | null | undefined {
  if (addressToDisplay === undefined || addressToDisplay === null) {
    return addressToDisplay;
  } else return !addressToDisplay.isContactBillingSame && addressToDisplay.billing ? addressToDisplay?.billing : null;
}
