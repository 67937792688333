enum FilterAccountsOptions {
  AllActivatedProductsServices = "All Activated Products & Services",
  NoActivatedProducts = "No Activated Products",
  HasActivationErrors = "Has Activation Errors",
  M365LinkedOnly = "M365 Linked Only",
  M365UnlinkedOnly = "M365 Unlinked Only",
  HasSMBLogins = "Has SMB logins",
  LoginUserAssociated = "Login user associated with them",
  HasIntronisBackup = "Has Intronis Backup",
}

export default FilterAccountsOptions;
