import React from "react";
import { Checkbox } from "@barracuda/bds-core";
import { CircularProgress } from "@material-ui/core";

const CheckBoxCommandCell = (props: any) => {
  return (
    <td style={{ display: "flex" }}>
      <Checkbox disabled={props.disabled} checked={props.checked} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => props.onChange(props.dataItem, ev.currentTarget.checked)} />
      {props.isLoading && (
        <span style={{ marginTop: "auto" }}>
          <CircularProgress data-testid="loadingIncludeExcludeAction" size="25px" style={{ marginTop: "auto" }} />
        </span>
      )}
    </td>
  );
};

export default CheckBoxCommandCell;
