import React, { useState, useEffect } from "react";
import Grid from "@barracuda/bds-core/dist/Grid";
import { Button, Typography, TextField, CheckboxLabel, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Tooltip } from "@barracuda/bds-core";
import IUser from "../../../models/IUser";
import UserRole from "../../../models/UserRole";
import EntitlementsTable from "./EntitlementsTable";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import { EmailValidationRule, NameValidationRule } from "../../../fieldsValidationRules";
import DropDown from "../../DropDown";
import MspType from "../../../models/MspType";
import { loadUserEntitlementsForAccount } from "../../../actions/userActions";
import IEntitlement from "../../../models/IEntitlement";
import { getAccountAccess, getCurrentRoles, getToolTipHelperForBaUsers, getUserRoleDropdownValue, setAddEditUserDialogWidth } from "../../../Utilities/usersHelper";
import { enterKey } from "../../../utility";
import IAccount from "../../../models/IAccount";
import { useMediaQuery } from "@material-ui/core";
import * as CoreIcons from "@barracuda/bds-core/dist/Icons/Core";

interface ISubmitDialogProps {
  onSubmit: (user: IUser) => void;
  onCancel: () => void;
  onDelete: () => void;
  showDialog: boolean;
  isEdit: boolean;
  dialogTitle: string;
  user?: IUser;
  isActionInProgress: boolean;
  isAddFirstUser: boolean;
}

const AddEditUserDialog: React.FC<ISubmitDialogProps> = ({ showDialog, onDelete, onCancel, onSubmit, isEdit, user, dialogTitle, isActionInProgress, isAddFirstUser }) => {
  const dispatch = useDispatch();
  const loadingUsers = useSelector((state: IAppState) => state.userState.loadingUsers);
  const loadingUsersExtraInfo = useSelector((state: IAppState) => state.userState.loadingUsersExtraInfo);
  const loadingEntitlements = useSelector((state: IAppState) => state.userState.loadingEntitlements);
  const entitlements = useSelector((state: IAppState) => state.userState.entitlements);
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserBillingAdministration, setNewUserBillingAdministration] = useState(false);
  const [newUserUserManagement, setNewUserUserManagement] = useState(false);
  const [selectedRole, setSelectedRole] = useState(0);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const loggedUser = useSelector((state: IAppState) => state.generalState.loggedUser);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const [isEditingLoggedInUser, setIsEditingLoggedInUser] = useState(false);
  const [id, setId] = useState(0);
  const [explicitAccountId, setExplicitAccountId] = useState(0);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const allRoles = getCurrentRoles(selectedAccount);
  const roles = allRoles.map(x => x.label);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showUserRoleDropdown, setShowUserRoleDropdown] = useState(false);
  const [entitlementsSelection, setEntitlementsSelection] = useState<IEntitlement[]>([]);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const [witdh, setwitdh] = useState(680);

  useEffect(() => {
    if (loggedUser && selectedAccount && selectedAccount.type !== MspType.BillingAggregator) {
      dispatch(loadUserEntitlementsForAccount(loggedUser.id, isEdit ? user?.explicitAccountId : selectedAccount?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, selectedAccount, isEdit, user]);

  useEffect(() => {
    setId(user ? user.id : 0);
    setExplicitAccountId(user ? user.explicitAccountId : 0);
    if (isEdit) {
      setIsEditingLoggedInUser(user?.id === loggedUser.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, user, selectedAccount]);

  useEffect(() => {
    if (isEdit) {
      setEntitlementsSelection([]);
      if (user && entitlements.length > 0) {
        const u: IEntitlement[] = [];
        entitlements.forEach((en: IEntitlement) => {
          let found = false;
          user.entitlements?.forEach((userEn: IEntitlement) => {
            if (userEn.service_id === en.service_id) {
              found = true;
            }
          });
          u.push({ ...en, checked: found });
        });
        setEntitlementsSelection(u);
      }
    } else {
      const u = entitlements.map((en: IEntitlement) => ({ ...en, checked: true }));
      setEntitlementsSelection(u);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitlements, isEdit, user]);

  const onNameChanged = (newValue: string) => {
    setNameError("");
    setNewUserName(newValue);
  };

  const onEmailChanged = (newValue: string) => {
    setEmailError("");
    setNewUserEmail(newValue);
  };

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [roleError, setRoleError] = useState("");

  const handleRoleChange = (newValue: number) => {
    setRoleError("");
    setSelectedRole(newValue);
    setNewUserUserManagement(false);
    setNewUserBillingAdministration(false);
    if (newValue === UserRole.Admin + 1) {
      setNewUserUserManagement(true);
      setNewUserBillingAdministration(true);
    } else if (newValue === UserRole.Finance + 1) {
      setNewUserBillingAdministration(true);
    }
  };

  const onEntitlementsSelectionChanged = (items: IEntitlement[]) => {
    setEntitlementsSelection(items);
  };

  const handleChangeUserManagement = (checked: boolean) => {
    setNewUserUserManagement(checked);
  };

  const handleChangeBillingAdministration = (checked: boolean) => {
    setNewUserBillingAdministration(checked);
  };

  useEffect(() => {
    if (isEdit && user !== undefined) {
      setNewUserName(user?.name);
      setNewUserEmail(user?.email);
      setNewUserBillingAdministration(user?.billingAdministration ? user?.billingAdministration : false);
      setNewUserUserManagement(user?.userManagement ? user?.userManagement : false);
      if (selectedAccount?.type === MspType.BillingAggregator) {
        setSelectedRole(Number(user?.role) - 4);
      } else {
        setSelectedRole(Number(user?.role) + 1);
      }
    }
    // eslint-disable-next-line
  }, [isEdit, user]);

  useEffect(() => {
    if (isAddFirstUser) {
      setNewUserBillingAdministration(true);
      setNewUserUserManagement(true);
      setSelectedRole(1);
    }
    // eslint-disable-next-line
  }, [isAddFirstUser]);

  const handleOnAddEditClicked = (newUser: IUser) => {
    let isError = false;
    if (newUser.name.length < 1) {
      isError = true;
      setNameError("Enter name");
    } else {
      if (!NameValidationRule.RegularExpression.test(newUser.name)) {
        isError = true;
        setNameError("User name contains invalid characters");
      }
    }
    if (newUser.email.length < 1) {
      isError = true;
      setEmailError("Enter email");
    } else {
      if (!EmailValidationRule.TildeRegExpression.test(newUser.email)) {
        isError = true;
        setEmailError("Invalid email");
      } else {
        if (!EmailValidationRule.RegularExpression.test(newUser.email)) {
          isError = true;
          setEmailError("Invalid email");
        }
      }
    }
    if (newUser.role === 0) {
      isError = true;
      setRoleError("Select a role");
    }

    if (!isError) {
      newUser.role = getUserRoleDropdownValue(selectedAccount, newUser);
      if (newUser.role === UserRole.Finance || newUser.role === UserRole.ReadOnly) {
        newUser.entitlements = [];
      } else {
        newUser.entitlements = entitlementsSelection.filter((x: IEntitlement) => x.checked === true);
      }
      onSubmit(newUser);
    }
  };

  useEffect(() => {
    if (isEdit && (mspAccountLoggedIn.userAdminFlag || mspAccountLoggedIn?.type === MspType.BillingAggregator) && loggedUser.id !== user?.id) {
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
    // eslint-disable-next-line
  }, [isEdit, mspAccountLoggedIn, loggedUser, user]);

  const handleOnOpenDropdown = (isOpen: boolean) => {
    setDropdownIsOpen(isOpen);
  };

  useEffect(() => {
    if ((isEdit && user?.role !== UserRole.ReadOnly) || (!isEdit && selectedAccount?.type !== MspType.Customer)) {
      setShowUserRoleDropdown(true);
    } else {
      setShowUserRoleDropdown(false);
    }

    // eslint-disable-next-line
  }, [isEdit, user, selectedAccount]);

  useEffect(() => {
    if (!isEdit && selectedAccount?.type === MspType.Customer) {
      setSelectedRole(UserRole.ReadOnly + 1);
    }
    // eslint-disable-next-line
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedAccount?.type === MspType.BillingAggregator || selectedAccount?.type === MspType.Customer) {
      responsiveViewPortTriggerMin ? setwitdh(500) : setwitdh(400);
    } else {
      responsiveViewPortTriggerMin ? setwitdh(680) : setwitdh(550);
    }
    // eslint-disable-next-line
  }, [responsiveViewPortTriggerMin]);

  const submitEvent = () => {
    handleOnAddEditClicked({
      id: id,
      email: newUserEmail.trim(),
      role: selectedRole,
      billingAdministration: newUserBillingAdministration,
      userManagement: newUserUserManagement,
      entitlements: entitlementsSelection,
      name: newUserName.trim(),
      explicitAccountId: explicitAccountId,
      accountAccess: selectedAccount && selectedAccount.type !== MspType.BillingAggregator ? getAccountAccess(selectedAccount) : "",
    });
  };

  function addEditUserContent(dialogTitleProp: string, newUserNameProp: string, nameErrorProp: string, isActionInProgressProp: boolean, onNameChangedProp: (newValue: string) => void, newUserEmailProp: string, emailErrorProp: string, isEditingLoggedInUserProp: boolean, onEmailChangedProp: (newValue: string) => void, showUserRoleDropdown: boolean, isAddFirstUserProp: boolean, selectedAccountProp: IAccount | undefined, selectedRoleProp: number, onEntitlementsSelectionChangedProp: (items: IEntitlement[]) => void, entitlementsSelectionProp: IEntitlement[], showDeleteButton: boolean, onCancelProp: () => void, handleOnAddEditClickedProp: (newUser: IUser) => void, idProp: number, newUserBillingAdministrationProp: boolean, newUserUserManagementProp: boolean, explicitAccountIdProp: number, loadingEntitlementsProp: boolean) {
    return (
      <div className="addEditUser" style={{ width: witdh }}>
        <DialogTitle data-testid="addEditUserDialogTitle" idProp="alert-dialog-title">
          {dialogTitleProp}
        </DialogTitle>
        <DialogContent>
          <Grid item container spacing={3}>
            <Grid container item xs={setAddEditUserDialogWidth(selectedAccount, "name")}>
              <Grid item xs={12} container direction="column">
                <TextField
                  data-testid="userNameTxt"
                  size={"small"}
                  label="NAME"
                  value={newUserNameProp}
                  error={nameErrorProp.length > 0}
                  disabled={isActionInProgressProp}
                  required
                  helperText={nameErrorProp}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onNameChangedProp(ev.target.value)}
                  inputProps={{
                    maxLength: 64,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={setAddEditUserDialogWidth(selectedAccount, "email")}>
              <Grid item xs={12} container direction="column">
                <TextField
                  data-testid="userEmailTxt"
                  label="EMAIL"
                  size={"small"}
                  value={newUserEmailProp}
                  error={emailErrorProp.length > 0}
                  disabled={isActionInProgressProp || isEditingLoggedInUserProp}
                  required
                  helperText={emailErrorProp}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onEmailChangedProp(ev.target.value)}
                  inputProps={{
                    maxLength: 64,
                  }}
                />
              </Grid>
            </Grid>
            {showUserRoleDropdown && (
              <Grid container item xs={12} style={{ paddingTop: "20px" }}>
                <Grid xs={setAddEditUserDialogWidth(selectedAccount, "role")} item container>
                  <Grid item xs={11}>
                    <div data-testid="userRoleDropdown">
                      <DropDown label={"ROLE"} options={roles} selectedOptionId={selectedRole} error={roleError} handleChange={handleRoleChange} disabled={isActionInProgress || isEditingLoggedInUser || isAddFirstUser} onOpen={handleOnOpenDropdown} />
                    </div>
                  </Grid>
                </Grid>

                {selectedAccountProp?.type === MspType.BillingAggregator && (
                  <Grid item xs={4} container>
                    <Grid item xs={1} container style={{ paddingTop: "20px", marginLeft: "-10px" }}>
                      <Tooltip title={<div style={{ whiteSpace: "pre-wrap" }}>{getToolTipHelperForBaUsers()}</div>}>
                        <div data-testid="usersRolesHelperIcon">
                          <CoreIcons.Help />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}

                {selectedAccountProp?.type !== MspType.BillingAggregator && (
                  <Grid item xs={8} container direction="column" style={{ position: "relative", top: "9px" }}>
                    <Grid item xs={12} container>
                      <Grid item xs={6} container>
                        <CheckboxLabel data-testid={"userManagementCheckbox"} label="User Management" size="small" control={<Checkbox data-testid="userManagementCheckbox" size="medium" checked={newUserUserManagement} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => handleChangeUserManagement(ev.currentTarget.checked)} disabled />} />
                      </Grid>
                      <Grid item xs={6} container>
                        <CheckboxLabel data-testid={"billingAdministrationCheckbox"} label={"Billing Administration"} size="small" control={<Checkbox data-testid="billingAdministrationCheckbox" size="medium" checked={newUserBillingAdministration} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => handleChangeBillingAdministration(ev.currentTarget.checked)} disabled />} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
            {selectedAccountProp?.type !== MspType.Customer && selectedAccountProp?.type !== MspType.BillingAggregator && selectedRoleProp !== UserRole.Finance + 1 && (
              <Grid container item xs={12}>
                <Grid item xs={12} container direction="column">
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      ENTITLEMENTS
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div data-testid={"entitlementsTable"}>
                      <EntitlementsTable selectionChanged={onEntitlementsSelectionChangedProp} userEntitlements={entitlementsSelectionProp} disabled={isActionInProgressProp || isEditingLoggedInUserProp || isAddFirstUserProp} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          {showDeleteButton && (
            <Button data-testid="deleteUserButton" variant="contained" color="secondary" type={"submit"} disabled={isActionInProgress || loadingUsers || loadingUsersExtraInfo} isLoading={loadingUsers || loadingUsersExtraInfo} onClick={onDelete}>
              DELETE
            </Button>
          )}
          <div style={{ flex: "1 0 0" }} />
          <Button data-testid="cancelAddEditUserBtn" variant="text" size="large" disabled={isActionInProgressProp} onClick={onCancelProp}>
            {isAddFirstUserProp ? "BACK" : "CANCEL"}
          </Button>
          <Button
            data-testid="confirmAddEditUserBtn"
            primary="true"
            isLoading={isActionInProgressProp}
            disabled={isActionInProgressProp || loadingEntitlementsProp}
            type={"submit"}
            onClick={() =>
              handleOnAddEditClickedProp({
                id: idProp,
                email: newUserEmailProp.trim().toLocaleLowerCase(),
                role: selectedRoleProp,
                billingAdministration: newUserBillingAdministrationProp,
                userManagement: newUserUserManagementProp,
                entitlements: entitlementsSelectionProp,
                name: newUserNameProp.trim(),
                explicitAccountId: explicitAccountIdProp,
                accountAccess: selectedAccountProp && selectedAccountProp.type !== MspType.BillingAggregator ? getAccountAccess(selectedAccountProp) : "",
              })
            }
          >
            CONFIRM
          </Button>
        </DialogActions>
      </div>
    );
  }

  return (
    <div>
      {isAddFirstUser ? (
        <Paper onKeyUp={(event: any) => (!dropdownIsOpen ? enterKey(event, submitEvent) : "")} disableEscapeKeyDown={isActionInProgress} className="addEdiUserDialog" data-testid="addEdiUserDialog" disableBackdropClick={true} open={showDialog} onClose={onCancel} maxWidth={false}>
          {addEditUserContent(dialogTitle, newUserName, nameError, isActionInProgress, onNameChanged, newUserEmail, emailError, isEditingLoggedInUser, onEmailChanged, showUserRoleDropdown, isAddFirstUser, selectedAccount, selectedRole, onEntitlementsSelectionChanged, entitlementsSelection, showDeleteButton, onCancel, handleOnAddEditClicked, id, newUserBillingAdministration, newUserUserManagement, explicitAccountId, loadingEntitlements)}
        </Paper>
      ) : (
        <Dialog onKeyUp={(event: any) => (!dropdownIsOpen ? enterKey(event, submitEvent) : "")} disableEscapeKeyDown={isActionInProgress} className="addEdiUserDialog" data-testid="addEdiUserDialog" disableBackdropClick={true} open={showDialog} onClose={onCancel} maxWidth={false}>
          {addEditUserContent(dialogTitle, newUserName, nameError, isActionInProgress, onNameChanged, newUserEmail, emailError, isEditingLoggedInUser, onEmailChanged, showUserRoleDropdown, isAddFirstUser, selectedAccount, selectedRole, onEntitlementsSelectionChanged, entitlementsSelection, showDeleteButton, onCancel, handleOnAddEditClicked, id, newUserBillingAdministration, newUserUserManagement, explicitAccountId, loadingEntitlements)}
        </Dialog>
      )}
    </div>
  );
};

export default AddEditUserDialog;
