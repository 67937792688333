import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@barracuda/bds-core";
import React from "react";
import { enterKey } from "../../../utility";

interface ISubmitUnassignedDialogProps {
  onCancel: () => void;
  showUnassignedDialog: boolean;
  isActionInProgress: boolean;
  onUnassign: () => void;
}
const ShowUnassignedDialog: React.FC<ISubmitUnassignedDialogProps> = ({ showUnassignedDialog, onCancel, onUnassign, isActionInProgress }) => {
  return (
    <div>
      <Dialog onKeyUp={(event: any) => enterKey(event, onUnassign)} disableEscapeKeyDown={isActionInProgress} className="showUnassignDialog" data-testid="showUnassignDialog" disableBackdropClick={true} open={showUnassignedDialog} onClose={onCancel} maxWidth={false}>
        <div style={{ width: "500px" }}>
          <DialogTitle data-testid="showUnassignDialogTitle" id="alert-dialog-title" style={{ backgroundColor: "primary" }}>
            Unassign Product / Service
          </DialogTitle>
          <DialogContent data-testid="unassignDialogMessage">
            <p>
              You are revoking this Subpartner's access to this product / service and they may no longer be able to activate it to their accounts. <br />
              <br />
              Click "Confirm" below to proceed with unassigning this product / service from this Subpartner.
            </p>
          </DialogContent>
          <DialogActions style={{ paddingTop: "18px", paddingBottom: "18px" }}>
            <Button data-testid="cancelUnassignButton" color="secondary" size="large" onClick={onCancel} disabled={isActionInProgress}>
              CANCEL
            </Button>
            <Button data-testid="confirmUnassignButton" color="primary" size="large" onClick={onUnassign} isLoading={isActionInProgress} disabled={isActionInProgress}>
              CONFIRM
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default ShowUnassignedDialog;
