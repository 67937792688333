import React, { useEffect, useRef, useState } from "react";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import * as CoreIcons from "@barracuda/bds-core/dist/Icons/Core";
import { TextField, Typography } from "@barracuda/bds-core";
import Grid from "@barracuda/bds-core/dist/Grid";
import DropDown from "../../DropDown";
import ICountry, { IState } from "../../../models/ICountry";
import { isStringNullOrEmpty } from "../../../utility";
import { useDispatch } from "react-redux";
import { getStatesAction } from "../../../actions/accountActions";
import { getStateLable, isUK } from "../../../Utilities/accountsHelper";

interface IFieldValidator {
  name: string;
  error: string;
  index: number;
}

interface IAddressDialogProps {
  countries: ICountry[];
  isBilling: boolean;
  company: string;
  name: string;
  city: string;
  countryId: number;
  email: string;
  phone: string;
  stateId: number;
  customState: string;
  street: string;
  street2?: string;
  street3?: string;
  street4?: string;
  zip: string;
  errors: IFieldValidator[];
  onCompanyChanged: (newValue: any) => void;
  onNameChanged: (newValue: any) => void;
  onStreetChanged: (newValue: any) => void;
  onStreet2Changed: (newValue: any) => void;
  onStreet3Changed: (newValue: any) => void;
  onStreet4Changed: (newValue: any) => void;
  onCityChanged: (newValue: any) => void;
  onZipChanged: (newValue: any) => void;
  onPhoneChanged: (newValue: any) => void;
  onEmailChanged: (newValue: any) => void;
  onCountryIdChanged: (newValue: any) => void;
  onCustomStateChanged: (newValue: any) => void;
  onStateIdChanged: (newValue: any) => void;
  disabled: boolean;
  handleLoadingStates: (newValue: boolean) => void;
  onOpenDropDown: (isOpen: boolean) => void;
  scrollToElement?: IFieldValidator;
}

const AddEditAddressDialog: React.FC<IAddressDialogProps> = ({ company, countries, isBilling, city, countryId, email, name, phone, stateId, customState, street, street2, street3, street4, zip, errors, onCompanyChanged, onNameChanged, onStreetChanged, onStreet2Changed, onStreet3Changed, onStreet4Changed, onCityChanged, onCountryIdChanged, onZipChanged, onPhoneChanged, onEmailChanged, onStateIdChanged, onCustomStateChanged, disabled, handleLoadingStates, onOpenDropDown, scrollToElement }) => {
  const dispatch = useDispatch();
  const [address2Visibility, setAddress2Visibility] = useState(!isStringNullOrEmpty(street2));
  const [address3Visibility, setAddress3Visibility] = useState(!isStringNullOrEmpty(street3));
  const [address4Visibility, setAddress4Visibility] = useState(!isStringNullOrEmpty(street4));
  const [stateLabel, setStateLabel] = useState("STATE");
  const [stateOptions, setStateOptions] = useState<string[]>([]);
  const isEmailDisabled = isBilling && !isStringNullOrEmpty(email);
  const useStyles = makeStyles(theme => ({
    popper: {
      zIndex: 9000,
    },
  }));
  let classes = useStyles();

  const getStates = (countryIndex: number): IState[] => {
    if (countries && countries.length > countryIndex) {
      if (countries[countryIndex - 1]) {
        return countries[countryIndex - 1].states;
      }
    }
    return [];
  };

  const [nameError, setNameError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [streetError, setStreetError] = useState("");
  const [street2Error, setStreet2Error] = useState("");
  const [street3Error, setStreet3Error] = useState("");
  const [street4Error, setStreet4Error] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [zipError, setZipError] = useState("");
  const [stateError, setStateError] = useState("");
  const [customStateError, setCustomStateError] = useState("");

  const nameRef = useRef<any>();
  const companyRef = useRef<any>();
  const emailRef = useRef<any>();
  const streetRef = useRef<any>();
  const phoneRef = useRef<any>();
  const cityRef = useRef<any>();
  const countryRef = useRef<any>();
  const zipRef = useRef<any>();
  const stateRef = useRef<any>();

  const [states, setStates] = useState<IState[]>([]);

  const [loadingStates, setLoadingStates] = useState(false);

  const [stateZipRequired, setStateZipRequired] = useState(false);

  useEffect(() => {
    handleLoadingStates(loadingStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingStates]);

  useEffect(() => {
    let currentStates = getStates(countryId);
    setStates(currentStates);
    if (currentStates && currentStates.length > 0 && !loadingStates) {
      if (isUK(countries[countryId - 1].countryName)) {
        setStateOptions(currentStates.map(s => s.stateName));
      } else {
        setStateOptions(currentStates.map(s => s.state));
      }
    }
    if (countryId > 0 && countries && (countries[countryId - 1].country === "US" || countries[countryId - 1].country === "CA")) {
      setStateZipRequired(true);
    } else {
      setStateZipRequired(false);
    }
    handleStateChanged(stateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, countryId]);

  useEffect(() => {
    if (countryId > 0 && countries) {
      setStateLabel(getStateLable(countries[countryId - 1].countryName, true).toUpperCase());
    }
  }, [countries, countryId]);

  useEffect(() => {
    if (scrollToElement) {
      switch (scrollToElement.name) {
        case "name":
          nameRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "company":
          companyRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "street":
          streetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "email":
          emailRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "phone":
          phoneRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "city":
          cityRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "country":
          countryRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "zip":
          zipRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case "state":
          stateRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        default:
          break;
      }
    }
  }, [scrollToElement]);

  useEffect(() => {
    errors.forEach(value => {
      switch (value.name) {
        case "name":
          setNameError(value.error);
          break;
        case "company":
          setCompanyError(value.error);
          break;
        case "street":
          setStreetError(value.error);
          break;
        case "street2":
          setStreet2Error(value.error);
          break;
        case "street3":
          setStreet3Error(value.error);
          break;
        case "street4":
          setStreet4Error(value.error);
          break;
        case "email":
          setEmailError(value.error);
          break;
        case "phone":
          setPhoneError(value.error);
          break;
        case "city":
          setCityError(value.error);
          break;
        case "country":
          setCountryError(value.error);
          break;
        case "zip":
          setZipError(value.error);
          break;
        case "state":
          setStateError(value.error);
          break;
        case "customState":
          setCustomStateError(value.error);
          break;
        default:
          break;
      }
    });
  }, [errors]);

  const handleNameChanged = (newValue: string) => {
    setNameError("");
    onNameChanged(newValue);
  };

  const handleCompanyChanged = (newValue: string) => {
    setCompanyError("");
    onCompanyChanged(newValue);
  };

  const handleEmailChanged = (newValue: string) => {
    setEmailError("");
    onEmailChanged(newValue);
  };

  const handleStreetChanged = (newValue: string) => {
    setStreetError("");
    onStreetChanged(newValue);
  };

  const handleStreet2Changed = (newValue: string) => {
    setStreet2Error("");
    onStreet2Changed(newValue);
  };

  const handleStreet3Changed = (newValue: string) => {
    setStreet3Error("");
    onStreet3Changed(newValue);
  };

  const handleStreet4Changed = (newValue: string) => {
    setStreet4Error("");
    onStreet4Changed(newValue);
  };

  const handlePhoneChanged = (newValue: string) => {
    setPhoneError("");
    onPhoneChanged(newValue);
  };

  const loadStates = (countryCode: string) =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(getStatesAction(countryCode));
      resolve(newAccountId);
    });

  const handleCountryChanged = (newValue: number) => {
    setCountryError("");
    const selCountry = countries[newValue - 1];
    if (selCountry) {
      setLoadingStates(true);
      loadStates(selCountry.country).then(result => {
        setLoadingStates(false);
        setStates(result);
        onCountryIdChanged(newValue);
        handleStateChanged(0);
      });
      setZipError("");
    }
  };

  const handleCityChanged = (newValue: string) => {
    setCityError("");
    onCityChanged(newValue);
  };

  const handleZipChanged = (newValue: string) => {
    setZipError("");
    onZipChanged(newValue);
  };

  const handleStateChanged = (newValue: number) => {
    setStateError("");
    onStateIdChanged(newValue);
  };

  const handleCustomStateChanged = (newValue: string) => {
    setCustomStateError("");
    onCustomStateChanged(newValue);
  };

  const handleOnOpenDropdown = (isOpen: boolean) => {
    onOpenDropDown(isOpen);
  };

  return (
    <div>
      <Typography data-testid="addressInfoTitle" variant="h6" gutterBottom style={{ padding: "10px" }}>
        {isBilling ? "BILLING INFORMATION" : "CONTACT INFORMATION"}
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
          <TextField
            data-testid="accountNameTxt"
            label={isBilling ? "BILLING NAME" : "CONTACT NAME"}
            size={"small"}
            value={name}
            error={nameError.length > 0}
            ref={nameRef}
            required
            disabled={disabled}
            helperText={nameError}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleNameChanged(ev.target.value)}
            inputProps={{
              maxLength: 64,
            }}
          />
        </Grid>
        <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
          <Tooltip title={isEmailDisabled ? "To change your Billing Address, please contact the Barracuda MSP Billing Department" : ""} classes={{ popper: classes.popper }}>
            <TextField
              data-testid="accountEmailTxt"
              label="EMAIL"
              size={"small"}
              value={email}
              error={emailError.length > 0}
              ref={emailRef}
              required
              disabled={disabled || isEmailDisabled}
              helperText={emailError}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleEmailChanged(ev.target.value)}
              inputProps={{
                maxLength: 64,
              }}
            />
          </Tooltip>
        </Grid>
        {!isBilling && (
          <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
            <TextField
              data-testid="accountCompanyTxt"
              label="COMPANY NAME"
              size={"small"}
              value={company}
              error={companyError.length > 0}
              ref={companyRef}
              required
              disabled={disabled}
              helperText={companyError}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCompanyChanged(ev.target.value)}
              inputProps={{
                maxLength: 64,
              }}
            />
          </Grid>
        )}
        <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
          <TextField
            data-testid="accountAddressTxt"
            label="ADDRESS"
            size={"small"}
            value={street}
            error={streetError.length > 0}
            ref={streetRef}
            required
            disabled={disabled}
            helperText={streetError}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleStreetChanged(ev.target.value)}
            inputProps={{
              maxLength: 32,
            }}
          />
        </Grid>
        {!address2Visibility && (
          <Grid item xs={1} container direction="column" className="IconAddAddress">
            <IconButton data-testid="addAddress2Btn" aria-label="add" size={"small"} disabled={disabled} onClick={() => setAddress2Visibility(true)}>
              <CoreIcons.Add></CoreIcons.Add>
            </IconButton>
          </Grid>
        )}
        {address2Visibility && (
          <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
            <TextField
              data-testid="accountAddress2Txt"
              label="ADDRESS 2"
              size={"small"}
              value={street2}
              error={street2Error.length > 0}
              ref={streetRef}
              disabled={disabled}
              helperText={street2Error}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleStreet2Changed(ev.target.value)}
              inputProps={{
                maxLength: 32,
              }}
            />
          </Grid>
        )}
        {address2Visibility && !address3Visibility && (
          <Grid item xs={1} container direction="column" className="IconAddAddress">
            <IconButton data-testid="addAddress3Btn" aria-label="add" size={"small"} disabled={disabled} onClick={() => setAddress3Visibility(true)}>
              <CoreIcons.Add></CoreIcons.Add>
            </IconButton>
          </Grid>
        )}

        {address3Visibility && (
          <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
            <TextField
              data-testid="accountAddress3Txt"
              label="ADDRESS 3"
              size={"small"}
              value={street3}
              error={street3Error.length > 0}
              ref={streetRef}
              disabled={disabled}
              helperText={street3Error}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleStreet3Changed(ev.target.value)}
              inputProps={{
                maxLength: 32,
              }}
            />
          </Grid>
        )}
        {address3Visibility && !address4Visibility && (
          <Grid item xs={1} container direction="column" className="IconAddAddress">
            <IconButton data-testid="addAddress4Btn" aria-label="add" size={"small"} disabled={disabled} onClick={() => setAddress4Visibility(true)}>
              <CoreIcons.Add></CoreIcons.Add>
            </IconButton>
          </Grid>
        )}
        {address4Visibility && (
          <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
            <TextField
              data-testid="accountAddress4Txt"
              label="ADDRESS 4"
              size={"small"}
              value={street4}
              error={street4Error.length > 0}
              ref={streetRef}
              disabled={disabled}
              helperText={street4Error}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleStreet4Changed(ev.target.value)}
              inputProps={{
                maxLength: 32,
              }}
            />
          </Grid>
        )}
        <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
          <TextField
            data-testid="accountCityTxt"
            label="CITY"
            size={"small"}
            value={city}
            error={cityError.length > 0}
            ref={cityRef}
            required
            disabled={disabled}
            helperText={cityError}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCityChanged(ev.target.value)}
            inputProps={{
              maxLength: 32,
            }}
          />
        </Grid>
        <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
          <div data-testid="accountCountryDropdown" ref={countryRef}>
            <DropDown label={"COUNTRY"} options={countries?.map(c => `${c.countryName} - ${c.country}`)} selectedOptionId={countryId} error={countryError} disabled={disabled} handleChange={handleCountryChanged} onOpen={handleOnOpenDropdown} />
          </div>
        </Grid>
        <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
          {states && states.length > 0 ? (
            <div data-testid="accountStateDropdown" ref={stateRef}>
              <DropDown label={stateLabel} options={stateOptions} selectedOptionId={stateId} error={stateError} handleChange={handleStateChanged} disabled={disabled} required={stateZipRequired} onOpen={handleOnOpenDropdown} />
            </div>
          ) : (
            <TextField
              data-testid="accountStateTxt"
              label="STATE"
              size={"small"}
              value={customState}
              error={customStateError.length > 0}
              ref={stateRef}
              helperText={customStateError}
              disabled={disabled}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleCustomStateChanged(ev.target.value)}
              inputProps={{
                maxLength: 32,
              }}
            />
          )}
        </Grid>
        <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
          <TextField
            data-testid="accountZipTxt"
            label="ZIP/POSTAL CODE"
            size={"small"}
            value={zip}
            error={zipError.length > 0}
            ref={zipRef}
            disabled={disabled}
            required={stateZipRequired}
            helperText={zipError}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleZipChanged(ev.target.value)}
            inputProps={{
              maxLength: 10,
            }}
          />
        </Grid>
        <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
          <TextField
            data-testid="accountPhoneTxt"
            label="PHONE NUMBER"
            size={"small"}
            value={phone}
            error={phoneError.length > 0}
            ref={phoneRef}
            required
            disabled={disabled}
            helperText={phoneError}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handlePhoneChanged(ev.target.value)}
            inputProps={{
              maxLength: 100,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddEditAddressDialog;
