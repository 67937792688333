import produce from "immer";
import { getSettingsForProductButtons, IProductButtonsSettings } from "../businessLogic/components/Products/ProductsButtonActions";
import { getProductFromAccountProductsIndexes } from "../businessLogic/products";
import IAccount from "../models/IAccount";
import IAccountSlim from "../models/IAccountSlim";
import IGroupedDropDownSuboption from "../models/IGroupedDropDownSuboption";
import IService from "../models/IService";
import MspType from "../models/MspType";
import IAccountProducts from "../models/Products/IAccountProducts";
import IOrder from "../models/Products/IOrder";
import IProduct from "../models/Products/IProduct";
import IProductFamily from "../models/Products/IProductFamily";
import ISerial from "../models/Products/ISerial";
import { IOrderSummary } from "../models/Products/OrderSummary";
import ProductFamily, { getProductTypeNameToDisplay, IProductFamillyNames } from "../models/Products/ProductFamily";
import ProductNames from "../models/Products/ProductNames";
import SerialStatus from "../models/Products/SerialStatus";
import { dynamicSort, eliminateDuplicates, groupBy, isStringNullOrEmpty } from "../utility";
import { findAccountById } from "./accountsHelper";
import IAccountOrders from "../models/Products/IAccountOrders";
import IOrderSlim from "../models/Products/IOrderSlim";

export interface IProductDisplayInfo {
  name: string;
  subname: string;
  account?: string;
  overages?: number;
  serial?: string;
  nameAndModel?: string;
  bbsOrderStatus?: string;
}

export const getProductDisplayInfo = (product: IOrder, order: IOrderSummary, mspAccounts: IAccount[] | undefined): IProductDisplayInfo => {
  let name: string = "";
  let subname: string = "";
  let account: string = "";
  let overages: number = 0;
  let serial: string | undefined = undefined;
  let nameAndModel: string = "";

  if (product.familyName.includes(ProductFamily.ESSENTIALS_SERIVICES) || product.familyName.includes(ProductFamily.CONTENT_SHIELD)) {
    name = product.bundleName;
    subname = !isStringNullOrEmpty(product.description) ? product.description : product.bundleName;
    overages = order.totalUsers - product.noOfUnits;
    if (order.finalSerials && order.finalSerials.length > 0) {
      serial = order.finalSerials[0].serial;
    }
    return { name, subname, overages, serial };
  }

  let bbsOrderStatus = "";
  name = product.prodSku;
  subname = !isStringNullOrEmpty(product.description) ? product.description : product.familyName;
  if (order.finalSerials && order.finalSerials.length > 0) {
    serial = order.finalSerials[0].serial;
    bbsOrderStatus = order.finalSerials[0].status;
    account = getAccountNameForSerial(order.finalSerials[0], mspAccounts, undefined);
  }

  nameAndModel = subname + " " + name;
  return { name, subname, account, serial, nameAndModel, bbsOrderStatus, overages };
};

export const sortBBSProducts = (products: IProduct[]) => {
  products.sort((a, b) => {
    if (a.type.includes(ProductFamily.BACKUP_APPLIANCES) && b.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
      return a.subname.localeCompare(b.subname) || a.serials[0].serial.localeCompare(b.serials[0].serial);
    }

    return 0;
  });
};

export function overwriteSavedProductFamilyForAccount(products: IProductFamily[], accountId: number, accountsProducts: IAccountProducts[]): IAccountProducts[] {
  const updatedInfo: IAccountProducts = { accountId: accountId, productFamilies: products };
  const accountIndex = accountsProducts.findIndex((x: IAccountProducts) => x.accountId.toString() === accountId.toString());

  return produce(accountsProducts, draft => {
    if (accountIndex < 0) {
      draft.push(updatedInfo);
      return;
    }

    draft[accountIndex] = updatedInfo;
  });
}

export function addProductForAccount(product: IProduct, account: IAccount, accountsProducts: IAccountProducts[]): IAccountProducts[] {
  const newProductFamilyItem: IProductFamily = {
    productType: product.type,
    products: [product],
  };

  const accountIndex = accountsProducts.findIndex((x: IAccountProducts) => x.accountId === account.id);
  return produce(accountsProducts, (draft: IAccountProducts[]) => {
    if (accountIndex >= 0) {
      const productFamIndex = accountsProducts[accountIndex].productFamilies.findIndex((x: IProductFamily) => x.productType === product.type);
      if (productFamIndex > -1) {
        draft[accountIndex].productFamilies[productFamIndex].products.push(product);
        return;
      }

      draft[accountIndex] = {
        accountId: account.id,
        productFamilies: [...accountsProducts[accountIndex].productFamilies, newProductFamilyItem],
      };
      return;
    }

    draft.push({ accountId: account.id, productFamilies: [newProductFamilyItem] });
  });
}

export function removeProductForAccount(product: IProduct, account: IAccount, accountsProducts: IAccountProducts[]): IAccountProducts[] {
  let nextStateAccountsProducts: IAccountProducts[] = [];
  const indexes = getProductFromAccountProductsIndexes(product, account.id, accountsProducts);
  if (indexes.productFound) {
    if (product.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
      const pIndexes = getProductFromAccountProductsIndexes(product, account.partnerId, accountsProducts);
      if (pIndexes.productFound) {
        nextStateAccountsProducts = produce(accountsProducts, draft => {
          draft[pIndexes.accountProductIndex].productFamilies[pIndexes.accountProductFamilyIndex].products[pIndexes.productIndex].subPartnerId = null;
        });
      } else {
        return accountsProducts; // todo should throw?
      }
      const nextStateAccountsProductsUpdated = produce(nextStateAccountsProducts, draft => {
        if (draft[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products.length > 1) {
          draft[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products.splice(indexes.productIndex, 1);
        } else {
          draft[indexes.accountProductIndex].productFamilies.splice(indexes.accountProductFamilyIndex, 1);
        }
      });
      return nextStateAccountsProductsUpdated;
    } else {
      nextStateAccountsProducts = produce(accountsProducts, draft => {
        if (draft[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products.length > 1) {
          draft[indexes.accountProductIndex].productFamilies[indexes.accountProductFamilyIndex].products.splice(indexes.productIndex, 1);
        } else {
          draft[indexes.accountProductIndex].productFamilies.splice(indexes.accountProductFamilyIndex, 1);
        }
      });
      return nextStateAccountsProducts;
    }
  }
  return accountsProducts;
}

export const getNoProductsMessage = (selectedAccount: IAccount | undefined): string => {
  if (selectedAccount) {
    let status = "activated";

    switch (selectedAccount?.type) {
      case MspType.BillingAggregator:
        return "You can export usage data for all MSPs associated with your account. To export data for just one MSP, please select the MSP on the left panel.";
      case MspType.Partner:
        status = "purchased";
        break;
      case MspType.Subpartner:
        status = "assigned";
        break;
    }

    return `No products ${status} yet`;
  }

  return "";
};

export function getProductsAvailableToAssign(parentProductsFamilies: IProductFamily[], productsToDisplay: IProductFamily[]): IProduct[] {
  let products: IProduct[] = [];
  parentProductsFamilies.forEach((pf: IProductFamily) => {
    // For each product within a product family
    pf.products.forEach((p: IProduct) => {
      // If the product is a backup appliance just add it
      if (p.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
        if (p.serials?.length > 0 && p.serials[0].status === SerialStatus.AVAILABLE && p.serials[0].accountId.toString() === "0" && p.subPartnerId === null) {
          products.push(p);
        }
        return;
      }

      // Check to see if any products have been assigned
      const wereAlreadyAdded = (ptd: IProductFamily): boolean => {
        const wereAssigned = (prod: IProduct) => prod.id === p.id && prod.unassigned !== true;
        return ptd.products.some(wereAssigned);
      };

      if (!productsToDisplay.some(wereAlreadyAdded)) products.push(p);
    });
  });
  sortBBSProducts(products);
  return products;
}

export function getProductsAvailableToActivate(parentProductsFamilies: IProductFamily[], productsToDisplay: IProductFamily[], parentAccount?: IAccount | IAccountSlim): IProduct[] {
  const alreadyActivatedServices = getSkuServicesForProducts(productsToDisplay);
  return getIntersectionOfServicesSkus(parentProductsFamilies, alreadyActivatedServices.activatedServicesIds, alreadyActivatedServices.hasContentShield, parentAccount);
}

export function getSkuServicesForProducts(productFamilies: IProductFamily[]): { activatedServicesIds: number[]; hasContentShield: boolean } {
  let activatedServicesIds: number[] = [];
  let hasContentShield: boolean = false;
  productFamilies.forEach((ptd: IProductFamily) => {
    if (ptd.productType === ProductFamily.CONTENT_SHIELD) {
      hasContentShield = true;
    }

    ptd.products.forEach((prod: IProduct) => {
      prod.services.forEach((servSku: IService) => {
        if (activatedServicesIds.indexOf(servSku.serviceId) === -1) {
          activatedServicesIds.push(servSku.serviceId);
        }
      });
    });
  });
  return { activatedServicesIds, hasContentShield };
}

export function getIntersectionOfServicesSkus(parentProductsFamilies: IProductFamily[], activatedServicesIds: number[], hasContentShield: boolean, parentAccount?: IAccount | IAccountSlim): IProduct[] {
  let products: IProduct[] = [];
  parentProductsFamilies.forEach((parentProductFamily: IProductFamily) => {
    parentProductFamily.products.forEach((parentProduct: IProduct) => {
      if (parentProductFamily.productType.includes(ProductFamily.BACKUP_APPLIANCES)) {
        if (parentProduct.serials?.length > 0 && parentProduct.serials[0].status === SerialStatus.AVAILABLE && parentProduct.serials[0].accountId.toString() === "0" && parentAccount !== undefined) {
          if ((parentAccount?.type === MspType.Partner && parentProduct.subPartnerId === null) || (parentAccount?.type === MspType.Subpartner && parentProduct.subPartnerId === parentAccount?.id)) {
            products.push(parentProduct);
          }
        }

        return;
      }

      const activatedServies = (value: IService) => activatedServicesIds.includes(value.serviceId);
      const noAssignedEssentialsServices = () => parentProductFamily.productType === ProductFamily.ESSENTIALS_SERIVICES && parentProduct.services.filter(activatedServies).length === 0;
      const noAssignedContentShieldServices = () => parentProductFamily.productType === ProductFamily.CONTENT_SHIELD && !hasContentShield;

      if (!parentProduct.unassigned && (noAssignedEssentialsServices() || noAssignedContentShieldServices())) {
        products.push(parentProduct);
      }
    });
  });
  sortBBSProducts(products);
  return products;
}

export const groupByFamilyAndByBundleName = (products: IProduct[], familyName: string): Record<string, IGroupedDropDownSuboption[]>[] => {
  const selectedGroup = products.filter((x: IProduct) => x.type === familyName);
  const productsByBundleName = groupBy(selectedGroup, "name");
  const bundleNames = Object.getOwnPropertyNames(productsByBundleName);
  bundleNames.sort();

  const opg: Record<string, IGroupedDropDownSuboption[]>[] = [];
  bundleNames.forEach((bundleName: any) => {
    const prods = products.filter((y: IProduct) => y.name === bundleName);
    const suboptions: IGroupedDropDownSuboption[] = prods.map((x: IProduct) => ({ id: x.id, label: x.subname, bundleSku: x.sku }));
    suboptions.sort(dynamicSort("label"));
    opg.push({ [bundleName]: suboptions });
  });

  return opg;
};

export const getStatusFromResponse = (responseStatus: number) => {
  switch (responseStatus) {
    case 200:
      return SerialStatus.ACTIVE;
    case 202:
      return SerialStatus.SSG_PENDING;
    default:
      return SerialStatus.ACTIVATION_FAILED;
  }
};

export function getAuditUsersButtonState(products: IProductFamily[], parentProducts: IProductFamily[]): { visible: boolean; enabled: boolean } {
  let hasSerials: boolean = false;
  let hasActiveSerials: boolean = false;
  let parentHasSerials: boolean = false;

  // Check each family
  for (const productFamily of products) {
    let productType = productFamily.productType;

    // Continue to the next product if the current type is not 'Essentials' or 'Content Shield'
    if (!productType.includes(ProductFamily.ESSENTIALS_SERIVICES) && !productType.includes(ProductFamily.CONTENT_SHIELD)) {
      continue;
    }

    // Check each family's products for a serial
    for (const product of productFamily.products) {
      if (product.type.includes(ProductFamily.ESSENTIALS_SERIVICES) || product.name.includes(ProductNames.BarracudaContentShieldPlus)) {
        hasSerials = true;
        hasActiveSerials = product.serials.some(serial => serial.status === SerialStatus.ACTIVE);
        if (hasActiveSerials) {
          break; // If an active serial was found, stop searching the family's products
        }
      }
    }

    // If an active serial was found, stop searching the families
    if (hasActiveSerials) break;
  }

  // If serials were found, return the information
  if (hasSerials) return { visible: hasSerials, enabled: hasActiveSerials };

  // If the serials were not found, check the parent
  parentHasSerials = parentProducts.some(productFamily => productFamily.productType.includes(ProductFamily.ESSENTIALS_SERIVICES) || productFamily.products.find(e => e.name.includes(ProductNames.BarracudaContentShieldPlus)) !== undefined);
  return { visible: parentHasSerials, enabled: false };
}

export const getStatus = (product: IProduct): string => {
  if (product.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
    if (product.bbsOrderStatus) {
      return product.bbsOrderStatus;
    }
    throw new Error("Barracuda Backup Appliances order should have serial");
  }

  return product.status;
};

export const isStatusPending = (status: string): boolean => {
  return status === SerialStatus.SSG_PENDING || status === SerialStatus.PENDING;
};

export function getDisplayProductFamilyNames(productsToActivate: IProduct[]): IProductFamillyNames[] {
  const productsByFamilyName = groupBy(productsToActivate, "type");
  const names: string[] = Object.getOwnPropertyNames(productsByFamilyName);
  const displayNames: IProductFamillyNames[] = names.map((name: string) => ({ productFamily: name, displayName: getProductTypeNameToDisplay(name) }));
  displayNames.sort(dynamicSort("displayName"));
  return displayNames;
}

export const isBBS = (id: number, availableFamilies: IProductFamillyNames[]): boolean => {
  return id > 0 && availableFamilies[id - 1].productFamily.includes(ProductFamily.BACKUP_APPLIANCES);
};

export const getBbsOptions = (productsToActivate: IProduct[]): string[] => {
  let bbsOptions: string[] = [];
  productsToActivate.forEach((x: IProduct) => {
    if (x.type === ProductFamily.BACKUP_APPLIANCES) {
      bbsOptions.push(x.subname + " - " + x.serial);
    }
  });
  return bbsOptions;
};

export function filterOrdersResult(ordersResult: any): any {
  return ordersResult.filter((product: IOrder) => ((product.familyName.includes(ProductFamily.ESSENTIALS_SERIVICES) || product.familyName.includes(ProductFamily.CONTENT_SHIELD)) && product.status === SerialStatus.ACTIVE) || product.familyName.includes(ProductFamily.BACKUP_APPLIANCES));
}

export function getCurrentlySelectedPairForProductFiltering(selectedAccountToFilterProductsForBA: IAccount, selAccount: IAccount | undefined): { currentlySelectedCustomerId: any; currentlySelectedSubpartnerId: any } {
  let custId = 0;
  let subpId = 0;
  if (selectedAccountToFilterProductsForBA.type === MspType.Subpartner) {
    subpId = selectedAccountToFilterProductsForBA.id;
  } else {
    custId = selectedAccountToFilterProductsForBA.id;
    if (selectedAccountToFilterProductsForBA.partnerId !== selAccount?.id) {
      subpId = selectedAccountToFilterProductsForBA.partnerId;
    }
  }
  return { currentlySelectedCustomerId: custId, currentlySelectedSubpartnerId: subpId };
}

export function shouldDisplayFilterDropdowns(loggedInAccount: IAccount, subpartners: IAccount[], customers: IAccount[]): boolean {
  if (loggedInAccount.type === MspType.BillingAggregator && (subpartners.length > 0 || customers.length > 0)) {
    return true;
  }
  return false;
}

export function getAccountNameForSerial(serial: ISerial, mspAccounts: IAccount[] | undefined, allAccounts: IAccountSlim[] | undefined): string {
  let accName = serial.accountName;
  if (accName === undefined || accName.length === 0) {
    if (mspAccounts && serial.accountId > 0) {
      accName = findAccountById(mspAccounts, allAccounts, serial.accountId)?.name;
    }
  }
  return accName ?? "";
}

export function getAccountNameForBbsOrder(bbs: IProduct, mspAccounts: IAccount[], allAccounts: IAccountSlim[] | undefined): string {
  let accName = bbs.account;
  if (accName === undefined || accName.length === 0) {
    if (mspAccounts && bbs.serials[0]?.accountId > 0) {
      accName = findAccountById(mspAccounts, allAccounts, bbs.serials[0]?.accountId)?.name;
    }
  }
  return accName ?? "";
}

export function setSerialTableAccountsColumnWidth(mspAccountLoggedIn: IAccount): any {
  if (mspAccountLoggedIn.type === MspType.BillingAggregator) {
    return undefined;
  } else {
    return 280;
  }
}

export function getProductsTabTitle(selectedAccountToFilterProductsForBA: IAccount | undefined, selectedAccount: IAccount | undefined) {
  if (selectedAccountToFilterProductsForBA) {
    return selectedAccountToFilterProductsForBA.name;
  } else {
    return selectedAccount ? selectedAccount.name : "";
  }
}

export function processProductsTabButtonsColor(mspAccounts: IAccount[], selectedAccount: IAccount | undefined, mspAccountLoggedIn: IAccount, parentProducts: IAccountProducts, productsToDisplay: IProductFamily[], canManageIntegration: boolean) {
  const productButtonsSettings: IProductButtonsSettings = getSettingsForProductButtons(mspAccounts, [], selectedAccount, mspAccountLoggedIn, parentProducts, productsToDisplay);
  let isPartnerWithNoPrimaryButtons = selectedAccount?.type === MspType.Partner && (!canManageIntegration || !productButtonsSettings.shouldShowAssignedSubpartnersButton);
  let isSubpartnerWithNoPrimaryButtons = selectedAccount?.type === MspType.Subpartner && (!productButtonsSettings.shouldShowAssignButton || productButtonsSettings.shouldAssignButtonBeDisabled);
  let isCustomerWithNoPrimaryButtons = selectedAccount?.type === MspType.Customer && (!productButtonsSettings.shouldShowActivateButton || productButtonsSettings.shouldActivateButtonBeDisabled);
  if (isPartnerWithNoPrimaryButtons || isSubpartnerWithNoPrimaryButtons || isCustomerWithNoPrimaryButtons) {
    return "primary";
  }
  return "secondary";
}

export function setGenerateCsvReportButtonColor(mspAccounts: IAccount[], selectedAccount: IAccount | undefined, mspAccountLoggedIn: IAccount, parentProducts: IAccountProducts, productsToDisplay: IProductFamily[], loadingProducts: boolean, canManageIntegration: boolean) {
  if (mspAccountLoggedIn?.type === MspType.BillingAggregator) {
    return "primary";
  } else {
    if (!canManageIntegration && loadingProducts) {
      return "primary";
    } else {
      return processProductsTabButtonsColor(mspAccounts, selectedAccount, mspAccountLoggedIn, parentProducts, productsToDisplay, canManageIntegration);
    }
  }
}

export function exportCsvPayloadAccountsIds(mspAccountLoggedIn: IAccount, selectedAccount: IAccount) {
  if (mspAccountLoggedIn.type === MspType.BillingAggregator) {
    if (selectedAccount.type === MspType.BillingAggregator) {
      return "";
    } else {
      return selectedAccount.id;
    }
  } else {
    if (selectedAccount.type === MspType.Partner) {
      return "";
    } else {
      return selectedAccount.id;
    }
  }
}

export function overwriteOrdersForAccount(orders: IOrder[], accountId: number, accountsOrders: IAccountOrders[]): IAccountOrders[] {
  let nextStateAccountsOrders: IAccountOrders[] = [];
  let uniqueEssOrderSlim: any[] = [];
  let uniqueCsBBsOrdersSlim: any[] = [];
  const orderSlim: IOrderSlim[] = orders.map((x: IOrder) => ({ bundleSku: x.bundleSku, bundleName: x.bundleName, familyName: x.familyName, prodSku: x.prodSku }));
  const essOrderSlim = orderSlim.filter(order => order.familyName === ProductFamily.ESSENTIALS_SERIVICES);
  const csBBsOrdersSlim = orderSlim.filter(order => order.familyName !== ProductFamily.ESSENTIALS_SERIVICES);
  if (essOrderSlim.length > 0) {
    uniqueEssOrderSlim = eliminateDuplicates(essOrderSlim, "bundleSku");
    uniqueEssOrderSlim.sort(dynamicSort("bundleName"));
  }
  if (csBBsOrdersSlim.length > 0) {
    uniqueCsBBsOrdersSlim = eliminateDuplicates(csBBsOrdersSlim, "prodSku");
    uniqueCsBBsOrdersSlim.sort(dynamicSort("bundleName"));
  }
  const uniqueItems = [...uniqueEssOrderSlim, ...uniqueCsBBsOrdersSlim];
  const updatedInfo: IAccountOrders = { accountId: accountId, orders: uniqueItems };
  const accountIndex = accountsOrders.findIndex((x: IAccountOrders) => x.accountId.toString() === accountId.toString());
  if (accountIndex < 0) {
    nextStateAccountsOrders = produce(accountsOrders, draft => {
      draft.push(updatedInfo);
    });
  } else {
    nextStateAccountsOrders = produce(accountsOrders, draft => {
      draft[accountIndex] = updatedInfo;
    });
  }
  return nextStateAccountsOrders;
}
