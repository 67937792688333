import React from "react";
import Grid from "@barracuda/bds-core/dist/Grid";
import * as CoreIcons from "@barracuda/bds-core/dist/Icons/Core";

const ExpandAccountWithExclusions = (props: any) => {
  return (
    <td onClick={() => props.onClick(props.dataItem)}>
      <Grid container item>
        <Grid container item xs={11} style={{ alignItems: "end" }}>
          {props.dataItem.total}
        </Grid>
        <Grid container item xs={1} style={{ alignItems: "center" }}>
          <CoreIcons.PageNext />
        </Grid>
      </Grid>
    </td>
  );
};

export default ExpandAccountWithExclusions;
