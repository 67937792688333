import React, { useEffect, useState } from "react";
import { Typography, LinearProgress, Button, Grid } from "@barracuda/bds-core";
import ProductAccordion from "./ProductAccordion";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import { changeSerialAction, deactivateProductsForAccountAction, getParentMspProductsAction, getProductSerialsToDisplayAction, getSerialsAction, setProductExpandedStatusAction, setSelectedProductAction, retryActivationProductAction, getProductsAction, getProductSerialForUser, unassignProductsForAccountAction, exportCsvReportAction } from "../../actions/productActions";
import ProductsButtonActions from "./ProductsButtonActions";
import MspType from "../../models/MspType";
import IProductFamily from "../../models/Products/IProductFamily";
import IAccount from "../../models/IAccount";
import ProductFamily from "../../models/Products/ProductFamily";
import ShowSerialsDialog from "./Serials/ShowSerialsDialog";
import MigrateDialog from "./Serials/MigrateDialog";
import IProduct from "../../models/Products/IProduct";
import { goToAccountAction, setPrevSelectedAccountAction, getLastUsageSyncTimeAction, loadParentAccountWithChildrenAction, cancelLoadCustomerParentAndItsChildrenAction, cancelLoadSubpartnerCustomersAction, getCustomers, filterAccountsAction } from "../../actions/accountActions";

import ShowEditSerialsDialog from "./Serials/ShowEditSerialsDialog";
import { findAccountById, searchByAccountId, showUnassignButton } from "../../Utilities/accountsHelper";
import ShowUnassignedDialog from "./Serials/ShowUnassignedDialog";
import ShowDeactivateDialog from "./Serials/ShowDeactivateDialog";
import { isStringNullOrEmpty, getDateToDisplay, getTimeToDisplay, getCanManageIntegrations, getCanExportDataUsage } from "../../utility";
import ShowCancelSerialDialog from "./Serials/ShowCancelSerialDialog";
import { getNoProductsMessage, getProductsTabTitle, setGenerateCsvReportButtonColor } from "../../Utilities/productsHelper";
import ShowDeactivateBBSDialog from "./Serials/ShowDeactivateBBSDialog";
import { Dispatch } from "redux";
import ISerial from "../../models/Products/ISerial";
import ShowMigrateConfirmationDialog from "./Serials/ShowMigrateConfirmationDialog";
import { getAccountProductFromAccountProducts } from "../../businessLogic/products";
import Tooltip from "@barracuda/bds-core/dist/Tooltip";
import { cancelCurrentAction, setSnackBarMessage, setViewIntegrationsAction } from "../../actions/generalActions";
import ProductFilters from "./ProductFilters";
import ExportUsageDialog from "./ExportUsageDialog";
import ActionMessageType from "../../models/ActionMessageType";
import { NoMspTypeError } from "../../models/constants";

export const getProductTabContent = (productsToDisplay: IProductFamily[], expandedStatus: Record<ProductFamily, boolean>, selectedAccount: IAccount | undefined, expandProductHandler: (productFamily: string, isExpanded: boolean) => void, handleOpenEditDialogHandler: (product: IProduct, selectedAccountType: string) => void, hasIBU: boolean, echoUrl?: string): JSX.Element | JSX.Element[] => {
  if (productsToDisplay && productsToDisplay.length > 0) {
    const acc = productsToDisplay?.map((item, idx) => {
      return (
        <div key={"paccordionDiv" + idx} data-testid={"productFamily" + idx}>
          <ProductAccordion key={"paccordion" + idx} tableKey={item.productType + idx} productFamily={item} expanded={expandedStatus[item.productType]} handleExpandProduct={expandProductHandler} handleOpenEditDialog={handleOpenEditDialogHandler} />
        </div>
      );
    });
    if (hasIBU) {
      acc.push(
        <div key={"paccordionDiv"} data-testid={"hasIntronisBackup"}>
          <ProductAccordion key={"paccordion"} tableKey={"x"} productFamily={undefined} expanded={expandedStatus[ProductFamily.INTRONIS_BACKUP]} handleExpandProduct={expandProductHandler} handleOpenEditDialog={handleOpenEditDialogHandler} hasIBU={hasIBU} />
        </div>,
      );
    }
    return acc;
  } else {
    if (hasIBU) {
      return (
        <div key={"paccordionDiv"} data-testid={"hasIntronisBackup"}>
          <ProductAccordion key={"paccordion"} tableKey={"x"} productFamily={undefined} expanded={expandedStatus[ProductFamily.INTRONIS_BACKUP]} handleExpandProduct={expandProductHandler} handleOpenEditDialog={handleOpenEditDialogHandler} hasIBU={hasIBU} />
        </div>
      );
    } else {
      return <Typography data-testid={"noProductsMessage"}>{getNoProductsMessage(selectedAccount)}</Typography>;
    }
  }
};

export interface IMigrationObject {
  orderLineItem: IProduct;
  serial: ISerial;
  lineItem: IProduct;
}

const ProductsTab: React.FC = () => {
  const dispatch = useDispatch();
  const loadingProducts = useSelector((state: IAppState) => state.productState.loadingProducts);
  const productsToDisplay = useSelector((state: IAppState) => state.productState.productsToDisplay);
  const accountsProducts = useSelector((state: IAppState) => state.productState.accountsProducts);
  const expandedStatus = useSelector((state: IAppState) => state.productState.expandedStatus);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const loadingHasSubpartnersWithProducts = useSelector((state: IAppState) => state.productState.loadingHasSubpartnersWithProducts);
  const hasSubpartnersWithProducts = useSelector((state: IAppState) => state.productState.hasSubpartnersWithProducts);
  const selectedAccountToFilterProductsForBA = useSelector((state: IAppState) => state.accountState.selectedAccountToFilterProductsForBA);
  const [accordions, setAccodions] = useState<JSX.Element | JSX.Element[]>();
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);
  const [lastUsageSyncTimeToDisplay, setLastUsageSyncTimeToDisplay] = useState("");
  const loadingPartnerAccounts = useSelector((state: IAppState) => state.accountState.loadingPartnerAccounts);
  const loadingAllChildrenAccountNames = useSelector((state: IAppState) => state.accountState.loadingAllChildrenAccountNames);
  const loadingCurrency = useSelector((state: IAppState) => state.productState.loadingCurrency);
  const serialsToDisplay = useSelector((state: IAppState) => state.productState.serialsToDisplay);
  const [showSerials, setShowSerials] = useState(false);
  const [showEditSerials, setShowEditSerials] = useState(false);
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const accountsNames = useSelector((state: IAppState) => state.accountState.accountsNames);
  const selectedProduct = useSelector((state: IAppState) => state.productState.selectedProduct);
  const echoUrl = useSelector((state: IAppState) => state.generalState.echoUrl);
  const [showLink, setShowLink] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [loadingAccountToGoTo, setLoadingAccountToGoTo] = useState(false);
  const [parentIsNotMsp, setParentIsNotMsp] = useState(false);
  const [showUnassignedDialog, setShowUnassignedDialog] = useState(false);
  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
  const [showDeactivateBBSDialog, setShowDeactivateBBSDialog] = useState(false);
  const [showCancelSerialDialog, setShowCancelSerialDialog] = useState(false);
  const [showMigrateDialog, setShowMigrateDialog] = useState(false);
  const [showUnassignedEditSerial, setShowUnassignedEditSerial] = useState(false);
  const prevSelectedAccount = useSelector((state: IAppState) => state.accountState.prevSelectedAccount);
  const [migrationOptions, setMigrationOptions] = useState<IProduct[]>([]);
  const [showConfirmMigrate, setShowConfirmMigrate] = useState(false);
  const [migrationObject, setMigrationObject] = useState<IMigrationObject | undefined>();
  const [canManageIntegration, setCanManageIntegration] = useState(false);
  const [manageIntegrationsButtonColor, setManageIntegrationsButtonColor] = useState(hasSubpartnersWithProducts ? "secondary" : "primary");
  const [selectedAccountName, setSelectedAccountName] = useState("");
  const [showGenereateCsvReport, setShowGenereateCsvReport] = useState(false);
  const isSelectedAccountBa = selectedAccount?.type === MspType.BillingAggregator;
  const parentProducts = useSelector((state: IAppState) => state.productState.parentProductsFamilies);
  const generateCsvReportButtonColor = setGenerateCsvReportButtonColor(mspAccounts, selectedAccount, mspAccountLoggedIn, parentProducts, productsToDisplay, loadingProducts, canManageIntegration);
  const loggedUser = useSelector((state: IAppState) => state.generalState.loggedUser);
  const [showHideLinearProgress, setshowHideLinearProgress] = useState(false);
  const [canExportDataUsage, setCanExportDataUsage] = useState(false);
  const hasIBU = useSelector((state: IAppState) => state.productState.hasIBU);
  const filters = useSelector((state: IAppState) => state.accountState.filters);
  const loadingHasIBU = useSelector((state: IAppState) => state.productState.loadingHasIBU);

  const handleExpandProduct = (productFamily: string, isExpanded: boolean) => {
    dispatch(setProductExpandedStatusAction(productFamily, isExpanded));
  };

  useEffect(() => {
    const loadLastUsageSyncTime = () =>
      new Promise<any>((resolve, reject) => {
        const result = dispatch(getLastUsageSyncTimeAction());
        resolve(result);
      });
    loadLastUsageSyncTime().then(result => {
      setLastUsageSyncTimeToDisplay(result);
    });
  }, [dispatch]);

  useEffect(() => {
    if (hasSubpartnersWithProducts) {
      setManageIntegrationsButtonColor("secondary");
      if (loadingProducts || loadingHasSubpartnersWithProducts || selectedAccount?.id !== mspAccountLoggedIn.id) {
        setManageIntegrationsButtonColor("primary");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubpartnersWithProducts, selectedAccount, loadingProducts, loadingHasSubpartnersWithProducts]);

  useEffect(() => {
    if ((loadingProducts || loadingPartnerAccounts || loadingAllChildrenAccountNames || loadingCurrency || loadingHasIBU) && !isSelectedAccountBa) {
      setshowHideLinearProgress(true);
    } else {
      setshowHideLinearProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProducts, loadingPartnerAccounts, loadingAllChildrenAccountNames, loadingCurrency, isSelectedAccountBa, loadingHasIBU]);

  useEffect(() => {
    const tabContent = getProductTabContent(productsToDisplay, expandedStatus, selectedAccount, handleExpandProduct, handleOpenEditDialog, hasIBU, echoUrl);
    setAccodions(tabContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsToDisplay, expandedStatus, selectedAccount, hasIBU]);

  const handleOpenEditDialog = (e: IProduct, selectedAccountType: string) => {
    dispatch(setSelectedProductAction(e));
    dispatch(getProductSerialsToDisplayAction(e));
    setShowLink(false);
    if (selectedAccountType === MspType.Customer) {
      setShowEditSerials(true);
      setShowSerials(false);
      dispatch(getProductSerialForUser(selectedAccount));
    } else {
      if (e.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
        setShowEditSerials(true);
        if (!isStringNullOrEmpty(e.account) && e.serials && e.serials.length > 0) {
          const goToAcccount = findAccountById(mspAccounts, accountsNames, e.serials[0].accountId);
          if (goToAcccount) {
            handleGoToAccount(goToAcccount.id);
          }
        } else if (e.subPartnerId !== null && isStringNullOrEmpty(e.account)) {
          if (selectedAccountType === MspType.Partner) {
            handleGoToAccount(e.subPartnerId, e);
          }
        }
      } else {
        setShowEditSerials(false);
        setShowSerials(true);
      }
    }
  };

  const handleOnCancel = () => {
    setShowEditSerials(false);
    setShowSerials(false);
    dispatch(setSelectedProductAction(null));
  };

  const unassignProduct = (product: IProduct) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(unassignProductsForAccountAction(selectedAccount, product));
      resolve(result);
    });

  const handleOnUnassign = () => {
    if (selectedProduct !== undefined) {
      setActionInProgress(true);
      unassignProduct(selectedProduct).then(result => {
        setActionInProgress(false);
        if (result) {
          if (selectedAccount) {
            if (selectedProduct.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
              dispatch(getParentMspProductsAction(selectedAccount));
            }
            dispatch(getProductsAction(selectedAccount));
            setShowUnassignedDialog(false);
            setShowUnassignedEditSerial(false);
            if (showLink) {
              onHandleBackToMSPAccounts(true);
            }
          }
        }
      });
    }
  };

  const deactivateProduct = (product: IProduct) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(deactivateProductsForAccountAction(mspAccountLoggedIn, product, selectedAccount));
      resolve(result);
    });

  const handleOnDeactivate = () => {
    if (selectedProduct !== undefined) {
      setActionInProgress(true);
      deactivateProduct(selectedProduct).then(result => {
        setActionInProgress(false);
        if (result) {
          if (selectedAccount) {
            if (selectedProduct.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
              dispatch(getParentMspProductsAction(selectedAccount));
            }
            dispatch(getProductsAction(selectedAccount));
            setShowDeactivateBBSDialog(false);
            if (showLink) {
              onHandleBackToMSPAccounts(true);
            }
          }
          if (filters.bbsProdSkus) {
            dispatch(filterAccountsAction());
          }
        }
      });
    }
  };

  const handleOnCancelEditSerial = () => {
    handleOnCancel();
  };

  const loadParentAccountWithChildren = (accountId: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(loadParentAccountWithChildrenAction(accountId, true));
      resolve(result);
    });

  const loadChildren = (accountId: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getCustomers(accountId));
      resolve(result);
    });

  const handleGoToAccount = (accountId: number, product?: IProduct): void => {
    const isSubpartner: boolean = product !== undefined;
    setShowLink(true);
    setShowSerials(false);
    setShowEditSerials(true);

    function handleGoToAcc(acc: IAccount) {
      dispatch(getProductSerialForUser(acc, product));
      dispatch(goToAccountAction(acc));
    }

    function loadParentAndGoToAccount(accountId: number) {
      setLoadingAccountToGoTo(true);
      loadParentAccountWithChildren(accountId).then(result => {
        if (result) {
          const partnerId = result.account?.partnerId;
          if (partnerId) {
            setParentIsNotMsp(false);
            handleGoToAcc({ ...result.account, partnerId: partnerId });
          } else {
            if (result.reason === NoMspTypeError) {
              setParentIsNotMsp(true);
            }
          }
        }
        setLoadingAccountToGoTo(false);
      });
    }

    function loadChildrenAndGoToAccount(accountId: number) {
      setLoadingAccountToGoTo(true);
      loadChildren(accountId).then(result => {
        if (result) {
          handleGoToAcc(result);
        }
        setLoadingAccountToGoTo(false);
      });
    }

    dispatch(setPrevSelectedAccountAction(selectedAccount));
    try {
      if (isSubpartner) {
        loadChildrenAndGoToAccount(accountId);
      } else {
        const goToAcc = searchByAccountId(mspAccounts, accountId);
        if (goToAcc) {
          handleGoToAcc(goToAcc);
        } else {
          loadParentAndGoToAccount(accountId);
        }
      }
    } catch {
      loadParentAndGoToAccount(accountId);
    }
  };

  const handleOpenUnassignedDialog = () => {
    setShowUnassignedDialog(true);
    if (showUnassignedEditSerial) {
      setShowEditSerials(false);
    } else {
      setShowSerials(false);
    }
  };
  const handleOnCancelUnassigned = () => {
    setShowUnassignedDialog(false);
    if (showUnassignedEditSerial) {
      setShowEditSerials(true);
      setShowUnassignedEditSerial(false);
    } else {
      setShowSerials(true);
    }
  };
  const handleOnDeactivateSerial = () => {
    setShowEditSerials(false);
    if (selectedProduct?.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
      setShowDeactivateBBSDialog(true);
    } else {
      setShowDeactivateDialog(true);
    }
  };
  const handleOnCloseDeactivate = () => {
    setShowEditSerials(true);
    if (selectedProduct?.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
      setShowDeactivateBBSDialog(false);
    } else {
      setShowDeactivateDialog(false);
    }
  };

  const onHandleBackToMSPAccounts = (refreshSerials: boolean) => {
    dispatch(cancelLoadSubpartnerCustomersAction());
    dispatch(cancelLoadCustomerParentAndItsChildrenAction());
    setShowLink(false);
    const newSelectedProduct = getAccountProductFromAccountProducts(selectedProduct, (prevSelectedAccount as IAccount).id, accountsProducts);
    if (refreshSerials) {
      dispatch(getProductSerialsToDisplayAction(newSelectedProduct));
    }
    dispatch(goToAccountAction(prevSelectedAccount));
    if (selectedProduct?.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
      setShowSerials(false);
      setShowEditSerials(false);
    } else {
      setShowSerials(true);
      setShowEditSerials(false);
    }
  };

  const handleOpenManageServiceDialog = (optionIndex: number) => {
    setShowEditSerials(false);
    setShowSerials(false);
    setShowMigrateDialog(true);
  };

  const changeSerial = (orderlineItem: IProduct, serial: ISerial, lineItem: IProduct) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(changeSerialAction(selectedAccount, orderlineItem, serial, lineItem));
      resolve(result);
    });

  const getSerials = (orderlineItem: IProduct, accountId: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getSerialsAction(accountId, orderlineItem));
      resolve(result);
    });

  const onMigrate = (orderLineItem: IProduct, serial: ISerial, lineItem: IProduct) => {
    setShowMigrateDialog(false);
    setMigrationObject({ orderLineItem: orderLineItem, serial: serial, lineItem: lineItem });
    setShowConfirmMigrate(true);
  };

  const handleOnCloseMigrate = () => {
    setShowMigrateDialog(false);
    setMigrationObject(undefined);
  };

  const handleConfirmMigrate = () => {
    if (migrationObject) {
      setShowConfirmMigrate(false);
      setActionInProgress(true);
      setShowMigrateDialog(true);
      changeSerial(migrationObject.orderLineItem, migrationObject.serial, migrationObject.lineItem).then(result => {
        if (result === true) {
          if (selectedAccount) {
            setMigrationObject(undefined);
            if (showLink && prevSelectedAccount) {
              getSerials(migrationObject.orderLineItem, prevSelectedAccount?.id).then(res => {
                onChangeSerialFinished(dispatch, selectedAccount, setShowMigrateDialog);
                onHandleBackToMSPAccounts(false);
              });
            } else {
              onChangeSerialFinished(dispatch, selectedAccount, setShowMigrateDialog);
            }
          }
        } else {
          setActionInProgress(false);
        }
      });
    }
  };

  const onChangeSerialFinished = (disp: Dispatch<any>, selectedAcc: IAccount | undefined, setShowMigrateDialogCallback: React.Dispatch<React.SetStateAction<boolean>>): void => {
    disp(getProductsAction(selectedAcc));
    setShowMigrateDialogCallback(false);
    setActionInProgress(false);
  };

  const handleCloseMigrateConfirmationDialog = () => {
    setShowConfirmMigrate(false);
    setShowMigrateDialog(true);
  };

  const onHandleBackToEditSerial = () => {
    setShowMigrateDialog(false);
    setShowEditSerials(true);
  };

  const onMigrationOptionsCalculated = (options: IProduct[]) => {
    setMigrationOptions(options);
  };
  const handleManageServiceDialog = () => {
    setShowEditSerials(false);
    setShowMigrateDialog(true);
  };

  const handleCancelSerialDialog = () => {
    setShowEditSerials(false);
    setShowCancelSerialDialog(true);
  };
  const handleOnCloseCancelSerial = () => {
    setShowEditSerials(true);
    setShowCancelSerialDialog(false);
  };
  const handleOnUnassignFromEditSerial = () => {
    setShowUnassignedEditSerial(true);
  };

  const retryActivationProduct = (product: IProduct, serials: ISerial) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(retryActivationProductAction(mspAccountLoggedIn, product, serials, selectedAccount));
      resolve(result);
    });

  const handleOnRetryActivation = () => {
    if (selectedProduct !== undefined) {
      setActionInProgress(true);
      if (selectedAccount) {
        retryActivationProduct(selectedProduct, serialsToDisplay[0]).then(result => {
          if (result) {
            if (selectedAccount) {
              dispatch(getProductsAction(selectedAccount));
              getSerials(selectedProduct, selectedAccount.id).then(res => {
                if (res) {
                  if (selectedProduct.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
                    dispatch(getParentMspProductsAction(selectedAccount));
                  }
                  setActionInProgress(false);
                }
              });
            }
          }
          if (filters.hasErrors) {
            dispatch(filterAccountsAction());
          }
        });
      }
    }
  };

  const getLastUsageSyncTimeToDisplay = (time: string) => {
    const t = new Date(time);
    return getDateToDisplay(t) + " @ " + getTimeToDisplay(t);
  };

  const renderLastUsageSyncInfo = () => {
    if (productsToDisplay && productsToDisplay.length > 0) {
      return (
        <div>
          <Grid container item xs={12} style={{ alignContent: "left" }}>
            <Typography data-testid="lastUsageSyncTitle" variant="subtitle2" style={{ fontWeight: "bold" }}>
              PRODUCT / SERVICE
            </Typography>
          </Grid>
          <Grid container item xs={12} style={{ alignContent: "left" }}>
            <Typography data-testid="lastUsageSyncTime" variant="subtitle2" style={{ color: "#8a8a8a" }}>
              Last Updated on {getLastUsageSyncTimeToDisplay(lastUsageSyncTimeToDisplay)}
            </Typography>
          </Grid>
        </div>
      );
    }
  };

  useEffect(() => {
    if (showUnassignedEditSerial) {
      handleOpenUnassignedDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUnassignedEditSerial]);

  const handleOpenManageIntegrations = () => {
    dispatch(setViewIntegrationsAction(true));
    dispatch(cancelCurrentAction());
  };

  useEffect(() => {
    setCanManageIntegration(getCanManageIntegrations(selectedAccount, mspAccountLoggedIn));
  }, [mspAccountLoggedIn, selectedAccount]);

  useEffect(() => {
    setCanExportDataUsage(getCanExportDataUsage(mspAccountLoggedIn));
  }, [mspAccountLoggedIn]);

  useEffect(() => {
    setSelectedAccountName(getProductsTabTitle(selectedAccountToFilterProductsForBA, selectedAccount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, selectedAccountToFilterProductsForBA]);

  const handleOpenGenereateCsvReport = () => {
    setShowGenereateCsvReport(true);
  };

  const handleCancelExportUsage = () => {
    setShowGenereateCsvReport(false);
  };

  const exportCsv = (mspAccountLoggedIn: IAccount, selectedAccount: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(exportCsvReportAction(mspAccountLoggedIn, selectedAccount));
      resolve(result);
    });

  const handelOnExportUsage = () => {
    setActionInProgress(true);
    if (selectedAccount) {
      exportCsv(mspAccountLoggedIn, selectedAccount).then(result => {
        if (result) {
          setShowGenereateCsvReport(false);
          dispatch(setSnackBarMessage({ message: "Email Csv Report has been requested", type: ActionMessageType.Success }));
        }
        setActionInProgress(false);
      });
    }
  };

  return (
    <div className={"ProductsTab"}>
      <Grid item container spacing={3} direction="column">
        <Grid item container>
          <Grid container item xs={10}>
            <Grid container spacing={1} style={{ marginLeft: "10px", paddingBottom: "10px" }}>
              <Typography data-testId="productsListTitleLbl" key="btnAddUser2" variant="h4" noWrap style={{ display: "flex" }}>
                Products {"&"} Services List for&nbsp;{" "}
                <Tooltip title={selectedAccountName}>
                  <Typography variant="h4" noWrap>
                    {selectedAccountName}
                  </Typography>
                </Tooltip>
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="flex-end">
            {!loadingProducts && <ProductsButtonActions />}
            {canManageIntegration && (
              <div>
                <Button data-testid={"ManageIntegrationsButton"} color={manageIntegrationsButtonColor} variant={"contained"} size={"large"} style={{ marginRight: "15px" }} onClick={handleOpenManageIntegrations}>
                  MANAGE INTEGRATIONS
                </Button>
              </div>
            )}
            {canExportDataUsage && (
              <div>
                <Button data-testid={"ExportUsageData"} color={generateCsvReportButtonColor} variant={"contained"} size={"large"} style={{ marginRight: "15px" }} onClick={handleOpenGenereateCsvReport}>
                  EXPORT USAGE DATA
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
        {isBaLoggedIn && !isSelectedAccountBa && (
          <Grid container item xs={12} style={{ justifyContent: "center" }} spacing={3}>
            <ProductFilters />
          </Grid>
        )}
        <Grid item xs={12}>
          {!loadingProducts && renderLastUsageSyncInfo()}
        </Grid>
        <Grid item xs={12}>
          {showHideLinearProgress ? (
            <div data-testid={"loadingProducts"}>
              <LinearProgress />
            </div>
          ) : (
            accordions
          )}
        </Grid>
      </Grid>

      {showSerials && selectedProduct && <ShowSerialsDialog showDialog={showSerials} onCancel={handleOnCancel} onGoToAccount={handleGoToAccount} dialogTitle={selectedProduct?.subname} onShowUnassignedDialog={handleOpenUnassignedDialog} showUnassignButton={showUnassignButton(selectedAccount, mspAccountLoggedIn)} disableUnassignButton={selectedProduct.unassigned} />}
      {showEditSerials && selectedProduct && serialsToDisplay?.length > 0 && <ShowEditSerialsDialog showDialog={showEditSerials} product={selectedProduct} onDeactivate={handleOnDeactivateSerial} onCancel={handleOnCancelEditSerial} onUnassign={handleOnUnassignFromEditSerial} onRetryActivation={handleOnRetryActivation} serialForUser={serialsToDisplay[0]} displayLink={showLink} onHandleBackToMSPAccounts={onHandleBackToMSPAccounts} handleOpenManageServiceDialog={handleOpenManageServiceDialog} onMigrationOptionsCalculated={onMigrationOptionsCalculated} onManageService={handleManageServiceDialog} onCancelSerial={handleCancelSerialDialog} showUnassignButton={showUnassignButton(selectedAccount, mspAccountLoggedIn)} isActionInProgress={actionInProgress} isLoadingParent={loadingAccountToGoTo || actionInProgress} parentIsNotMsp={parentIsNotMsp} />}
      {showUnassignedDialog && <ShowUnassignedDialog showUnassignedDialog={showUnassignedDialog} onCancel={handleOnCancelUnassigned} onUnassign={handleOnUnassign} isActionInProgress={actionInProgress} />}
      {showDeactivateDialog && <ShowDeactivateDialog showDeactivateDialog={showDeactivateDialog} onCancel={handleOnCloseDeactivate} isActionInProgress={actionInProgress} dialogTitle={selectedProduct?.subname} />}
      {showDeactivateBBSDialog && <ShowDeactivateBBSDialog showDeactivateBBSDialog={showDeactivateBBSDialog} onCancel={handleOnCloseDeactivate} isActionInProgress={actionInProgress} selectedProduct={selectedProduct} onSubmitDeactivate={handleOnDeactivate} />}
      {showCancelSerialDialog && <ShowCancelSerialDialog showCancelSerialDialog={showCancelSerialDialog} onCancel={handleOnCloseCancelSerial} dialogTitle={selectedProduct?.subname} />}
      {showConfirmMigrate && <ShowMigrateConfirmationDialog showDialog={showConfirmMigrate} onCancel={handleCloseMigrateConfirmationDialog} isActionInProgress={actionInProgress} onSubmit={handleConfirmMigrate} migrationOption={migrationObject} />}
      {showMigrateDialog && selectedProduct && <MigrateDialog showDialog={showMigrateDialog} onCancel={handleOnCloseMigrate} onSubmit={onMigrate} product={selectedProduct} products={migrationOptions} serial={serialsToDisplay[0]} onHandleBackToEditSerial={onHandleBackToEditSerial} isActionInProgress={actionInProgress} migrateToProd={migrationObject?.lineItem} />}
      {showGenereateCsvReport && selectedAccount && <ExportUsageDialog showExportUsageDialogDialog={showGenereateCsvReport} onCancel={handleCancelExportUsage} isActionInProgress={actionInProgress} onExportUsageData={handelOnExportUsage} selectedAccount={selectedAccount} emailAddress={loggedUser.email} />}
    </div>
  );
};
export default ProductsTab;
