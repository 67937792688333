import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { IAccountState, accountReducer } from "../reducers/accountReducer";
import { IUserState, userReducer } from "../reducers/userReducer";
import { IGeneralState, generalReducer } from "../reducers/generalReducer";
import { IProductState, productReducer } from "../reducers/productReducer";
import { IAuditUsersState, auditUsersReducer } from "../reducers/auditUsersReducer";
import { financeReducer, IFinanceState } from "../reducers/financeReducer";
import { IIntegrationsState, integrationsReducer } from "../reducers/integrations/integrationsReducer";
import { IIntegrationsAccountsState, integrationsAccountsReducer } from "../reducers/integrations/integrationsAccountsReducer";
import { IIntegrationsLogsState, integrationsLogsReducer } from "../reducers/integrations/integrationsLogsReducer";
import { IIntegrationsBillingMapState, integrationsBillingMapReducer } from "../reducers/integrations/integrationsBillingReducer";

const composedEnhancer = composeWithDevTools(applyMiddleware(thunk));

export interface IAppState {
  generalState: IGeneralState;
  accountState: IAccountState;
  userState: IUserState;
  productState: IProductState;
  auditUsersState: IAuditUsersState;
  financeState: IFinanceState;
  integrationsState: IIntegrationsState;
  integrationsAccountsState: IIntegrationsAccountsState;
  integrationsLogsState: IIntegrationsLogsState;
  integrationsBillingMapState: IIntegrationsBillingMapState;
}

const rootReducer = combineReducers<IAppState>({
  generalState: generalReducer,
  accountState: accountReducer,
  userState: userReducer,
  productState: productReducer,
  auditUsersState: auditUsersReducer,
  financeState: financeReducer,
  integrationsState: integrationsReducer,
  integrationsAccountsState: integrationsAccountsReducer,
  integrationsLogsState: integrationsLogsReducer,
  integrationsBillingMapState: integrationsBillingMapReducer,
});

export default function configureStore(): Store<IAppState, any> {
  const store = createStore(rootReducer, {}, composedEnhancer);
  return store;
}
