import React, { useState, useEffect } from "react";
import "../../../../table.css";
import { DataTable, DataTableColumn as Column, Pager } from "@barracuda/bds-core";
import { process } from "@progress/kendo-data-query";
import DeleteBillingMapCell from "./DeleteBillingMapCell";
import BillingMapPlanCell from "./BillingMapPlanCell";
import BillingMapLevelCell from "./BillingMapLevelCell";
import { useMediaQuery } from "@material-ui/core";
import { getButtonCount } from "../../../../utility";
import { IIntegrationBillingMap, IntegrationBllingMapType } from "../../../../models/Integrations/IntegrationsBillingMap";
import BillingMapFamilyCell from "./BillingMapFamilyCell";
import BillingMapBundleCell from "./BillingMapBundleCell";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store/store";
import { setIntegrationSetupBillingTablePageNumberAction, setIntegrationSetupBillingTablePageSizeAction } from "../../../../actions/integrations/integrationsBillingActions";
import { IFieldValidator } from "../../../Accounts/AddEditAccount/addressValidator";
import { setBillingSetupErrorMessage } from "../../../../Utilities/integrationsBillingHelper";

interface ISetupBillingTableProps {
  items: IIntegrationBillingMap[];
  availableOrderLineItems: IIntegrationBillingMap[];
  onDeleteItem: (item: IIntegrationBillingMap, index: number) => void;
  onChangeFamily: (currentMap: IIntegrationBillingMap, newFamily: IntegrationBllingMapType, index: number) => void;
  onChangeBundle: (currentMap: IIntegrationBillingMap, newBundle: string, index: number) => void;
  onChangePlan: (currentMap: IIntegrationBillingMap, newItem: string, index: number) => void;
  onChangeLevel: (currentMap: IIntegrationBillingMap, newLevel: string, index: number) => void;
  errors: IFieldValidator[];
}

const SetupBillingTable: React.FC<ISetupBillingTableProps> = ({ items, availableOrderLineItems, onDeleteItem, onChangeFamily, onChangeBundle, onChangePlan, onChangeLevel, errors }) => {
  const dispatch = useDispatch();
  const pageSize = useSelector((state: IAppState) => state.integrationsBillingMapState.setupBillingMapTablePageSize);
  const pageNumber = useSelector((state: IAppState) => state.integrationsBillingMapState.setupBillingMapTablePageNumber);
  const responsiveViewPortTriggerMin = useMediaQuery("(max-width: 1600px)");
  const [buttonCount, setButtonCount] = useState(10);
  const setupBillingTableTriggerMin = useMediaQuery("(max-width: 1255px)");
  const [deleteActionWidth, setDleteActionWidth] = useState<number | undefined>();
  const dataState = {
    skip: pageSize * (pageNumber - 1),
    take: pageSize,
    sort: [],
    group: [],
    collapsedGroups: [],
    selected: -1,
    lastSelectedIndex: 0,
    columns: [
      {
        title: "BILLING TYPE",
        field: "name",
        show: false,
        filter: "text",
      },
      {
        title: "UNIQUE ID/PLAN",
        field: "subname",
        show: false,
        filter: "text",
      },
      {
        title: "LEVEL",
        field: "level",
        show: false,
        filter: "text",
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(items, dataState as any),
  });

  useEffect(() => {
    if (setupBillingTableTriggerMin) {
      setDleteActionWidth(95);
    } else {
      setDleteActionWidth(undefined);
    }
  }, [setupBillingTableTriggerMin]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const dataStateChange = (e: any): void => {
    dispatch(setIntegrationSetupBillingTablePageSizeAction(e.dataState.take));
    dispatch(setIntegrationSetupBillingTablePageNumberAction(e.dataState.skip / e.dataState.take + 1));
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(items, e.dataState),
    });
  };

  const rowClick = (item: any): void => {
    setGridState({
      dataState: { ...gridState.dataState, selected: item.dataItem.id },
      dataResult: gridState.dataResult,
    });
  };

  useEffect(() => {
    setGridState({ dataState: gridState.dataState, dataResult: process(items, gridState.dataState as any) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    let newDs = { ...gridState.dataState, skip: pageSize * (pageNumber - 1) };
    setGridState({ dataState: newDs, dataResult: process(items, newDs as any) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, pageNumber, pageSize]);

  const onBillingMapBillingMapFamilyCellChanged = (currentMap: IIntegrationBillingMap, newFamily: IntegrationBllingMapType, index: number): void => {
    onChangeFamily(currentMap, newFamily, index);
  };

  const onBillingMapBillingMapBundleCellChanged = (currentMap: IIntegrationBillingMap, newBundle: string, index: number): void => {
    onChangeBundle(currentMap, newBundle, index);
  };

  const onBillingMapPlanChanged = (currentMap: IIntegrationBillingMap, newPlan: string, index: number): void => {
    onChangePlan(currentMap, newPlan, index);
  };

  const onBillingMapLevelChanged = (currentMap: any, newLevel: string, index: number): void => {
    onChangeLevel(currentMap, newLevel, index);
  };

  const onDeleteMapClick = (item: any, index: number): void => {
    onDeleteItem(item, index);
  };

  const BillingMapPlanFamiliesCellCommand = (props: any) => <BillingMapFamilyCell {...props} onChange={(newItem: IntegrationBllingMapType) => onBillingMapBillingMapFamilyCellChanged(props.dataItem, newItem, props.dataIndex)} availableOrderLineItems={availableOrderLineItems} errorMessage={setBillingSetupErrorMessage(errors, props.dataIndex, "family")} totalItems={items.length} />;
  const BillingMapPlanBundlesCellCommand = (props: any) => (props.dataItem.type === IntegrationBllingMapType.IBU_PLANS ? <td></td> : <BillingMapBundleCell {...props} onChange={(newBundle: string) => onBillingMapBillingMapBundleCellChanged(props.dataItem, newBundle, props.dataIndex)} availableOrderLineItems={availableOrderLineItems} errorMessage={setBillingSetupErrorMessage(errors, props.dataIndex, "bundle")} />);
  const BillingMapPlanOrderLinesCellCommand = (props: any) => <BillingMapPlanCell {...props} onChange={(newPlan: string) => onBillingMapPlanChanged(props.dataItem, newPlan, props.dataIndex)} availableOrderLineItems={availableOrderLineItems} errorMessage={setBillingSetupErrorMessage(errors, props.dataIndex, "plan")} />;
  const BillingMapLevelCellCommand = (props: any) => <BillingMapLevelCell {...props} disabled={props.dataItem.type === IntegrationBllingMapType.IBU_PLANS} onChange={(newLevel: string) => onBillingMapLevelChanged(props.dataItem, newLevel, props.dataIndex)} errorMessage={setBillingSetupErrorMessage(errors, props.dataIndex, "level")} />;
  const DeleteBillingMapCellCommand = (props: any) => <DeleteBillingMapCell {...props} onClick={() => onDeleteMapClick(props.dataItem, props.dataIndex)} disabled={props.dataItem.type === IntegrationBllingMapType.IBU_PLANS} />;

  return (
    <div style={{ position: "relative" }}>
      <DataTable
        className={"noScrollbar noBorders SetupBillingTable"}
        data={gridState.dataResult}
        resizable
        // page
        pageConfig={{
          pageable: {
            pageSizes: [10, 25, 50],
            buttonCount: buttonCount,
          },
          skip: gridState.dataState.skip,
          take: gridState.dataState.take,
          total: gridState.dataResult.total,
        }}
        groupable
        // sort
        sortConfig={{
          sortable: true,
          sort: dataState.skip,
        }}
        pager={gridState.dataResult.data.length > 0 && Pager}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        {...(gridState.dataState as any)}
        style={{ maxWidth: "100%" }}
        onRowClick={rowClick}
        {...(gridState.dataState as any)}
      >
        <Column key={"setupBillingPlan"} title={"TYPE"} cell={BillingMapPlanFamiliesCellCommand} width={200} sortable={false} resizable={false} />
        <Column key={"setupBillingPlan"} title={"PRODUCT/SERVICE"} cell={BillingMapPlanBundlesCellCommand} width={250} sortable={false} resizable={false} />
        <Column key={"setupBillingPlan"} title={"UNIQUE ID/PLAN"} cell={BillingMapPlanOrderLinesCellCommand} width={450} sortable={false} resizable={false} />
        <Column key={"setupBillingLevel"} title={"LEVEL"} field={"level"} cell={BillingMapLevelCellCommand} width={150} sortable={false} resizable={false} />
        <Column key={"setupBillingDelete"} title={""} cell={DeleteBillingMapCellCommand} sortable={false} width={deleteActionWidth} resizable={false} />
      </DataTable>
    </div>
  );
};

export default SetupBillingTable;
