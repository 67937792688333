export const NameValidationRule = {
  RegularExpression: /^([a-z0-9.']{1,}(\.|,|-){0,1}( ){0,1}){1,5}(jr(\.){0,1}|i{1,3}){0,1}$/i,
};

export const AccountCompanyNameValidationRule = {
  RegularExpressionMoreThanFiveWords: /^([0-9a-z'.\-_]{1,64}(,|\.|!){0,1}( |&| & ){0,1}){1,10}$/i,
  RegularExpressionUnderFiveWords: /^([0-9a-z'.\-_]{1,64}(,|\.|!){0,1}( |&| & ){0,1}){1,5}$/i,
};
export const CityValidationRule = {
  RegularExpression: /^([a-z]{1,24}(\.|,|'|-){0,1}( ){0,1}){1,5}$/i,
};

export const StateValidationRule = {
  RegularExpression: /^[a-z]{2,32}( [a-z]{3,32}){0,1}$/i,
};

export const PhoneValidationRule = {
  // eslint-disable-next-line no-useless-escape
  RegularExpression: /^([-\s\.\+\(\)]*[0-9]*[0-9]){10,16}$/,
  MinLength: 10,
  MaxLength: 16,
};

export const EmailValidationRule = {
  RegularExpression: /^([0-9a-z]([-_.\w]*[0-9a-z])*@(([0-9a-z])+([-0-9a-z]*[0-9a-z])*\.)+[a-z]{2,20})$/i,
  TildeRegExpression: /^.*\.[a-z]{2,20}$/i,
};

export const ZipValidationRule = {
  RegularExpression: /^((\d{5}-\d{4})|(\d{5})|([abceghjklmnprstvxy]\d[a-z]\s?\d[a-z]\d)|([a-z0-9]{2,4}\s?\d[a-z]{2})|(\d{6})|([a-z]{2}\d-\d{4})|(\d{4}))$/i,
  USRegularExpression: /^([0-9]{5})(-[0-9]{4})?$/i,
  CARegularExpression: /^[abceghjklmnprstvxy]\d[a-z]\s?\d[a-z]\d$/i,
  MinLength: 5,
  MaxLength: 10,
};

export const CardNumberValidationRules = {
  RegularExpression: /^[0-9]+$/,
  Visa: /^4/,
  MasterCard: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
  Amex: /^3[47]/,
  Discover: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
  Dinners: /^36/,
  DinersCarteBlanche: /^30[0-5]/,
  JCB: /^35(2[89]|[3-8][0-9])/,
  VisaElectron: /^(4026|417500|4508|4844|491(3|7))/,
};

export const CardCvvValidationRule = {
  RegularExpression: /^[0-9]{3,4}$/,
};

export const CardNameValidationRule = {
  RegularExpression: /^[a-zA-Z\-']+$/,
};

export const ConnectWiseURLValidationRule = {
  // eslint-disable-next-line no-useless-escape
  RegularExpression: /^(https?:\/\/)?([a-z0-9]([a-z0-9\-]{0,61}[a-z0-9])?\.)+[a-z]{2,6}$/,
};

export const CompanyNameValidationRule = {
  // eslint-disable-next-line no-useless-escape
  RegularExpression: /^([0-9a-z\'\.\-\_]{1,64}(,|\.|\!){0,1}( |&| & ){0,1}){1,10}$/i,
};

export const ValidCharactersValidationRule = {
  RegularExpression: /^[0-9a-zA-Z_ ]+$/,
};

export const LevelValidationRule = {
  RegularExpression: /^[a-z0-9_,\\. -]{1,32}$/i,
};
