import React from "react";
import * as CoreIcons from "@barracuda/bds-core/dist/Icons/Core";
import { Typography } from "@barracuda/bds-core";
import Grid from "@barracuda/bds-core/dist/Grid";
import Tooltip from "@barracuda/bds-core/dist/Tooltip";
import IntegrationsIcon from "./IntegrationsIcon";
import { getIntegrationStatus } from "../../Utilities/integrationsUtilities";
import IntegrationType, { getIntegrationNameByType } from "../../models/IntegrationType";
import TooltipElement from "../Users/TooltipElement";
import { EchoOnlyOption } from "../../Utilities/integrationsBillingHelper";

const IntegrationsCell = (props: any) => {
  let isAutoTask = props.dataItem.type === IntegrationType.Autotask;
  return (
    <td onClick={() => props.onClick(props)} className={"noIndentation " + (isAutoTask ? "cursorNotAllowed" : "")} style={{ padding: props.loading ? "9px 24px" : "5px 24px", opacity: isAutoTask ? 0.5 : 1 }}>
      <TooltipElement title={isAutoTask ? EchoOnlyOption : ""}>
        <Grid item container>
          {props.displayAccountIcon && (
            <Grid container item xs={1} style={{ justifyContent: "space-around", paddingRight: "11px", alignItems: "center" }}>
              <Tooltip title={""}>
                <div style={{ position: "relative" }}>
                  <IntegrationsIcon entityType={props.dataItem.type}></IntegrationsIcon>
                </div>
              </Tooltip>
            </Grid>
          )}
          <Grid container item xs={10}>
            <div>
              <Typography data-testId="integrationName" variant="body1" gutterBottom noWrap className={"noIndentation centerVertically"}>
                {getIntegrationNameByType(props.dataItem.type)}
              </Typography>
              {!props.loading && <div style={{ fontWeight: "lighter", fontSize: "10px", paddingBottom: "4px", lineHeight: "10px" }}>{getIntegrationStatus(props.dataItem.status, props.dataItem.type)}</div>}
            </div>
          </Grid>
          <Grid container item xs={1} style={{ justifyContent: "flex-end" }}>
            <CoreIcons.PageNext className="centerVertically" />
          </Grid>
        </Grid>
      </TooltipElement>
    </td>
  );
};

export default IntegrationsCell;
