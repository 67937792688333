import { DialogTitle, DialogActions, Button, DialogContent, Typography, Dialog } from "@barracuda/bds-core";
import React from "react";

interface IDeleteCreditCardProps {
  onCancel: () => void;
  onDeleteCard: () => void;
  showDialog: boolean;
  isActionInProgress: boolean;
}

const DeleteCreditCard: React.FC<IDeleteCreditCardProps> = ({ showDialog, onCancel, onDeleteCard, isActionInProgress }) => {
  return (
    <Dialog className={"DeleteCreditCardDialog"} data-testid="deleteCreditCardDialog" open={showDialog} onClose={onCancel} disableBackdropClick={true} maxWidth={false} style={{ zIndex: 4002 }}>
      <div style={{ width: "400px" }}>
        <DialogTitle data-testid="deleteCreditCardDialogTitle" id="alert-dialog-title">
          Delete Card
        </DialogTitle>
        <DialogContent>
          <Typography data-testid="deleteCreditCardMessage" variant="body1">
            Are you sure you want to delete this card?
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <Button data-testid="deleteCreditCardCancelButton" color="secondary" disabled={isActionInProgress} type={"submit"} onClick={onCancel}>
            CANCEL
          </Button>
          <Button data-testid="deleteCreditCardConfirmButton" type={"submit"} isLoading={isActionInProgress} disabled={isActionInProgress} onClick={onDeleteCard}>
            CONFIRM
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeleteCreditCard;
