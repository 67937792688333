import produce from "immer";
import { IDetailsTabs } from "../components/InfoPanel";
import DetailsTabs from "../models/DetailsTabs";
import IAccount from "../models/IAccount";
import IAccountSlim from "../models/IAccountSlim";
import MspType from "../models/MspType";
import ISerial from "../models/Products/ISerial";
import UserRole from "../models/UserRole";
import { eliminateDuplicates, dynamicSort, userHasRole } from "../utility";
import IFilterCheckbox from "../models/IFilterCheckbox";
import { IAccountFilters } from "../models/IAccountFilters";

export const searchByAccountName = (accounts: IAccount[], value: string): IAccount[] => {
  const allCustomers = getAllAccountsAndCustomers(accounts);
  return allCustomers.filter(x => x.name.toLowerCase() === value.toLowerCase());
};

export const filterByAccountName = (accounts: IAccount[], slimAccounts: IAccountSlim[] | undefined, value: string): any[] => {
  const allCustomers = getAllAccountsAndCustomers(accounts);
  if (slimAccounts) {
    let allCustomersNames = allCustomers.map(x => ({ name: x.name, id: x.id, type: x.type, partnerId: x.partnerId })) as IAccountSlim[];
    allCustomersNames.push(...slimAccounts);
    const uniqueItems = eliminateDuplicates(allCustomersNames, "id");
    return uniqueItems.filter((x: { name: string }) => x.name.toLowerCase().includes(value.toLowerCase()));
  } else {
    return allCustomers.filter(x => x.name.toLowerCase().includes(value.toLowerCase()));
  }
};

export const filterSmbByName = (accounts: any[], value: string): any[] => {
  return accounts.filter((x: any) => x.name.toLowerCase().includes(value.toLowerCase()));
};

export function searchAccount(mspAccountLoggedIn: IAccount, mspAccounts: IAccount[], customerName: string) {
  let filterableAccounts: IAccount[] = [];
  if (accountIsBillingAggregator(mspAccountLoggedIn)) {
    filterableAccounts.push(mspAccountLoggedIn);
  } else {
    filterableAccounts.push(...mspAccounts);
  }
  const result = filterByAccountName(filterableAccounts, [], customerName);
  result.sort(dynamicSort("name"));
  return result;
}

export const searchByAccountId = (accounts: IAccount[], value: number): IAccount => {
  const allCustomers = getAllAccountsAndCustomers(accounts);
  return allCustomers.filter(x => x.id.toString() === value.toString())[0];
};

export const findAccountById = (accounts: IAccount[], slimAccounts: IAccountSlim[] | undefined, id: number): IAccount | IAccountSlim | undefined => {
  const allCustomers = getAllAccountsAndCustomers(accounts);
  let index = allCustomers.findIndex((x: IAccount) => x.id.toString() === id.toString());
  if (index > -1) {
    return allCustomers[index];
  }
  if (slimAccounts) {
    index = slimAccounts.findIndex((x: IAccountSlim) => x.id.toString() === id.toString());
    if (index > -1) {
      return slimAccounts[index];
    }
  }
  return undefined;
};

const getAllAccountsAndCustomers = (mspAccounts: IAccount[]): IAccount[] => {
  let allAccounts: IAccount[] = [];
  mspAccounts.forEach(msp => {
    allAccounts.push(msp);
    msp.accounts?.forEach(customer => {
      allAccounts.push(customer);
    });
  });
  return allAccounts;
};

export const showUnassignButton = (selectedAccount: IAccount | undefined, mspAccountLoggedIn: IAccount) => {
  return selectedAccount?.type === MspType.Subpartner && mspAccountLoggedIn.type === MspType.Partner;
};

export const getCustomerSubpartnerText = (selectedAccount: IAccount | undefined, customerText: string, subpartnerText: string) => {
  return selectedAccount?.type === MspType.Customer ? customerText : subpartnerText;
};

export const getAccountParent = (mspAccounts: IAccount[], accountId: number): IAccount | IAccountSlim => {
  let currentAccount = searchByAccountId(mspAccounts, accountId);
  if (currentAccount) {
    if (currentAccount.type === MspType.Partner) {
      throw new Error(`No parent account for partner with id ${accountId}`);
    }
    let parentAccount = searchByAccountId(mspAccounts, currentAccount.partnerId);
    if (parentAccount) {
      return parentAccount;
    } else {
      throw new Error(`No parent account for account with id ${accountId}`);
    }
  } else {
    throw new Error(`No account with id ${accountId}`);
  }
};

export function shouldUseIndent(viewSearchResults: boolean, mspAccountLoggedIn: IAccount, displayedAccount: IAccount, isViewingMsp: boolean): boolean {
  if (viewSearchResults) {
    return false;
  }
  if (mspAccountLoggedIn.type === MspType.Partner) {
    return shouldUseIndentAccountWhenLoggedInAsPartner(displayedAccount, isViewingMsp);
  } else if (mspAccountLoggedIn.type === MspType.Subpartner) {
    return shouldUseIndentAccountWhenLoggedInAsSubPartner(displayedAccount);
  } else if (mspAccountLoggedIn.type === MspType.BillingAggregator) {
    return shouldUseIndentAccountWhenLoggedInAsBa(displayedAccount);
  } else {
    return false;
  }
}

export function getParentAccountDetailsFromSerials(mspAccounts: IAccount[], serial: ISerial | undefined): { parentName: string; parentType: string } {
  let parentType: string = "";
  let parentName: string = "";
  if (serial) {
    try {
      const parentAcc = getAccountParent(mspAccounts, serial.accountId);
      parentType = parentAcc.type;
      parentName = parentAcc.name;
    } catch {
      //parent not found, so empty result
    }
  }
  return { parentType, parentName };
}

export function setEditDialogTitle(account: IAccount): string {
  if (account.type === MspType.Partner) {
    return "EDIT PARTNER";
  } else if (account.type === MspType.BillingAggregator) {
    return "EDIT BILLING AGGREGATOR";
  } else {
    return "EDIT " + getCustomerSubpartnerText(account, "ACCOUNT", "SUBPARTNER");
  }
}

export function getAccountTypeAsTitle(type: string | undefined): string {
  return getAccountDisplayType(type).toUpperCase();
}

export function getAccountDisplayType(type: string | undefined): string {
  if (type) {
    switch (type) {
      case MspType.Partner:
        return "Partner";
      case MspType.Subpartner:
        return "Subpartner";
      case MspType.BillingAggregator:
        return "Billing Aggregator";
      default:
        return "Account";
    }
  }
  return "";
}

export function getAccountTypeFromValue(value: string): string {
  if (value.toLowerCase().includes("subpartner")) {
    return "SUBPARTNER";
  } else if (value.toLowerCase().includes("partner")) {
    return "PARTNER";
  } else if (value.toLowerCase().includes("aggregator")) {
    return "BILLING AGGREGATOR";
  } else {
    return "ACCOUNT";
  }
}
export function tabsToBeFiltered(initialTabs: IDetailsTabs[], removedTabs: DetailsTabs[]): IDetailsTabs[] {
  removedTabs.forEach(element => {
    initialTabs = initialTabs.filter(x => {
      return x.tab.label !== element;
    });
  });
  return initialTabs;
}

export function tabsForBillingIntegrator(tabs: IDetailsTabs[], mspAccountLoggedIn: IAccount, selectedAccount: IAccount | undefined): IDetailsTabs[] {
  if (selectedAccount?.type === MspType.BillingAggregator) {
    if (userHasRole(UserRole.BillingAggregatorAdmin, mspAccountLoggedIn)) {
      return tabs;
    } else if (userHasRole(UserRole.BillingAggregatorFinance, mspAccountLoggedIn)) {
      return tabsToBeFiltered(tabs, [DetailsTabs.Users]);
    } else {
      return tabsToBeFiltered(tabs, [DetailsTabs.Products, DetailsTabs.Users, DetailsTabs.Finance]);
    }
  } else if (selectedAccount?.type === MspType.Partner) {
    return tabsToBeFiltered(tabs, [DetailsTabs.Users, DetailsTabs.Finance]);
  }
  return [];
}

export function filterTabs(tabs: IDetailsTabs[], mspAccountLoggedIn: IAccount, selectedAccount: IAccount | undefined): IDetailsTabs[] {
  if (mspAccountLoggedIn.type === MspType.BillingAggregator) {
    return tabsForBillingIntegrator(tabs, mspAccountLoggedIn, selectedAccount);
  } else {
    if (!userHasRole(UserRole.Admin, mspAccountLoggedIn)) {
      let filteredTabs = tabsToBeFiltered(tabs, [DetailsTabs.Users]);
      if (!userHasRole(UserRole.Finance, mspAccountLoggedIn) || !selectedAccount?.type.includes(MspType.Partner)) {
        filteredTabs = tabsToBeFiltered(filteredTabs, [DetailsTabs.Finance]);
      }
      return filteredTabs;
    } else if (userHasRole(UserRole.Admin, mspAccountLoggedIn) && !selectedAccount?.type.includes(MspType.Partner)) {
      return tabsToBeFiltered(tabs, [DetailsTabs.Finance]);
    } else {
      return tabs;
    }
  }
}

export function accountIsPartner(account: IAccount | undefined): boolean {
  return account?.type === MspType.Partner;
}

export function accountIsSubPartner(account: IAccount | undefined): boolean {
  return account?.type === MspType.Subpartner;
}

export function accountIsCustomer(account: IAccount | undefined): boolean {
  return account?.type === MspType.Customer;
}

function shouldUseIndentAccountWhenLoggedInAsPartner(displayedAccount: IAccount, isViewingMsp: boolean): boolean {
  return displayedAccount.type === MspType.Customer || (displayedAccount.type === MspType.Subpartner && isViewingMsp);
}

function shouldUseIndentAccountWhenLoggedInAsSubPartner(displayedAccount: IAccount): boolean {
  return displayedAccount.type !== MspType.Subpartner;
}

function shouldUseIndentAccountWhenLoggedInAsBa(displayedAccount: IAccount): boolean {
  return displayedAccount.type !== MspType.BillingAggregator;
}

export function accountIsBillingAggregator(account: IAccount | undefined): boolean {
  return account?.type === MspType.BillingAggregator;
}

export function isBillingInfoAvailable(mspLogin: IAccount, account: IAccount): boolean {
  if (account?.type === MspType.Partner && mspLogin.type === MspType.Partner) {
    return true;
  } else if (account?.type === MspType.BillingAggregator && mspLogin.type === MspType.BillingAggregator) {
    return true;
  } else {
    return false;
  }
}

export function isBillingInfoOnly(mspLogin: IAccount, account: IAccount): boolean {
  return mspLogin.type === MspType.BillingAggregator && account.type === MspType.BillingAggregator;
}

export function shouldDisplayEditContactButton(mspLogin: IAccount, account: IAccount): boolean {
  if (mspLogin.type === MspType.BillingAggregator) {
    return account.type === MspType.BillingAggregator;
  }
  return true;
}

export function computePartnerWithChildren(allAccountsWithChildren: any, partnerAccount: IAccount) {
  const customers = getSortedAccountsByType(allAccountsWithChildren, MspType.Customer, partnerAccount);
  const subpartners: IAccount[] = getSortedAccountsByType(allAccountsWithChildren, MspType.Subpartner, partnerAccount);
  const mspAccounts = [{ ...partnerAccount, accounts: [...customers] }, ...subpartners];
  return { mspAccounts, subpartners, customers };
}

function getSortedAccountsByType(accounts: any, type: MspType, partnerAccount: IAccount) {
  const items = accounts.accounts.filter((x: IAccount) => x.type === type);
  items.sort(dynamicSort("name"));
  return addParentIdToAccount(items, partnerAccount.id);
}

export function addChildrenToSubpartner(currentSubpartners: any[], accounts: any[], partnerAccount: IAccount) {
  const nextStateAcc = getSortedAccountsByType(accounts, MspType.Customer, partnerAccount);

  const index = currentSubpartners.findIndex((x: any) => x.id === partnerAccount.id);
  const nextStateSubpartners = produce(currentSubpartners, (draft: any[]) => {
    draft[index].accounts = [...nextStateAcc];
  });
  return nextStateSubpartners;
}

function addParentIdToAccount(accounts: any[], partnerId: number): any[] {
  return accounts.map((x: any) => ({ ...x, partnerId }));
}

export function canViewBillingTab(mspAccountLoggedIn: IAccount, selectedAccount: IAccount): boolean {
  return (mspAccountLoggedIn.type === MspType.Partner && selectedAccount.type === MspType.Partner) || (mspAccountLoggedIn.type === MspType.BillingAggregator && selectedAccount.type === MspType.BillingAggregator);
}

export function getNumberOfAccountsForMsp(mspAccounts: IAccount[], selectedAccount: IAccount): number {
  if (selectedAccount.type === MspType.Customer) {
    return 0;
  }
  if (selectedAccount.type === MspType.Subpartner || selectedAccount.type === MspType.Partner) {
    try {
      const foundAccount = searchByAccountId(mspAccounts, selectedAccount.id);
      if (foundAccount) {
        return foundAccount.accounts.length;
      }
    } catch (ex) {
      console.log(`Searching for an inexistent account with id ${selectedAccount.id}`);
    }
  }
  return 0;
}

export function getStateLable(country: string, isDialog: boolean): string {
  if (isUK(country)) {
    return "County";
  } else if (country === "United States") {
    return "State";
  } else if (country === "Canada") {
    return "Province";
  } else {
    return isDialog ? "STATE" : "State / Province";
  }
}

export function isUK(countryName: string): boolean {
  return countryName === "United Kingdom";
}

export function handleM356Auth(success: boolean, successLink: any, entity: any, handleAddEditAccount: any): void {
  if (successLink && successLink.isSuccessful && successLink.authorizeUrl) {
    let popupWindow = window.open(successLink.authorizeUrl, "", "");
    if (popupWindow !== null) {
      const intervalId = setInterval(function () {
        if (popupWindow?.closed) {
          clearInterval(intervalId);
          handleAddEditAccount(success, entity);
          return;
        }
        let href = "";
        try {
          if (popupWindow?.location.href) {
            href = popupWindow?.location.href;
          }
        } catch (e) {}

        if (href === "" || href === "about:blank") {
          return;
        }
        const url = new URL(href);
        const curUrl = new URL(window.location.href);
        if (url.hostname === curUrl.hostname) {
          popupWindow?.close();
          handleAddEditAccount(success, entity);
        }
        clearInterval(intervalId);
      }, 50);
    }
  } else {
    handleAddEditAccount(success, entity);
  }
}

export function uncheckDeletedFilterChip(options: IFilterCheckbox[], label: string): IFilterCheckbox[] {
  return options.map((option: IFilterCheckbox) => {
    if (option.label === label) {
      return { ...option, checked: !option.checked };
    } else {
      return option;
    }
  });
}

export function getShowResourcesButton(loggedInAcc: IAccount, selectedAcc: IAccount | undefined): boolean {
  if (selectedAcc) {
    if ((loggedInAcc.type === MspType.Partner || loggedInAcc.type === MspType.Subpartner) && loggedInAcc.type === selectedAcc.type) {
      return true;
    }
  }
  return false;
}

export function computeItemsToFilter(loggedInAcc: IAccount, accNames: IAccountSlim[], mspAccounts: IAccount[], filterChildrenOfAccountId: number | undefined, directChildrenOnly: boolean | undefined): IAccountSlim[] | IAccount[] {
  if (filterChildrenOfAccountId !== undefined) {
    if (filterChildrenOfAccountId === loggedInAcc.id && directChildrenOnly === false) {
      return accNames.filter(x => x.type === MspType.Customer);
    }
    const index = mspAccounts.findIndex(x => x.id === filterChildrenOfAccountId);
    if (index > -1) {
      return mspAccounts[index].accounts.filter(x => x.type === MspType.Customer);
    }
    return [];
  }
  return [];
}

function hasExtraFiltersPresent(filters: IAccountFilters | undefined): boolean {
  if (filters !== undefined && (filters.allProducts !== undefined || filters.associatedLogin !== undefined || filters.hasErrors !== undefined || filters.hasIbu !== undefined || filters.hasSmbLogins !== undefined || filters.noProducts !== undefined || filters.onlyM365Linked !== undefined || filters.onlyM365Unlinked !== undefined || (filters.essSkus !== undefined && filters.essSkus.length > 0) || (filters.csProdSkus !== undefined && filters.csProdSkus.length > 0) || (filters.bbsProdSkus !== undefined && filters.bbsProdSkus.length > 0))) {
    return true;
  }
  return false;
}

export function getFilterMethod(filters: IAccountFilters | undefined) {
  let filterByName = false;
  let filterByExtraParams = false;

  const extraFiltersPresent = hasExtraFiltersPresent(filters);
  if (extraFiltersPresent) {
    filterByExtraParams = true;
    if (filters?.name !== undefined && filters.name.length > 0) {
      filterByName = true;
    }
  } else {
    if (filters?.name !== undefined && filters.name.length > 0) {
      filterByName = true;
    }
  }

  return { filterByName, filterByExtraParams };
}

export function areFiltersActive(filters: IAccountFilters | undefined): boolean {
  const { filterByName, filterByExtraParams } = getFilterMethod(filters);
  return filterByName === true || filterByExtraParams === true;
}

export function getM365Filters(filters: IAccountFilters): boolean | undefined {
  let result: boolean | undefined = undefined;
  if (filters.onlyM365Linked !== undefined && filters.onlyM365Linked === true) {
    result = true;
  } else if (filters.onlyM365Unlinked !== undefined && filters.onlyM365Unlinked === true) {
    result = false;
  }
  return result;
}

export function getEssBundleSkusFilters(filters: IAccountFilters): string | undefined {
  let result: string | undefined = undefined;
  if (filters.essSkus !== undefined && filters.essSkus.length > 0) {
    filters.essSkus.forEach(element => {
      if (result === undefined) {
        result = element.sku;
      } else {
        result += "," + element.sku;
      }
    });
  }
  return result;
}

export function filterResultsByType(results: IAccountSlim[], mspAccountLoggedIn: IAccount): IAccountSlim[] {
  if (mspAccountLoggedIn.type === MspType.BillingAggregator) {
    return results.filter(x => x.type === MspType.BillingAggregator || x.type === MspType.Partner);
  }
  return results.filter(x => x.type === MspType.Customer);
}

export function addUpdateAccountToAccountNamesList(currentAcountNames: IAccountSlim[], newAccount: IAccountSlim): IAccountSlim[] {
  let nextStateAccountsNames: IAccountSlim[] = [];
  const accountIndex = currentAcountNames.findIndex((x: IAccountSlim) => x.id.toString() === newAccount.id.toString());
  if (accountIndex < 0) {
    nextStateAccountsNames = produce(currentAcountNames, draft => {
      draft.push(newAccount);
    });
  } else {
    nextStateAccountsNames = produce(currentAcountNames, draft => {
      draft[accountIndex].name = newAccount.name;
    });
  }
  return nextStateAccountsNames;
}

export function removeAccountFromAccountNamesList(currentAcountNames: IAccountSlim[], accountId: number): IAccountSlim[] {
  let nextStateAccountsNames: IAccountSlim[] = [];
  const accountIndex = currentAcountNames.findIndex((x: IAccountSlim) => x.id.toString() === accountId.toString());
  if (accountIndex > -1) {
    nextStateAccountsNames = produce(currentAcountNames, draft => {
      draft.splice(accountIndex, 1);
    });
  }
  return nextStateAccountsNames;
}
