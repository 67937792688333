import IAccount from "../../../../models/IAccount";
import MspType from "../../../../models/MspType";
import IAccountSlim from "../../../../models/IAccountSlim";
import IProduct from "../../../../models/Products/IProduct";
import SerialStatus from "../../../../models/Products/SerialStatus";
import { findAccountById } from "../../../../Utilities/accountsHelper";
import { serialHasRmaStatus } from "../../../products";

export function isEditBBSDisabled(product: IProduct, allMspAccounts: IAccount[], accountNames: IAccountSlim[]): boolean {
  if (serialHasRmaStatus(product.serials[0])) {
    return true;
  } else if (isBBSNotAssignedOrActivated(product)) {
    return false;
  } else {
    const accountId = getAccountIdForBBSSerial(product);
    if (findAccountById(allMspAccounts, accountNames, accountId) === undefined) {
      return true;
    }
  }
  return false;
}

export function getEditBBSSerialTitle(product: IProduct, allMspAccounts: IAccount[], accountNames: IAccountSlim[]): string {
  if (serialHasRmaStatus(product.serials[0])) {
    return "This appliance requires a manual data wipe to restore it to an 'Available' state";
  } else if (isBBSNotAssignedOrActivated(product)) {
    return "";
  } else {
    const accountId = getAccountIdForBBSSerial(product);
    if (findAccountById(allMspAccounts, accountNames, accountId) === undefined) {
      return `Account ID ${accountId} does not have MSP type`;
    }
  }
  return "";
}

export function getAccountIdForBBSSerial(product: IProduct): number {
  if (product.serials[0].accountId && product.serials[0].accountId.toString() !== "0") {
    return product.serials[0].accountId;
  } else if (product.subPartnerId && product.subPartnerId !== null && product.subPartnerId !== 0) {
    return product.subPartnerId;
  }
  return 0;
}
export function isBBSNotAssignedOrActivated(product: IProduct): boolean {
  return product.serials[0].status === SerialStatus.AVAILABLE && product.serials[0].accountId.toString() === "0" && (product.subPartnerId === null || product.subPartnerId === 0);
}

export function getBbsProductTableColumnsSettings(isBaLoggedIn: boolean, selectedAccount: IAccount | undefined, selectedAccountToFilterProductsForBA: IAccount | undefined) {
  let showEditCol = true;
  let showPriceCol = false;
  let showAccountCol = true;
  if (isBaLoggedIn) {
    showEditCol = false;
    if (selectedAccountToFilterProductsForBA && selectedAccountToFilterProductsForBA.type !== MspType.Partner) {
      if (selectedAccountToFilterProductsForBA.type === MspType.Customer) {
        showAccountCol = false;
      }
    } else {
      showPriceCol = true;
    }
  } else {
    if (selectedAccount) {
      if (selectedAccount.type === MspType.Customer) {
        showAccountCol = false;
      }
    }
  }
  return { showEditCol, showPriceCol, showAccountCol };
}
