import React, { useState } from "react";
import TooltipElement from "../../../Users/TooltipElement";
import { TextField } from "@barracuda/bds-core";
import { setBillingSetupDisableTooltipMessage } from "../../../../Utilities/integrationsBillingHelper";
import { IntegrationBllingMapType } from "../../../../models/Integrations/IntegrationsBillingMap";
import { isStringNullOrEmpty } from "../../../../utility";

const BillingMapLevelCell = (props: any) => {
  const [level, setLevel] = useState(props.dataItem.level);
  const [errorMessage, setErrorMessage] = useState(props.errorMessage);
  if (props.rowType === "groupHeader") {
    return null;
  }

  const handleOnLevelChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setLevel(ev.target.value);
    if (!isStringNullOrEmpty(props.errorMessage)) {
      setErrorMessage("");
    }
  };

  const handleOnLevelBlur = (ev: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(ev.target.value);
  };

  return (
    <td>
      <div className={props.disabled ? "cursorNotAllowed" : ""}>
        <TooltipElement title={setBillingSetupDisableTooltipMessage(props.title, props.dataItem.type)}>
          <TextField
            data-testid="billingMappingLevel"
            size={"small"}
            value={level}
            error={errorMessage.length > 0}
            disabled={props.dataItem.type === undefined || props.dataItem.name === "" || props.dataItem.subname === "" || props.dataItem.type === IntegrationBllingMapType.IBU_PLANS}
            required
            helperText={errorMessage}
            onChange={handleOnLevelChange}
            onBlur={handleOnLevelBlur}
            inputProps={{
              maxLength: 32,
            }}
          />
        </TooltipElement>
      </div>
    </td>
  );
};

export default BillingMapLevelCell;
