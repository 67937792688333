import React from "react";
import CheckM365Status from "./CheckM356Status";
import { Typography } from "@barracuda/bds-core";

const CheckM365StatusButton = (props: any) => {
  return (
    <div style={{ display: "inline-flex", height: 48 }} className="CheckM365Status">
      <Typography variant="subtitle2" className={"centerVertically"}>
        M365:
      </Typography>
      <CheckM365Status accountId={props.accountId} />
    </div>
  );
};

export default CheckM365StatusButton;
