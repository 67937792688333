import React from "react";
import Grid from "@barracuda/bds-core/dist/Grid";
import Tooltip from "@barracuda/bds-core/dist/Tooltip";
import { Link } from "@barracuda/bds-core";

const ProductLicense = (props: any) => {
  return (
    <td>
      <Tooltip title={props.dataItem.productLicense}>
        <Grid item container>
          <Grid container item xs={11} className="AuditUserProductColumn">
            <div className={(props.dataItem.isExcluded ? "grayedOut" : "none") + " textNoWrap"}>{props.dataItem.productLicense}</div>
            {props.dataItem.productLicenseCount > 1 && (
              <div className="AuditUserProductLink">
                <Link href="#" onClick={() => props.onLinkClick(props.dataItem)}>
                  ({props.dataItem.productLicenseCount - 1} more...)
                </Link>
              </div>
            )}
          </Grid>
        </Grid>
      </Tooltip>
    </td>
  );
};

export default ProductLicense;
