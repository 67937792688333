import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import IAccount from "../models/IAccount";
import mspService from "../service/mspService";
import { IAppState } from "../store/store";
import { handleError } from "./actionsErrorHandler";
import IAuditUser from "../models/Products/IAuditUser";
import { State } from "@progress/kendo-data-query";
import { CancelTokenSource } from "axios";
import { chunk, isStringNullOrEmpty, setAuditUserDisabled, setExcludedAuditUser } from "../utility";
import IAuditUserDisplayState from "../models/Products/IAuditUserDisplayState";
import { foundAuditUser, searchAuditUser } from "../Utilities/auditUsersHelper";
import IAuditUserImportAction from "../models/Products/IAuditUserImportAction";
import produce from "immer";
import { CHUNK_SIZE_BILLING_USERS_TO_IMPORT } from "../config";
import { cancelLoadAuditUsersActionTokenAndCreateNew } from "./cancelAction";

export enum AuditUsersActionsTypes {
  GET_AUDIT_USERS = "GET_AUDIT_USERS",
  GET_AUDIT_USERS_TABLE_PROPS = "GET_AUDIT_USERS_TABLE_PROPS",
  SET_CANCEL_LOAD_AUDIT_USERS_TOKEN = "SET_CANCEL_LOAD_AUDIT_USERS_TOKEN",
  SAVE_AUDIT_DISPLAY_STATE = "SAVE_AUDIT_DISPLAY_STATE",
  SET_LOADING_AUDIT_USERS = "SET_LOADING_AUDIT_USERS",
  SET_SEARCHED_AUDIT_USER_EMAIL = "SET_SEARCHED_AUDIT_USER_EMAIL",
  SET_AUDIT_DISPLAY_ITEMS = "SET_AUDIT_DISPLAY_ITEMS",
  SET_NO_OF_ACTIONS_IN_PROGRESS = "SET_NO_OF_ACTIONS_IN_PROGRESS",
  ADD_IMPORT_AUDIT_USERS_EXCLUSION_FILE = "ADD_IMPORT_AUDIT_USERS_EXCLUSION_FILE",
  REMOVE_FINISHED_IMPORT_AUDIT_USERS_EXCLUSION_FILE = "REMOVE_FINISHED_IMPORT_AUDIT_USERS_EXCLUSION_FILE",
}

export interface IGetAuditUsersAction {
  type: AuditUsersActionsTypes.GET_AUDIT_USERS;
  auditUsersToDisplay: IAuditUser[];
  loadingAuditUsers: boolean;
  nextPageToken: string;
  apiSearchedAuditUserEmail: string;
}

export interface ISetLoadingAuditUsers {
  type: AuditUsersActionsTypes.SET_LOADING_AUDIT_USERS;
  loadingAuditUsers: boolean;
}

export interface ISetSearchedAuditUserEmail {
  type: AuditUsersActionsTypes.SET_SEARCHED_AUDIT_USER_EMAIL;
  searchedAuditUserEmail: string;
}

export interface ISetAuditUsersTableProps {
  type: AuditUsersActionsTypes.GET_AUDIT_USERS_TABLE_PROPS;
  tableState: State;
}

export interface ISetCancelLoadAuditUsersToken {
  type: AuditUsersActionsTypes.SET_CANCEL_LOAD_AUDIT_USERS_TOKEN;
  loadAuditUsersCancellationTokenSource: CancelTokenSource;
}

export interface ISetAuditDisplayItems {
  type: AuditUsersActionsTypes.SET_AUDIT_DISPLAY_ITEMS;
  auditUsersToDisplay: IAuditUser[];
}

export interface ISaveAuditStateBeforeSearch {
  type: AuditUsersActionsTypes.SAVE_AUDIT_DISPLAY_STATE;
  auditUserStateBeforeSearch: IAuditUserDisplayState | undefined;
}

export interface ISetNoOfActionsInProgress {
  type: AuditUsersActionsTypes.SET_NO_OF_ACTIONS_IN_PROGRESS;
  noOfActionsInProgress: number;
}

export interface IAddImportAuditUsersExclusionFileAction {
  type: AuditUsersActionsTypes.ADD_IMPORT_AUDIT_USERS_EXCLUSION_FILE;
  importActions: IAuditUserImportAction[];
}

export interface IRemoveFinishedImportAuditUsersExclusionFileAction {
  type: AuditUsersActionsTypes.REMOVE_FINISHED_IMPORT_AUDIT_USERS_EXCLUSION_FILE;
  importActions: IAuditUserImportAction[];
}

export type AuditUsersActions = IGetAuditUsersAction | ISetAuditUsersTableProps | ISetCancelLoadAuditUsersToken | ISaveAuditStateBeforeSearch | ISetLoadingAuditUsers | ISetSearchedAuditUserEmail | ISetAuditDisplayItems | ISetNoOfActionsInProgress | IAddImportAuditUsersExclusionFileAction | IRemoveFinishedImportAuditUsersExclusionFileAction;

export const getBilledUsersAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetAuditUsersAction>> = (account: IAccount, beginWith: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const { auditUsersTableState } = getState().auditUsersState;
      if (isStringNullOrEmpty(beginWith)) {
        dispatch({
          type: AuditUsersActionsTypes.GET_AUDIT_USERS,
          auditUsersToDisplay: [],
          loadingAuditUsers: true,
          nextPageToken: "",
          apiSearchedAuditUserEmail: "",
        });
      } else {
        dispatch({
          type: AuditUsersActionsTypes.SET_LOADING_AUDIT_USERS,
          loadingAuditUsers: true,
        });
      }

      const newCancelTokenSource = cancelLoadAuditUsersActionTokenAndCreateNew(getState, dispatch);

      let result = await mspService.loadUsageAudit(apiUrl, account.id, beginWith, newCancelTokenSource.token);

      if (isStringNullOrEmpty(beginWith)) {
        dispatch({
          type: AuditUsersActionsTypes.SAVE_AUDIT_DISPLAY_STATE,
          auditUserStateBeforeSearch: {
            auditUsersToDisplay: result.auditUsers,
            auditUsersTableState,
            nextPageToken: result.nextPageToken,
          },
        });
      } else {
        const { auditUsersToDisplay } = getState().auditUsersState;
        const { nextPageToken } = getState().auditUsersState;
        const { auditUserStateBeforeSearch } = getState().auditUsersState;

        if (foundAuditUser(auditUsersToDisplay, beginWith, nextPageToken) && auditUserStateBeforeSearch === undefined) {
          dispatch({
            type: AuditUsersActionsTypes.SAVE_AUDIT_DISPLAY_STATE,
            auditUserStateBeforeSearch: { auditUsersToDisplay, auditUsersTableState, nextPageToken },
          });
        }
      }
      dispatch({
        type: AuditUsersActionsTypes.GET_AUDIT_USERS,
        auditUsersToDisplay: result.auditUsers,
        loadingAuditUsers: false,
        nextPageToken: result.nextPageToken,
        apiSearchedAuditUserEmail: beginWith,
      });
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: AuditUsersActionsTypes.GET_AUDIT_USERS,
          auditUsersToDisplay: [],
          loadingAuditUsers: false,
          nextPageToken: "",
          apiSearchedAuditUserEmail: "",
        });
      });
      return false;
    }
  };
};

export const setAuditUsersTableProps: ActionCreator<ThunkAction<any, IAppState, null, ISetAuditUsersTableProps>> = (tableState: State) => (dispatch: Dispatch) => dispatch({ type: AuditUsersActionsTypes.GET_AUDIT_USERS_TABLE_PROPS, tableState });

export const searchAuditUserAction: ActionCreator<ThunkAction<any, IAppState, null, ISetAuditDisplayItems>> = (auditUserEmail: string) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { auditUserStateBeforeSearch } = getState().auditUsersState;
    const result = searchAuditUser(auditUserEmail, auditUserStateBeforeSearch);
    dispatch({
      type: AuditUsersActionsTypes.SET_AUDIT_DISPLAY_ITEMS,
      auditUsersToDisplay: result,
    });
    dispatch({
      type: AuditUsersActionsTypes.GET_AUDIT_USERS_TABLE_PROPS,
      tableState: { sort: [{ field: "user", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
    });
  };
};

export const addAccountExclusionAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetAuditDisplayItems>> = (account: IAccount, newAuditUserEmails: string[]) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: AuditUsersActionsTypes.SET_NO_OF_ACTIONS_IN_PROGRESS,
        noOfActionsInProgress: getState().auditUsersState.noOfActionsInProgress + 1,
      });
      const initialAuditUserState = getState().auditUsersState.auditUsersToDisplay;
      const resultAfterDisable = setAuditUserDisabled(initialAuditUserState, newAuditUserEmails[0], true);
      dispatch({
        type: AuditUsersActionsTypes.SET_AUDIT_DISPLAY_ITEMS,
        auditUsersToDisplay: resultAfterDisable,
      });
      await mspService.addBilledUser(apiUrl, account.id, newAuditUserEmails);
      const auditUsersToDisplay = getState().auditUsersState.auditUsersToDisplay;
      const finalResult = setExcludedAuditUser(auditUsersToDisplay, newAuditUserEmails[0], true);
      dispatch({
        type: AuditUsersActionsTypes.SET_AUDIT_DISPLAY_ITEMS,
        auditUsersToDisplay: finalResult,
      });
      dispatch({
        type: AuditUsersActionsTypes.SET_NO_OF_ACTIONS_IN_PROGRESS,
        noOfActionsInProgress: getState().auditUsersState.noOfActionsInProgress - 1,
      });
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {
        const auditUsersToDisplay = getState().auditUsersState.auditUsersToDisplay;
        const resultAfterDisable = setAuditUserDisabled(auditUsersToDisplay, newAuditUserEmails[0], false);
        dispatch({
          type: AuditUsersActionsTypes.SET_AUDIT_DISPLAY_ITEMS,
          auditUsersToDisplay: resultAfterDisable,
        });
        dispatch({
          type: AuditUsersActionsTypes.SET_NO_OF_ACTIONS_IN_PROGRESS,
          noOfActionsInProgress: getState().auditUsersState.noOfActionsInProgress - 1,
        });
      });
      return false;
    }
  };
};

export const deleteAccountExclusionAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetAuditDisplayItems>> = (account: IAccount, user: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: AuditUsersActionsTypes.SET_NO_OF_ACTIONS_IN_PROGRESS,
        noOfActionsInProgress: getState().auditUsersState.noOfActionsInProgress + 1,
      });

      const initialAuditUserState = getState().auditUsersState.auditUsersToDisplay;
      const resultAfterDisable = setAuditUserDisabled(initialAuditUserState, user, true);
      dispatch({
        type: AuditUsersActionsTypes.SET_AUDIT_DISPLAY_ITEMS,
        auditUsersToDisplay: resultAfterDisable,
      });
      await mspService.deleteBilledUser(apiUrl, account.id, user);
      const auditUsersToDisplay = getState().auditUsersState.auditUsersToDisplay;
      const finalResult = setExcludedAuditUser(auditUsersToDisplay, user, false);
      dispatch({
        type: AuditUsersActionsTypes.SET_AUDIT_DISPLAY_ITEMS,
        auditUsersToDisplay: finalResult,
      });

      dispatch({
        type: AuditUsersActionsTypes.SET_NO_OF_ACTIONS_IN_PROGRESS,
        noOfActionsInProgress: getState().auditUsersState.noOfActionsInProgress - 1,
      });
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {
        const auditUsersToDisplay = getState().auditUsersState.auditUsersToDisplay;
        const resultAfterDisable = setAuditUserDisabled(auditUsersToDisplay, user, false);
        dispatch({
          type: AuditUsersActionsTypes.SET_AUDIT_DISPLAY_ITEMS,
          auditUsersToDisplay: resultAfterDisable,
        });
        dispatch({
          type: AuditUsersActionsTypes.SET_NO_OF_ACTIONS_IN_PROGRESS,
          noOfActionsInProgress: getState().auditUsersState.noOfActionsInProgress - 1,
        });
      });
      return false;
    }
  };
};

export const clearSearchAuditUserResultsAction: ActionCreator<ThunkAction<any, IAppState, null, IGetAuditUsersAction>> = () => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { auditUserStateBeforeSearch } = getState().auditUsersState;
    if (auditUserStateBeforeSearch) {
      dispatch({
        type: AuditUsersActionsTypes.GET_AUDIT_USERS,
        auditUsersToDisplay: auditUserStateBeforeSearch.auditUsersToDisplay,
        loadingAuditUsers: false,
        nextPageToken: auditUserStateBeforeSearch.nextPageToken,
        apiSearchedAuditUserEmail: "",
      });

      dispatch({
        type: AuditUsersActionsTypes.GET_AUDIT_USERS_TABLE_PROPS,
        tableState: auditUserStateBeforeSearch.auditUsersTableState,
      });
    }
  };
};

export const clearAuditStateBeforeSearch: ActionCreator<ThunkAction<any, IAppState, null, ISaveAuditStateBeforeSearch>> = () => (dispatch: Dispatch) => dispatch({ type: AuditUsersActionsTypes.SAVE_AUDIT_DISPLAY_STATE, auditUserStateBeforeSearch: undefined });

export const setAuditStateBeforeSearch: ActionCreator<ThunkAction<any, IAppState, null, ISaveAuditStateBeforeSearch>> = (auditUserStateBeforeSearch: IAuditUserDisplayState) => (dispatch: Dispatch) => dispatch({ type: AuditUsersActionsTypes.SAVE_AUDIT_DISPLAY_STATE, auditUserStateBeforeSearch });

export const setSearchedAuditUserEmail: ActionCreator<ThunkAction<any, IAppState, null, ISetSearchedAuditUserEmail>> = (searchedAuditUserEmail: string) => (dispatch: Dispatch) => dispatch({ type: AuditUsersActionsTypes.SET_SEARCHED_AUDIT_USER_EMAIL, searchedAuditUserEmail });

export const setLoadingAuditUsers: ActionCreator<ThunkAction<any, IAppState, null, ISetLoadingAuditUsers>> = (loadingAuditUsers: boolean) => (dispatch: Dispatch) => dispatch({ type: AuditUsersActionsTypes.SET_LOADING_AUDIT_USERS, loadingAuditUsers });

export const importAuditUsersExclusionFile: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAddImportAuditUsersExclusionFileAction>> = (account: IAccount, csvFileName: string, users: string[]) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      addNewImportActionForAccount(account, csvFileName, users, getState, dispatch);
      await importEmailsForAccount(users, account, getState, dispatch);
      updateImportActionForAccount(getState, account, dispatch);
    } catch (err) {
      handleError(err, dispatch, () => {
        /* no action needed*/
      });
    }
  };
};

export const removeFinishedImportAuditUsersExclusionFile: ActionCreator<ThunkAction<any, IAppState, null, IRemoveFinishedImportAuditUsersExclusionFileAction>> = (accountId: number) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { importActions } = getState().auditUsersState;
    const currentActionIndex = importActions.findIndex((x: IAuditUserImportAction) => x.account.id === accountId);
    if (currentActionIndex > -1) {
      const newImportActionsState = produce(importActions, draft => {
        draft.splice(currentActionIndex, 1);
      });
      dispatch({
        type: AuditUsersActionsTypes.REMOVE_FINISHED_IMPORT_AUDIT_USERS_EXCLUSION_FILE,
        importActions: newImportActionsState,
      });
    }
  };
};

function addNewImportActionForAccount(account: IAccount, csvFileName: string, users: string[], getState: () => IAppState, dispatch: Dispatch) {
  const newImportAction = { account, csvFileName, invalidEmails: [], isRunning: true, noOfEmailsToImport: users.length, noOfImportedEmails: 0 };
  const { importActions } = getState().auditUsersState;
  const newImportActionsState = produce(importActions, draft => {
    draft.push(newImportAction);
  });
  dispatch({
    type: AuditUsersActionsTypes.ADD_IMPORT_AUDIT_USERS_EXCLUSION_FILE,
    importActions: newImportActionsState,
  });
}

async function importEmailsForAccount(users: string[], account: IAccount, getState: () => IAppState, dispatch: Dispatch) {
  const { apiUrl } = getState().generalState;
  const emailChunks = chunk(users, CHUNK_SIZE_BILLING_USERS_TO_IMPORT);
  const promises = emailChunks.map(async (emails: string[]) => {
    let result = await mspService.addBilledUser(apiUrl, account.id, emails);
    const { currentActionIndex, newImportActions } = getImportActionForAccount(getState, account);
    if (currentActionIndex > -1) {
      const newImportActionsState1 = produce(newImportActions, draft => {
        draft[currentActionIndex].noOfImportedEmails += emails.length;
      });
      dispatch({
        type: AuditUsersActionsTypes.ADD_IMPORT_AUDIT_USERS_EXCLUSION_FILE,
        importActions: newImportActionsState1,
      });
    }
    return result.data;
  });
  const finalResult = await Promise.all(promises);
  return finalResult;
}

function updateImportActionForAccount(getState: () => IAppState, account: IAccount, dispatch: Dispatch) {
  const { currentActionIndex, newImportActions } = getImportActionForAccount(getState, account);
  if (currentActionIndex > -1) {
    const newImportActionsState1 = produce(newImportActions, draft => {
      draft[currentActionIndex].isRunning = false;
    });
    dispatch({
      type: AuditUsersActionsTypes.ADD_IMPORT_AUDIT_USERS_EXCLUSION_FILE,
      importActions: newImportActionsState1,
    });
  }
}

function getImportActionForAccount(getState: () => IAppState, account: IAccount): { currentActionIndex: number; newImportActions: IAuditUserImportAction[] } {
  const newImportActions = getState().auditUsersState.importActions;
  const currentActionIndex = newImportActions.findIndex((x: IAuditUserImportAction) => x.account.id === account.id);
  return { currentActionIndex, newImportActions };
}
