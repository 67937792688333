import React, { useMemo, useState } from "react";
import { Button } from "@barracuda/bds-core";
import Grid from "@barracuda/bds-core/dist/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import IInvoice from "../../models/Invoices/IInvoice";
import { IInvoiceOptions } from "../../models/Invoices/InvoiceOptions";

interface IInvoicesActionsSplitButtonProps {
  isLoading: boolean;
  buttonColor: string;
  invoice: IInvoice;
  setSelectedAction: (selectedAction: IInvoiceOptions, invoice: IInvoice) => void;
  options: IInvoiceOptions[];
  disabled: boolean;
}

const InvoicesActionsSplitButton: React.FC<IInvoicesActionsSplitButtonProps> = ({ isLoading, buttonColor, invoice, setSelectedAction, options, disabled }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useMemo(() => {
    const ix: number = options.findIndex(x => x.value === invoice.optionSelected?.value);
    if (ix > -1) {
      setSelectedIndex(ix);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, options]);

  const handleClick = () => {
    setSelectedAction(options[selectedIndex], invoice);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
    setSelectedAction(options[index], invoice);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <td>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup variant="contained" color={buttonColor as any} ref={anchorRef} aria-label="split button">
            <Button color={buttonColor} isLoading={isLoading} disabled={disabled} size="medium" onClick={handleClick} className={"invoiceActionButton"}>
              {options[selectedIndex]?.label}
            </Button>
            {options.length > 1 && (
              <Button data-testid="invoicesActionSplitButton" disabled={disabled} color={buttonColor} size="medium" aria-controls={open ? "split-button-menu" : undefined} aria-expanded={open ? "true" : undefined} aria-label="select merge strategy" aria-haspopup="menu" onClick={handleToggle}>
                <ArrowDropDownIcon />
              </Button>
            )}
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem key={option.value} data-testid={"invoicesActionSplitButton" + index} selected={index === selectedIndex} onClick={event => handleMenuItemClick(event, index)}>
                          {option?.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </td>
  );
};

export default InvoicesActionsSplitButton;
