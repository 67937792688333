import IProduct from "./IProduct";
import IProductFamily from "./IProductFamily";
import ProductFamily from "./ProductFamily";
import SerialStatus from "./SerialStatus";

export interface ISubpartnersWithProducts {
  accountId: number;
  accountName: string;
  totalProducts: number;
  totalErrors: number;
  hasPendingSerials: boolean;
  hasSerials: boolean;
  noOfRmaSerials: number;
  products?: IProduct[];
}

export function getSubpartnerProductsSummary(productFamilies: IProductFamily[], subpartner: ISubpartnersWithProducts): ISubpartnersWithProducts {
  let totalErrors = 0;
  let totalProducts = 0;
  let hasPendingSerials = false;
  let hasSerials = false;
  let noOfRmaSerials = 0;

  productFamilies.forEach((family: IProductFamily) => {
    totalProducts += family.products.length;

    if (totalProducts > 0) {
      family.products.forEach(product => {
        totalErrors += product.noOfErrors;
        if (product.hasPendingSerials) {
          hasPendingSerials = true;
        }
        if (product.serials.length > 0) {
          hasSerials = isProductActivatedToAccount(product);
        }
        noOfRmaSerials += product.noOfRmaSerials !== undefined ? product.noOfRmaSerials : 0;
      });
    }
  });

  return {
    accountId: subpartner.accountId,
    accountName: subpartner.accountName,
    totalProducts,
    totalErrors,
    hasPendingSerials,
    hasSerials,
    noOfRmaSerials,
  };
}

export function isProductActivatedToAccount(prodElement: IProduct): boolean {
  let hasSerials = false;
  if (prodElement.serials.length < 1) {
    return false;
  }
  if (prodElement.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
    hasSerials = isBBSProductActivatedToAccount(prodElement);
  } else {
    hasSerials = true;
  }
  return hasSerials;
}

export function isBBSProductActivatedToAccount(prodElement: IProduct): boolean {
  if (!prodElement.type.includes(ProductFamily.BACKUP_APPLIANCES)) {
    throw new Error("Invalid argument");
  }
  let allProductsHaveAvailableState = true;
  if (prodElement.serials[0].status !== SerialStatus.AVAILABLE) {
    allProductsHaveAvailableState = false;
  }
  return !allProductsHaveAvailableState;
}
