import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@barracuda/bds-core";
import React from "react";
import { enterKey } from "../../../utility";

interface ISubmitCancelSerialDialogProps {
  onCancel: () => void;
  showCancelSerialDialog: boolean;
  dialogTitle: string | undefined;
}
const ShowCancelSerialDialog: React.FC<ISubmitCancelSerialDialogProps> = ({ showCancelSerialDialog, onCancel, dialogTitle }) => {
  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onCancel)} className="cancelSerialDialog" data-testid="cancelSerialDialog" disableBackdropClick={true} open={showCancelSerialDialog} onClose={onCancel}>
      <DialogTitle data-testid="cancelSerialTitle"> {"CANCEL " + dialogTitle} </DialogTitle>
      <DialogContent style={{ paddingTop: 26, paddingBottom: 19, paddingRight: 27, paddingLeft: 27 }}>
        <Typography data-testid="cancelSerialMessage" variant="h6">
          To cancel a Serial Number, you must call<b> 1-800-569-0155</b>.
          <br /> <br />
          Only Serial Numbers that are <b className="DarkRedColor">NOT</b> in an <b>Active</b> or <b>Assigned</b> state can be canceled.
          <br />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button data-testid="okCancelSerial" primary="true" size="large" onClick={onCancel} style={{ marginRight: 16 }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowCancelSerialDialog;
