import React from "react";
import Grid from "@barracuda/bds-core/dist/Grid";
import { Back } from "@barracuda/bds-core/dist/Icons/Core";
import { Link, Typography } from "@barracuda/bds-core";

interface IBackToProperties {
  text: string;
  callback: Function;
  testId?: string;
  className?: string;
  variant?: string;
  enforceText?: boolean;
}

const BackTo = ({ testId, text, callback, className, variant, enforceText }: IBackToProperties) => {
  testId = testId ?? `backTo${text.replace(/\s/g, "")}`;
  variant = variant ?? "body1";

  return (
    <Link data-testid={testId} href="#" className={className} onClick={(event: React.SyntheticEvent) => callback(event)}>
      <Grid container item>
        <Grid item>
          <Back />
        </Grid>
        <Grid item>
          <Typography variant={variant} gutterBottom>
            {enforceText !== undefined && enforceText === true ? text : `Back to ${text}`}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};

export default BackTo;
