import IAccount from "./models/IAccount";
import IProduct from "./models/Products/IProduct";
import IProductFamily from "./models/Products/IProductFamily";
import ISerial from "./models/Products/ISerial";
import SerialStatus from "./models/Products/SerialStatus";
import UserRole, { UserRoleValues } from "./models/UserRole";
import { IDetailsTabs } from "./components/InfoPanel";
import { getProductTypeName } from "./models/Products/ProductFamily";
import IAuditUser from "./models/Products/IAuditUser";
import { TokenStorage } from "./TokenStorage";
import { AxiosResponse } from "axios";
import env from "@beam-australia/react-env";
import MspType from "./models/MspType";
import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import CustomColors from "./models/CustomColors";
import produce from "immer";

export const dynamicSort = (property: string): any => {
  let sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substring(1);
  }
  return function (a: any, b: any) {
    const isGreater = a[property].toLowerCase() > b[property].toLowerCase() ? 1 : 0;
    let result = a[property].toLowerCase() < b[property].toLowerCase() ? -1 : isGreater;
    return result * sortOrder;
  };
};

export const isStringNullOrEmpty = (value: string | undefined): boolean => {
  if (value) {
    if (value.trim()) {
      return false;
    }
  }
  return true;
};

export const groupBy = (array: any, key: any) => {
  return array.reduce((result: any, currentValue: any) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
};

export function eliminateDuplicates(values: any[], key: string): any[] {
  return values.filter((value, index, array) => array.findIndex(t => t[key] === value[key]) === index);
}

export const userHasRole = (role: UserRole, mspAccountLoggedIn: IAccount) => {
  let authDbValueResult = UserRoleValues.find(value => value.value === role)?.authDbValue;
  return mspAccountLoggedIn.roles?.[0] === authDbValueResult;
};

export const isNotFinance = (mspAccountLoggedIn: IAccount) => {
  return !isFinance(mspAccountLoggedIn);
};

export const getShowEditSerialButton = (mspAccountLoggedIn: IAccount) => {
  return mspAccountLoggedIn.type !== MspType.BillingAggregator && !isFinance(mspAccountLoggedIn);
};

export const getSerialStatusColor = (serial: ISerial): string => {
  switch (serial.status) {
    case SerialStatus.ACTIVE:
    case SerialStatus.AVAILABLE:
      return CustomColors.DarkGreen;
    case SerialStatus.ACTIVATION_FAILED:
    case SerialStatus.PROVISION_FAILED:
    case SerialStatus.RMA_NEEDED:
      return CustomColors.DarkRed;
    default:
      return "darkgray";
  }
};

export const getSerialStatusLabel = (status: string) => {
  if (status === SerialStatus.SSG_PENDING) {
    return "Activation Pending";
  } else {
    return status;
  }
};

export const getFinalProduct = (resultOrders: IProduct[]): IProductFamily[] => {
  let result: IProductFamily[] = [];
  const productsByFamilyName = groupBy(resultOrders, "type");
  const names = Object.getOwnPropertyNames(productsByFamilyName);
  names.forEach(name => {
    const prodToDisplay = resultOrders.filter((product: IProduct) => product.type === name);
    let productFamilyErrors = 0;
    let productHasPendingSerials = false;
    let productNoOfRmaSerials = 0;
    prodToDisplay.forEach(prod => {
      productFamilyErrors += prod.noOfErrors;
      if (prod.hasPendingSerials && prod.hasPendingSerials === true) {
        productHasPendingSerials = true;
      }
      productNoOfRmaSerials += prod.noOfRmaSerials !== undefined ? prod.noOfRmaSerials : 0;
    });
    const prodEnum = getProductTypeName(name);
    result.push({ productType: prodEnum, products: prodToDisplay, errors: productFamilyErrors, hasPendingSerials: productHasPendingSerials, noOfRmaSerials: productNoOfRmaSerials });
  });
  return result;
};

export const getDetailsTab = (tabs: IDetailsTabs[], label: string) => {
  return tabs.filter(x => x.tab.label === label)[0];
};

export const setAuditUserDisabled = (users: IAuditUser[], email: string, isDisabled: boolean) => {
  const accountIndex = users.findIndex((x: IAuditUser) => x.user === email);
  const newAuditUsersToDisplayNewState = produce(users, draft => {
    draft[accountIndex].isDisabled = isDisabled;
  });
  return newAuditUsersToDisplayNewState;
};

export const setExcludedAuditUser = (users: IAuditUser[], email: string, isExcluded: boolean) => {
  const accountIndex = users.findIndex((x: IAuditUser) => x.user === email);
  const newAuditUsersToDisplayNewState = produce(users, draft => {
    draft[accountIndex].isExcluded = isExcluded;
    draft[accountIndex].isDisabled = false;
  });
  return newAuditUsersToDisplayNewState;
};

export const setButtonColor = (selectedAccount: IAccount | undefined) => {
  if (selectedAccount) {
    return "secondary";
  } else {
    return "primary";
  }
};

export function getButtonCount(total: number, take: number | undefined, isGreatRes: boolean) {
  const defaultValue = 10;
  const minNoOfButtons = isGreatRes ? 5 : 3;
  if (total === 0) {
    return 1;
  }
  if (total === take || (take === undefined && total === defaultValue)) {
    return 1;
  }
  return Math.min(minNoOfButtons, Math.floor(total / (take ? take : defaultValue)) + 1);
}

export function setNoPoinerEvents(condition: boolean) {
  let className = "";
  if (condition) {
    className = "noPointerEvents";
    return className;
  }
  return className;
}

export function truncate(input: string, size: number) {
  if (input.length > size) {
    return input.substring(0, size) + "...";
  }
  return input;
}

export function getPercentageSubpartnersLoadedProgress(noOfLoadedSubpartners: number, noOfSubpartners: number): number {
  if (noOfSubpartners > 0) {
    return (noOfLoadedSubpartners * 100) / noOfSubpartners;
  }
  return 0;
}

export function enterKey(event: any, submitEvent: any) {
  const ENTER = 13;
  if (event.keyCode === ENTER) {
    //submitEvent();
  }
}

export function chunk(arr: any[], len: number) {
  let chunks = [],
    i = 0,
    n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
}

export function logout() {
  TokenStorage.clear();
  window.location.href = `${env("AUTH_URL")}/logout`;
}

export function isAuthenticationError(error: any) {
  return error?.response?.status === 401;
}

export function isTokenExpiredError(error: any) {
  if (isAuthenticationError(error)) {
    return getErrorMessage(error.response?.data?.errors).errorMessage === "Token Expired.";
  } else {
    return false;
  }
}

export function isRefreshTokenError(error: any): boolean {
  if (error.config && error.config.data) {
    return JSON.parse(error.config.data).grant_type === "refresh_token";
  } else {
    return false;
  }
}

export function shouldRefreshToken(error: any): boolean {
  return error.config.data === undefined || (error.config.data && (JSON.parse(error.config.data).shouldNotRefreshToken === undefined || JSON.parse(error.config.data).shouldNotRefreshToken === false));
}

export function log(response: AxiosResponse<any>) {
  if (process.env.NODE_ENV === "development") {
    console.log(response);
  }
}
export function getErrorMessage(errors: any): { errorMessage: string; errorCode: string | undefined } {
  let errorMess: string = "";
  let errorCo: string | undefined = "";
  if (errors) {
    if (errors.length > 0) {
      errorMess = errors[0].error_description;
      errorCo = errors[0].error_code;
    } else {
      errorMess = errors.error_description;
      errorCo = errors.error_code;
    }
  }
  return { errorMessage: errorMess, errorCode: errorCo };
}

export function getDateToDisplay(date: Date | undefined): string {
  if (date && new Date(date).getFullYear() > 0) {
    return new Date(date).toLocaleDateString(window.navigator.language, { timeZone: "UTC", year: "numeric", month: "2-digit", day: "2-digit" });
  }
  return "";
}

export function getTimeToDisplay(date: Date): string {
  return new Date(date).toLocaleTimeString(window.navigator.language, { hour: "2-digit", minute: "2-digit" });
}

export function getDateForApiCall(date: Date): string {
  const iso = new Date(date).toISOString();
  return iso.substring(0, iso.indexOf("T"));
}

export function getDateTimeForApiCall(date: Date): string {
  const iso = new Date(date).toISOString();
  const index = iso.indexOf("T");
  const dateString = iso.substring(0, index);
  const timeString = iso.slice(index + 1, index + 9);
  return dateString + " " + timeString;
}

export function dateOlderThan60Days(date: Date): boolean {
  const date60DaysBack = new Date(new Date().setDate(new Date().getDate() - 60));
  return new Date(date) <= date60DaysBack;
}

export function getAmountToDisplay(amount: number | undefined, currency: string, language?: string): string {
  if (isNullOrUndefined(language)) {
    language = window.navigator.language;
  }
  if (amount) {
    if (currency !== "") {
      return amount.toLocaleString(language, { style: "currency", currency: currency });
    } else {
      return amount.toString();
    }
  }
  return "";
}

export function getBrowserCountry(): string {
  if (window.navigator.language.indexOf("-") > -1) {
    return window.navigator.language.slice(-2);
  }
  return "";
}

export function getDateFormatString(locale: string): string {
  const formatObj = new Intl.DateTimeFormat(locale, { timeZone: "UTC", year: "numeric", month: "2-digit", day: "2-digit" }).formatToParts(new Date());

  return formatObj
    .map(obj => {
      switch (obj.type) {
        case "day":
          return "DD";
        case "month":
          return "MM";
        case "year":
          return "YYYY";
        default:
          return obj.value;
      }
    })
    .join("");
}

export function getCanManageIntegrations(selectedAccount: IAccount | undefined, mspAccountLoggedIn: IAccount): boolean {
  if (mspAccountLoggedIn.type === MspType.BillingAggregator) {
    return false;
  }
  return !isFinance(mspAccountLoggedIn) && mspAccountLoggedIn.id === selectedAccount?.id && !selectedAccount?.type.includes(MspType.Customer);
}

export function getCanExportDataUsage(mspAccountLoggedIn: IAccount): boolean {
  return isAdmin(mspAccountLoggedIn) || userHasRole(UserRole.BillingAggregatorFinance, mspAccountLoggedIn);
}

export function canAddEditUser(mspAccountLoggedIn: IAccount): boolean {
  return isAdmin(mspAccountLoggedIn);
}

export function isAdmin(mspAccountLoggedIn: IAccount): boolean {
  return userHasRole(UserRole.Admin, mspAccountLoggedIn) || userHasRole(UserRole.BillingAggregatorAdmin, mspAccountLoggedIn);
}

export function isFinance(mspAccountLoggedIn: IAccount): boolean {
  return userHasRole(UserRole.Finance, mspAccountLoggedIn) || userHasRole(UserRole.BillingAggregatorFinance, mspAccountLoggedIn);
}

export function getDateTimeToDisplay(date: Date): string {
  return new Date(date).toLocaleDateString(window.navigator.language, { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" });
}

export function addHttps(url: string | undefined): string {
  if (url?.startsWith("https://")) {
    return url;
  } else {
    url = "https://" + url;
  }
  return url;
}

export function objectWithoutKey(object: any, key: any): any {
  const { [key]: deletedKey, ...otherKeys } = object;
  return otherKeys;
}

export function matchApiToEcho(currentApiUrl: string, apiUrls: any) {
  const index = apiUrls.findIndex((x: any) => x.api_url === currentApiUrl);
  if (index > -1) {
    return apiUrls[index].echo_url;
  }
  return "";
}

export function redirectWithSso(echoUrl: string, mspAccountLoggedIn: IAccount, selectedAccount: IAccount, accountUserName?: string) {
  window.open(getGoToEchoPlatformLink(echoUrl, mspAccountLoggedIn, selectedAccount, accountUserName), "_blank", "noopener,noreferrer");
}

export function getGoToEchoPlatformLink(echoUrl: string, mspAccountLoggedIn: IAccount, selectedAccount: IAccount, accountUserName?: string) {
  if (selectedAccount.type === MspType.Partner || selectedAccount.type === MspType.Subpartner || mspAccountLoggedIn.type === MspType.Customer) {
    return `${echoUrl}/manage/Manage.aspx`;
  } else {
    return `${echoUrl}/manage/AccountDetails.aspx?account=${accountUserName}`;
  }
}

export function isObjectEmpty(obj: any) {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function dataStateHasChanged(dataState1: State, dataState2: State): boolean {
  if (dataState1.take !== dataState2.take) {
    return true;
  }
  if (dataState1.skip !== dataState2.skip) {
    return true;
  }
  if (JSON.stringify(dataState1.sort) !== JSON.stringify(dataState2.sort)) {
    return true;
  }
  if (filterHasChanged(dataState1.filter, dataState2.filter)) {
    return true;
  }
  return false;
}

export function filterHasChanged(filter1: CompositeFilterDescriptor | undefined, filter2: CompositeFilterDescriptor | undefined): boolean {
  if (JSON.stringify(filter1) === JSON.stringify(filter2)) {
    return false;
  }
  if (isNullOrUndefined(filter1) && isNullOrUndefined(filter2)) {
    return false;
  }
  return true;
}

function isNullOrUndefined(object: any): boolean {
  return object === undefined || object === null;
}

export function getAdjustedPageNumberAfterDelete(noOfItems: number, pageSize: number, pageNumber: number) {
  if (noOfItems === 0) {
    return 1;
  }
  if (noOfItems <= pageSize) {
    return 1;
  }
  if (noOfItems % pageSize === 0) {
    if (pageNumber > noOfItems / pageSize) {
      return pageNumber - 1;
    }
  }
  return pageNumber;
}

export function getAdjustedPageNumberAfterAdd(noOfItems: number, pageSize: number) {
  if (noOfItems === 0) {
    return 1;
  } else if (noOfItems % pageSize === 0) {
    return Math.floor(noOfItems / pageSize);
  } else {
    return Math.floor(noOfItems / pageSize) + 1;
  }
}

export function stripTrailingSlash(value: string): string {
  return value.endsWith("/") ? value.slice(0, -1) : value;
}

export function getShowSearchAccount(loggedInAccount: IAccount) {
  return loggedInAccount.type === MspType.BillingAggregator;
}

export const getTabsWithIndex = (initailTabs: IDetailsTabs[]) => {
  let tabsWithIndex = [...initailTabs];
  for (let i = 0; i < tabsWithIndex.length; i++) {
    tabsWithIndex[i].id = i;
  }
  return tabsWithIndex;
};
