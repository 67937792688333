import React, { useState, useEffect } from "react";
import { Card, Tabs, TabPanel, Tab } from "@barracuda/bds-core";
import ProductsTab from "./Products/ProductsTab";
import UsersTab from "./Users/UsersTab";
import AccountDetailsTab from "./Accounts/AccountDetailsTab";
import { useDispatch, useSelector } from "react-redux";
import { cancelCurrentAction, setSelectedTabName, setViewBillingExclusionsListAction } from "../actions/generalActions";
import { fetchM365AuthAction, getAccountAddressAction, loadParentAccountWithChildrenAction, setLoadedAccountAction, loadPartnerChildren, resetPartnerChildren, fetchAccountAllChildren } from "../actions/accountActions";
import { getAccountUsersAction } from "../actions/userActions";
import DetailsTabs from "../models/DetailsTabs";
import { IAppState } from "../store/store";
import { getCurrencyAction, getHasSubpartnersWithProducts, getIBUStatusAction, getParentMspProductsAction, getProductsAction, resetProductsToDisplayAction } from "../actions/productActions";
import { getDetailsTab, isStringNullOrEmpty, getTabsWithIndex } from "../utility";
import MspType from "../models/MspType";
import IAccount from "../models/IAccount";
import { TabProps } from "@barracuda/bds-core/dist/Tabs";
import AuditUsersCard from "./Products/AuditUsers/AuditUsersCard";
import FinanceTab from "./Finance/FinanceTab";
import { canViewBillingTab, filterTabs } from "../Utilities/accountsHelper";
import { getInvoices, getPaymentMethod, setInvoicesTableState } from "../actions/financeActions";

export interface IDetailsTabs {
  id: number;
  tab: TabProps;
}

export interface ICustomMessageDialog {
  userTabLoaded: boolean;
  detailsTabLoaded: boolean;
  productsTabLoaded: boolean;
  financeTabLoaded: boolean;
  setTabLoaded: (loaded: boolean, tab: DetailsTabs) => void;
}

const InfoPanel: React.FC<ICustomMessageDialog> = ({ userTabLoaded, detailsTabLoaded, productsTabLoaded, financeTabLoaded, setTabLoaded }) => {
  const dispatch = useDispatch();
  const isBillingExclusionsDisplayed = useSelector((state: IAppState) => state.generalState.isBillingExclusionsDisplayed);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const loadedAccount = useSelector((state: IAppState) => state.accountState.loadedAccount);
  const selectedTabName = useSelector((state: IAppState) => state.generalState.selectedTabName);
  const loadingUsersCanceledForAccountId = useSelector((state: IAppState) => state.userState.loadingUsersCanceledForAccountId);
  const loadingProductsCanceledForAccountId = useSelector((state: IAppState) => state.productState.loadingProductsCanceledForAccountId);
  const loadingParentProductsCanceled = useSelector((state: IAppState) => state.productState.loadingParentProductsCanceled);
  const loadingInvoicesCanceled = useSelector((state: IAppState) => state.financeState.loadingInvoicesCanceled);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);
  const selectedAccountToFilterProductsForBA = useSelector((state: IAppState) => state.accountState.selectedAccountToFilterProductsForBA);
  const alltabs: IDetailsTabs[] = [
    {
      id: 0,
      tab: {
        label: DetailsTabs.Products,
        value: <ProductsTab />,
      },
    },
    {
      id: 0,
      tab: {
        label: DetailsTabs.Users,
        value: <UsersTab />,
      },
    },
    {
      id: 0,
      tab: {
        label: DetailsTabs.AccountsDetails,
        value: <AccountDetailsTab />,
      },
    },
    {
      id: 0,
      tab: {
        label: DetailsTabs.Finance,
        value: <FinanceTab />,
      },
    },
  ];

  const initialTabs = filterTabs(alltabs, mspAccountLoggedIn, selectedAccount);

  const [tabs, setTabs] = useState(getTabsWithIndex(initialTabs));

  const selectedDetailTab = getDetailsTab(tabs, selectedTabName);
  const [selectedTab, setSelectedTab] = useState(selectedDetailTab);
  const handleChange: any = (_e: React.SyntheticEvent, newValue: any) => {
    let resultValue = tabs.filter(x => x.id === newValue)[0];
    if (resultValue !== null) {
      setSelectedTab(resultValue);
      dispatch(setSelectedTabName(resultValue.tab.label));
    }
  };

  const [tabObject, setTabObject] = useState(selectedDetailTab);

  useEffect(() => {
    setTabs(initialTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  useEffect(() => {
    if (isStringNullOrEmpty(selectedTabName)) {
      dispatch(setSelectedTabName(initialTabs[0].tab.label as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isTabFound = false;
    initialTabs.forEach(item => {
      if (item.tab.label === selectedTabName) {
        isTabFound = true;
      }
    });

    if (!isTabFound) {
      setSelectedTab(getDetailsTab(initialTabs, initialTabs[0].tab.label as string));
      dispatch(setSelectedTabName(initialTabs[0].tab.label as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  useEffect(() => {
    setSelectedTab(selectedDetailTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDetailTab]);

  useEffect(() => {
    setSelectedTab(selectedDetailTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabName]);

  useEffect(() => {
    setTabObject(selectedDetailTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const loadProducts = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getProductsAction(account));
      resolve(result);
    });

  const getParentAccount = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(loadParentAccountWithChildrenAction(account.id, false));
      resolve(result);
    });

  function getParentProducts(selectedAccount: IAccount) {
    if (selectedAccount.partnerId !== undefined) {
      dispatch(getParentMspProductsAction(selectedAccount));
    } else {
      getParentAccount(selectedAccount).then(result => {
        if (result) {
          const partnerId = result.partnerId;
          if (partnerId) {
            dispatch(getParentMspProductsAction({ ...selectedAccount, partnerId: partnerId }));
          }
        }
      });
    }
  }

  const loadProductsAndFollowUp = (loadProductsAction: (account: IAccount) => Promise<any>, selectedItem: IAccount, followUpAction: any) => {
    loadProductsAction(selectedItem).then(result => {
      if (result) {
        if (followUpAction) {
          followUpAction();
        }
      }
    });
  };

  const loadCurrency = (account: IAccount) =>
    new Promise<any>(resolve => {
      const result = dispatch(getCurrencyAction(account));
      resolve(result);
    });

  const getProductsAndDoNextAction = (followUpAction: any, selectedItem: IAccount, loggedInPartner: IAccount, selAccountToFilterProducts: IAccount | undefined) => {
    if (isBaLoggedIn) {
      if (selectedItem.type === MspType.Partner) {
        if (selAccountToFilterProducts === undefined) {
          dispatch(resetPartnerChildren());
          loadCurrency(selectedItem).then(result => {
            if (result) {
              dispatch(fetchAccountAllChildren(selectedItem));
            }
          });
          dispatch(loadPartnerChildren(selectedItem));
          loadProductsAndFollowUp(loadProducts, selectedItem, followUpAction);
        }
      } else if (selectedItem.type === MspType.BillingAggregator) {
        dispatch(cancelCurrentAction());
        dispatch(resetProductsToDisplayAction());
      }
    } else {
      loadProductsAndFollowUp(loadProducts, selectedItem, followUpAction);
    }
  };

  useEffect(() => {
    if (selectedAccount && selectedTab) {
      if (selectedAccount?.type !== MspType.Customer) {
        dispatch(setViewBillingExclusionsListAction(false));
      }
      let accountHasChanged = false;
      if (loadedAccount?.id !== selectedAccount.id) {
        accountHasChanged = true;
        dispatch(setLoadedAccountAction(selectedAccount));
        setTabLoaded(false, DetailsTabs.Products);
        setTabLoaded(false, DetailsTabs.Users);
        setTabLoaded(false, DetailsTabs.AccountsDetails);
        setTabLoaded(false, DetailsTabs.Finance);
      }
      if (selectedTab.tab.label === DetailsTabs.AccountsDetails) {
        if (accountHasChanged || !detailsTabLoaded) {
          setTabLoaded(true, DetailsTabs.AccountsDetails);
          dispatch(getAccountAddressAction(selectedAccount));
          if (selectedAccount?.type === MspType.Customer) {
            dispatch(fetchM365AuthAction(selectedAccount));
          }
        }
      } else if (selectedTab.tab.label === DetailsTabs.Users) {
        if (accountHasChanged || !userTabLoaded || loadingUsersCanceledForAccountId === selectedAccount.id) {
          setTabLoaded(true, DetailsTabs.Users);
          dispatch(getAccountUsersAction(selectedAccount));
        }
      } else if (selectedTab.tab.label === DetailsTabs.Finance && canViewBillingTab(mspAccountLoggedIn, selectedAccount)) {
        if ((accountHasChanged || !financeTabLoaded || loadingInvoicesCanceled) && canViewBillingTab(mspAccountLoggedIn, selectedAccount)) {
          setTabLoaded(true, DetailsTabs.Finance);
          dispatch(setInvoicesTableState({ sort: [{ field: "date", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } }));
          dispatch(getPaymentMethod(selectedAccount));
          dispatch(getInvoices(selectedAccount));
        }
      } else if (selectedTab.tab.label === DetailsTabs.Products) {
        if (accountHasChanged || !productsTabLoaded || loadingProductsCanceledForAccountId === selectedAccount.id || loadingParentProductsCanceled) {
          setTabLoaded(true, DetailsTabs.Products);
          let followUpAction: any = undefined;
          if (selectedAccount.type === MspType.Partner && mspAccountLoggedIn.type !== MspType.BillingAggregator) {
            followUpAction = () => dispatch(getHasSubpartnersWithProducts());
          } else if (hasPresetParent(mspAccountLoggedIn, selectedAccount)) {
            followUpAction = () => {
              getParentProducts(selectedAccount);
            };
          }
          if (accountHasChanged || !productsTabLoaded || loadingProductsCanceledForAccountId === selectedAccount.id) {
            getProductsAndDoNextAction(followUpAction, selectedAccount, mspAccountLoggedIn, selectedAccountToFilterProductsForBA);
            dispatch(getIBUStatusAction(selectedAccount));
          } else {
            if (followUpAction) {
              followUpAction();
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, selectedAccount, loadingUsersCanceledForAccountId, loadingProductsCanceledForAccountId, loadingParentProductsCanceled, userTabLoaded, financeTabLoaded, detailsTabLoaded, financeTabLoaded, selectedAccountToFilterProductsForBA]);

  const onHandleBackToProduct = () => {
    dispatch(setViewBillingExclusionsListAction(false));
  };

  return (
    <Card>
      {selectedAccount?.type === MspType.Customer && isBillingExclusionsDisplayed ? (
        <AuditUsersCard data-testid="billingExclusionsListCard" handleBackToProducts={onHandleBackToProduct} />
      ) : (
        selectedTab &&
        tabObject && (
          <div>
            <Tabs value={selectedTab.id} onChange={handleChange} indicatorColor="primary" textColor="primary">
              {tabs.map((t, index) => (
                <Tab key={"infoTab" + index} data-testid={"infoTab" + t.tab.label?.toString().toLocaleLowerCase().replace(/\s/g, "")} label={t.tab.label} />
              ))}
            </Tabs>
            <TabPanel>{tabObject.tab.value}</TabPanel>
          </div>
        )
      )}
    </Card>
  );
};

export default InfoPanel;
function hasPresetParent(mspAccountLoggedIn: IAccount, selectedAccount: IAccount): boolean {
  return (selectedAccount.type === MspType.Subpartner && mspAccountLoggedIn.type === MspType.Partner) || (selectedAccount.type === MspType.Customer && mspAccountLoggedIn.type !== MspType.Customer);
}
