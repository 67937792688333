import Tooltip from "@barracuda/bds-core/dist/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { truncate } from "../utility";

interface ITruncatedText {
  value: string;
  bold: boolean;
  maxLength: number;
}

const TruncatedText: React.FC<ITruncatedText> = ({ value, bold, maxLength }) => {
  const nonTruncatedText = bold ? <b> {value}</b> : <div> {value}</div>;
  const truncatedText = bold ? <b> {truncate(value, maxLength)}</b> : <div> {truncate(value, maxLength)}</div>;
  const useStyles = makeStyles(theme => ({
    popper: {
      zIndex: 9000,
    },
  }));
  let classes = useStyles();
  return value.length > maxLength ? (
    <Tooltip classes={{ popper: classes.popper }} title={value}>
      {truncatedText}
    </Tooltip>
  ) : (
    nonTruncatedText
  );
};

export default TruncatedText;
