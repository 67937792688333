import React from "react";
import StartApp from "./components/StartApp";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NewUser from "./components/NewUserPage/NewUser";
import { BDSProvider } from "@barracuda/bds-core";

const App = (): JSX.Element => {
  return (
    <BDSProvider>
      <Router>
        <Routes>
          <Route path="/" element={<StartApp />} />
          <Route path="/newUser" element={<NewUser />} />
          <Route path="*" element={<StartApp />} />
        </Routes>
      </Router>
    </BDSProvider>
  );
};

export default App;
