import React, { useEffect, useState } from "react";
import { DataTable, DataTableColumn as Column } from "@barracuda/bds-core";
import { process, State } from "@progress/kendo-data-query";
import { GridNoRecords } from "@progress/kendo-react-grid";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress, useMediaQuery } from "@material-ui/core";
import BBSProductNameCell from "./BBSProductNameCell";
import IProduct from "../../../models/Products/IProduct";
import IProductFamily from "../../../models/Products/IProductFamily";
import { IAppState } from "../../../store/store";
import { setTablePropsForBBS } from "../../../actions/productActions";
import ButtonCommandCell from "../../ButtonCommandCell";
import TooltipElement from "../../Users/TooltipElement";
import SerialStatus from "../../../models/Products/SerialStatus";
import { getButtonCount, getSerialStatusLabel } from "../../../utility";
import AccountIconsCell from "../AccountIconsCell";
import SerialStatusCell from "../Serials/SerialStatusCell";
import BBSFilterStatusDropdown from "./BBSFilterStatusDropdown";
import Pager from "@barracuda/bds-core/dist/DataTable/Pager";
import { getAccountNameForBbsOrder, getStatus } from "../../../Utilities/productsHelper";
import { serialHasRmaStatus } from "../../../businessLogic/products";
import { filterDateInput, filterTextInput, getColorForColumn } from "../../TableHelpers";
import { getBbsProductTableColumnsSettings, getEditBBSSerialTitle, isEditBBSDisabled } from "../../../businessLogic/components/Products/BBS/BBSProductsTable";
import PriceCell from "../../PriceCell";
import DateCell from "../../DateCell";

interface IProductsTableProps {
  productFamily: IProductFamily;
  openEditDialog: (product: IProduct, selectedAccountType: string) => void;
}

const BBSProductsTable: React.FC<IProductsTableProps> = ({ productFamily, openEditDialog }) => {
  const dispatch = useDispatch();
  const currency = useSelector((state: IAppState) => state.productState.currency);
  const loadingSerials = useSelector((state: IAppState) => state.productState.loadingProducts);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const selectedAccountToFilterProductsForBA = useSelector((state: IAppState) => state.accountState.selectedAccountToFilterProductsForBA);
  const tableState = useSelector((state: IAppState) => state.productState.bbsTableState);
  const [width, setWidth] = useState(120);
  const [statusWidth, setStatusWidth] = useState(110);
  const responsiveViewPortTriggerMin = useMediaQuery("(max-width: 1600px)");
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const accountNames = useSelector((state: IAppState) => state.accountState.accountsNames);
  let statuses = [
    { bbsOrderStatus: getSerialStatusLabel(SerialStatus.ACTIVE), show: false },
    { bbsOrderStatus: getSerialStatusLabel(SerialStatus.ACTIVATION_FAILED), show: false },
    { bbsOrderStatus: SerialStatus.SSG_PENDING, show: false },
    { bbsOrderStatus: getSerialStatusLabel(SerialStatus.AVAILABLE), show: false },
    { bbsOrderStatus: getSerialStatusLabel(SerialStatus.PENDING), show: false },
    { bbsOrderStatus: getSerialStatusLabel(SerialStatus.PROVISION_FAILED), show: false },
    { bbsOrderStatus: getSerialStatusLabel(SerialStatus.RMA_ISSUED), show: false },
    { bbsOrderStatus: getSerialStatusLabel(SerialStatus.RMA_NEEDED), show: false },
    { bbsOrderStatus: getSerialStatusLabel(SerialStatus.RMA_REQUESTED), show: false },
  ];
  const [newStatuses, setNewStatuses] = useState(statuses);
  const [updatedStatuses, setUpdatedStatuses] = useState({} as any);
  const [currentlySelectedAccountId, setCurrentlySelectedAccountId] = useState(0);
  const [buttonCount, setButtonCount] = useState(10);
  const [showPriceColumns, setShowPriceColumns] = useState(false);
  const [showAccountColumn, setShowAccountColumn] = useState(true);
  const [showEdit, setShowEdit] = useState(true);

  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    selectedItem: "any",
    sort: tableState.sort,
    filter: tableState.filter,
  };

  useEffect(() => {
    if (responsiveViewPortTriggerMin) {
      setWidth(108);
      setStatusWidth(90);
    } else {
      setWidth(120);
      setStatusWidth(110);
    }
  }, [responsiveViewPortTriggerMin]);

  useEffect(() => {
    const { showEditCol, showPriceCol, showAccountCol } = getBbsProductTableColumnsSettings(isBaLoggedIn, selectedAccount, selectedAccountToFilterProductsForBA);
    setShowAccountColumn(showAccountCol);
    setShowEdit(showEditCol);
    setShowPriceColumns(showPriceCol);
  }, [isBaLoggedIn, selectedAccount, selectedAccountToFilterProductsForBA]);

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(productFamily.products, dataState as any),
  });

  useEffect(() => {
    const updatedBssProducts = productFamily.products.map(bbs => {
      const accName = getAccountNameForBbsOrder(bbs, mspAccounts, accountNames);
      if (bbs.effectiveDate) {
        let newEffectiveDate = new Date(bbs.effectiveDate);
        return {
          ...bbs,
          account: accName,
          effectiveDate: new Date(Date.UTC(newEffectiveDate.getFullYear(), newEffectiveDate.getMonth(), newEffectiveDate.getDate())),
          effectiveDateToDisplay: bbs.effectiveDate,
        };
      } else {
        return { ...bbs, account: accName };
      }
    });

    dispatch(setTablePropsForBBS({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip, filter: gridState.dataState.filter }));
    setGridState({ dataState: gridState.dataState, dataResult: process(updatedBssProducts, gridState.dataState as any) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState.dataState, productFamily.products, mspAccounts]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  useEffect(() => {
    if (selectedAccount !== undefined && selectedAccount != null) {
      if (currentlySelectedAccountId !== selectedAccount?.id) {
        setCurrentlySelectedAccountId(selectedAccount.id);
        if (selectedAccount.id !== 0 && currentlySelectedAccountId !== 0) {
          const ds = { ...gridState.dataState, skip: 0 };
          setGridState({
            dataState: ds,
            dataResult: process(productFamily.products, ds as any),
          });
          dispatch(setTablePropsForBBS({ skip: 0 }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelectedAccountId, gridState.dataState, selectedAccount, productFamily.products]);

  const dataStateChange = (e: any): void => {
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(productFamily.products, e.dataState),
    });
  };

  const enterEdit = (e: any): void => {
    if (selectedAccount) {
      openEditDialog(e, selectedAccount.type);
    }
  };

  useEffect(() => {
    if (gridState.dataState.filter === null) {
      gridState.dataState.filter = {
        logic: "and",
        filters: [],
      };
    }
    if (updatedStatuses.length > 0) {
      updatedStatuses.forEach((e: any) => {
        if (gridState.dataState.filter?.filters.filter((p: any) => p.logic === "or").length === 0 && e.show) {
          gridState.dataState.filter?.filters.push({
            logic: "or",
            filters: [
              {
                field: "bbsOrderStatus",
                operator: "eq",
                value: e.bbsOrderStatus,
              },
            ],
          });
        } else if (gridState.dataState.filter !== undefined) {
          if (gridState.dataState.filter.filters.length > 0 && e.show) {
            let index = gridState.dataState.filter?.filters.findIndex((h: any) => h.logic === "or");
            if (index >= 0) {
              let gridFilter = gridState.dataState.filter.filters[index] as any;
              gridFilter.filters.push({
                field: "bbsOrderStatus",
                operator: "eq",
                value: e.bbsOrderStatus,
              });
            }
          } else if (e.show === false && newStatuses.filter((g: any) => g.show).length > 0) {
            let index = gridState.dataState.filter?.filters.findIndex((t: any) => t.filters.find((x: any) => x.field === "bbsOrderStatus"));
            if (index >= 0) {
              let gridFilter = gridState.dataState.filter.filters[index] as any;
              let index2 = gridFilter.filters.findIndex((f: any) => f.value === e.bbsOrderStatus);
              if (index2 >= 0) {
                gridFilter.filters.splice(index2, 1);
              }
            }
          } else if (newStatuses.filter((g: any) => g.show === true).length === 0) {
            gridState.dataState.filter?.filters.pop();
          }
        }
      });

      let ds = { ...gridState.dataState, filter: gridState.dataState.filter };
      dispatch(setTablePropsForBBS({ filter: gridState.dataState.filter }));
      setGridState({ dataState: ds, dataResult: process(productFamily.products, ds as any) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFamily.products, newStatuses]);

  useEffect(() => {
    let stateFilter = tableState.filter?.filters.find((k: any) => k.logic === "or") as any;
    let newStateStatuses = [...newStatuses];
    if (stateFilter) {
      stateFilter.filters.forEach((l: any) => {
        newStateStatuses.forEach((o: any) => {
          if (l.value === o.bbsOrderStatus) {
            o.show = true;
          }
        });
      });
      if (JSON.stringify(newStateStatuses) !== JSON.stringify(newStatuses)) {
        setNewStatuses(newStateStatuses);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStatuses]);

  const onColumnsSubmit = (newSubmitStatuses: any, updatedStatus: any) => {
    setNewStatuses(newSubmitStatuses);
    setUpdatedStatuses(updatedStatus);
  };

  const handleOnReset = () => {
    gridState.dataState.filter?.filters.pop();
    let ds = { ...gridState.dataState, filter: gridState.dataState.filter };
    dispatch(setTablePropsForBBS({ filter: gridState.dataState.filter }));
    setGridState({ dataState: ds, dataResult: process(productFamily.products, ds as any) });
  };

  const TooltipTable = (props: any, tooltipType: any) => <TooltipElement {...props} tooltipType={"table"} />;
  const AccountName = (props: any) => <AccountIconsCell {...props} accountName={props.dataItem.account} serial={props.dataItem.serials && props.dataItem.serials.length > 0 ? props.dataItem.serials[0] : undefined} showAccountName={!serialHasRmaStatus(props.dataItem.serials[0])} />;
  const ProductName = (props: any) => <BBSProductNameCell {...props} />;
  const ProductPrice = (props: any) => <PriceCell {...props} amount={props.dataItem.price} currency={currency} />;
  const ProductIssueDate = (props: any) => <DateCell {...props} date={props.dataItem.effectiveDateToDisplay} />;
  const ProductStatus = (props: any) => <SerialStatusCell {...props} status={getStatus(props.dataItem)} isBBS={true} />;
  const EditProduct = (props: any) => <ButtonCommandCell {...props} dataTestId={"editProduct"} action={enterEdit} label={"EDIT"} isOutlined={false} color={"secondary"} disabled={isEditBBSDisabled(props.dataItem, mspAccounts, accountNames)} title={getEditBBSSerialTitle(props.dataItem, mspAccounts, accountNames)} />;
  const FilterStatusDropdown = (props: any) => <BBSFilterStatusDropdown {...props} statuses={newStatuses} onReset={handleOnReset} onColumnsSubmit={onColumnsSubmit} />;

  return (
    <div className="overflowTableColumn">
      <DataTable
        className={"noScrollbar noBorders products BarracudaBackupAppliances"}
        data={gridState.dataResult}
        resizable
        // page
        pageConfig={{
          pageable: {
            pageSizes: [10, 25, 50],
            buttonCount: buttonCount,
          },
          skip: gridState.dataState.skip,
          take: gridState.dataState.take,
          total: gridState.dataResult.total,
        }}
        // sort
        sortConfig={{
          sortable: true,
          sort: tableState.sort,
        }}
        pager={gridState.dataResult.data.length > 0 && Pager}
        filter={gridState.dataState.filter}
        expandField="expanded"
        onDataStateChange={dataStateChange}
        selectedField="selected"
        {...(gridState.dataState as any)}
        style={{ maxWidth: "100%" }}
      >
        <GridNoRecords>
          <div data-testid="loadingProducts">{loadingSerials ? <LinearProgress /> : "No records available"}</div>
        </GridNoRecords>
        <Column key={"bbsProductName"} title={"APPLIANCE NAME"} cell={ProductName} field={"nameAndModel"} filterable filter={"text"} minResizableWidth={30} columnMenu={filterTextInput} headerClassName={getColorForColumn("nameAndModel", gridState.dataState as State)} />
        {showAccountColumn && <Column key={"bbsProductAccount"} title={"ACCOUNT"} field={"account"} minResizableWidth={30} cell={AccountName} filterable filter={"text"} columnMenu={filterTextInput} headerClassName={getColorForColumn("account", gridState.dataState as State)} />}
        <Column key={"bbsProductSerial"} title={"SERIAL"} field={"serial"} minResizableWidth={30} cell={TooltipTable} filterable filter={"text"} columnMenu={filterTextInput} headerClassName={getColorForColumn("serial", gridState.dataState as State)} />
        {showPriceColumns && <Column key={"bbsPrice"} title={"PRICE"} cell={ProductPrice} minResizableWidth={30} field={"price"} filterable filter="numeric" columnMenu={filterTextInput} headerClassName={getColorForColumn("price", gridState.dataState as State)} />}
        {showPriceColumns && <Column key={"bbsShipDate"} title={"SHIP DATE"} cell={ProductIssueDate} minResizableWidth={30} field={"effectiveDate"} filterable filter="date" columnMenu={filterDateInput} headerClassName={getColorForColumn("effectiveDate", gridState.dataState as State)} />}
        <Column key={"bbsProductStatus"} title={"STATUS"} cell={ProductStatus} minResizableWidth={30} width={statusWidth} field={"bbsOrderStatus"} filterable filter={"text"} columnMenu={FilterStatusDropdown} headerClassName={getColorForColumn("bbsOrderStatus", gridState.dataState as State)} />
        {showEdit && <Column key={"BbsProductActions"} title={"ACTIONS"} cell={EditProduct} minResizableWidth={30} width={width} sortable={false} />}
      </DataTable>
    </div>
  );
};

export default BBSProductsTable;
