import React, { useState } from "react";
import { Button } from "@barracuda/bds-core";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import { getCountriesAction } from "../../actions/accountActions";
import { cancelCurrentAction, removeSnackBarMessage, setSnackBarMessage } from "../../actions/generalActions";
import CreditCardsDialog from "./CreditCardDialog";
import IApiCreditCard from "../../models/Invoices/IApiCreditCard";
import { addCreditCard, getCreditCards, setSelectedCreditCardAction, deleteCreditCard, editCreditCard, setLoadingCreditCards } from "../../actions/financeActions";
import IAccount from "../../models/IAccount";
import ActionMessageType from "../../models/ActionMessageType";
import IExistingCreditCard from "../../models/Invoices/IExistingCreditCard";
import DeleteCreditCard from "./DeleteCreditCard";

const CREDIT_CARD_ERROR = "The credit card provided could not be validated. This could be caused by the card being expired or inactive, or possibly insufficient funds. Please check that the credit card is in good standing and there are sufficient funds to complete the transaction and try again.";
const CREDIT_CARD_SUCCESS = "Credit Card successfully added";
const CREDIT_CARD_UPDATE = "Credit Card successfully updated";

interface IManageCreditCardsButton {
  disabled: boolean;
  isAddingFirstCard: boolean;
  onCancel: () => void;
  onAddFristNewCard: () => void;
  onOpenManageCreditCards: (isLoading: boolean) => void;
}

const ManageCreditCardsButton: React.FC<IManageCreditCardsButton> = ({ disabled, isAddingFirstCard, onAddFristNewCard, onCancel, onOpenManageCreditCards }) => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const countries = useSelector((state: IAppState) => state.accountState.countries);
  const loadingInvoices = useSelector((state: IAppState) => state.financeState.loadingInvoices);
  const loadingCreditCards = useSelector((state: IAppState) => state.financeState.loadingCreditCards);
  const loadingCreditCardsCanceled = useSelector((state: IAppState) => state.financeState.loadingCreditCardsCanceled);
  const selectedCreditCard = useSelector((state: IAppState) => state.financeState.selectedCreditCard);
  const [showManageCreditCard, setShowManageCreditCard] = useState(false);
  const [showDeleteCreditCard, setShowDeleteCreditCard] = useState(false);
  const [loadingManageCreditCard, setLoadingManageCreditCard] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const loadCountries = () =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(getCountriesAction());
      resolve(newAccountId);
    });

  const loadCreditCards = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const existingCreditCards = dispatch(getCreditCards(account));
      resolve(existingCreditCards);
    });

  const handleOpenManageCreditCards = () => {
    if (selectedAccount) {
      onOpenManageCreditCards(true);
      setLoadingManageCreditCard(true);
      loadCreditCards(selectedAccount).then(getCredtiCardsResult => {
        if (countries.length <= 0) {
          loadCountries().then(countriesResult => {
            setLoadingManageCreditCard(false);
            setShowManageCreditCard(!showManageCreditCard);
            onOpenManageCreditCards(false);
          });
        } else {
          setLoadingManageCreditCard(false);
          setShowManageCreditCard(!showManageCreditCard);
          onOpenManageCreditCards(false);
        }
      });
    }
  };

  const handleOnCancelShowManageCreditCard = () => {
    dispatch(removeSnackBarMessage());
    dispatch(setSelectedCreditCardAction(undefined));
    setShowManageCreditCard(false);
    if (isAddingFirstCard) {
      onCancel();
    }
    if (loadingCreditCards) {
      dispatch(cancelCurrentAction());
      dispatch(setLoadingCreditCards(false));
    }
  };

  const addCard = (account: IAccount, creditCard: IApiCreditCard) =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(addCreditCard(account.id, creditCard));
      resolve(newAccountId);
    });

  const editCard = (account: IAccount, currentCreditCard: IExistingCreditCard, creditCard: IApiCreditCard) =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(editCreditCard(account, currentCreditCard, creditCard));
      resolve(newAccountId);
    });

  const handleOnSubmitCard = (creditCard: IApiCreditCard | undefined, existingCreditCard: IExistingCreditCard | undefined) => {
    if (selectedAccount && creditCard) {
      setActionInProgress(true);
      if (selectedCreditCard) {
        editCard(selectedAccount, selectedCreditCard, creditCard)
          .then(result => {
            setActionInProgress(result);
            if (result) {
              setShowManageCreditCard(false);
              setActionInProgress(false);
              dispatch(setSnackBarMessage({ message: CREDIT_CARD_UPDATE, type: ActionMessageType.Success }));
              dispatch(setSelectedCreditCardAction(undefined));
            }
          })
          .catch(error => {
            setActionInProgress(false);
            dispatch(setSnackBarMessage({ message: CREDIT_CARD_ERROR, type: ActionMessageType.Error }));
            dispatch(setSelectedCreditCardAction(undefined));
          });
      } else {
        addCard(selectedAccount, creditCard)
          .then(result => {
            setActionInProgress(result);
            if (result) {
              setShowManageCreditCard(false);
              setActionInProgress(false);
              dispatch(setSnackBarMessage({ message: CREDIT_CARD_SUCCESS, type: ActionMessageType.Success }));
              if (isAddingFirstCard) {
                onAddFristNewCard();
              }
            }
          })
          .catch(error => {
            setActionInProgress(false);
            dispatch(setSnackBarMessage({ message: CREDIT_CARD_ERROR, type: ActionMessageType.Error }));
          });
      }
    }
  };

  const deleteCard = (account: IAccount, creditCard: IExistingCreditCard) =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(deleteCreditCard(account, creditCard));
      resolve(newAccountId);
    });

  const handleOnDeleteCreditCard = () => {
    if (selectedAccount && selectedCreditCard) {
      setDeleteInProgress(true);
      deleteCard(selectedAccount, selectedCreditCard).then(result => {
        dispatch(setSelectedCreditCardAction(undefined));
        setDeleteInProgress(false);
        setShowDeleteCreditCard(false);
      });
    }
  };

  const handleOnOpenDeleteDialog = () => {
    setShowDeleteCreditCard(true);
  };

  const handleOnCancelDeleteDialog = () => {
    setShowDeleteCreditCard(false);
  };

  return (
    <div>
      <Button data-testid="manageCreditCards" variant={"contained"} size={"large"} onClick={handleOpenManageCreditCards} isLoading={loadingManageCreditCard} disabled={disabled || loadingInvoices || loadingManageCreditCard || (loadingCreditCards && !loadingCreditCardsCanceled)} style={{ marginRight: "15px" }}>
        MANAGE CREDIT CARDS
      </Button>

      {(isAddingFirstCard || (showManageCreditCard && selectedAccount)) && <CreditCardsDialog showDialog={isAddingFirstCard || showManageCreditCard} onCancel={handleOnCancelShowManageCreditCard} onSubmit={handleOnSubmitCard} invoice={undefined} actionInProgress={actionInProgress} onDelete={handleOnOpenDeleteDialog} currency={""} />}
      {showDeleteCreditCard && selectedAccount && <DeleteCreditCard showDialog={showDeleteCreditCard} onCancel={handleOnCancelDeleteDialog} onDeleteCard={handleOnDeleteCreditCard} isActionInProgress={deleteInProgress} />}
    </div>
  );
};

export default ManageCreditCardsButton;
