import React, { useEffect, useState } from "react";
import { Typography, Card, CardContent, Link, TextField, LinearProgress } from "@barracuda/bds-core";
import Tooltip from "@barracuda/bds-core/dist/Tooltip";
import Grid from "@barracuda/bds-core/dist/Grid";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import AuditUsersTable from "../AuditUsers/AuditUsersTable";
import { clearAuditStateBeforeSearch, clearSearchAuditUserResultsAction, getBilledUsersAction, searchAuditUserAction, setSearchedAuditUserEmail } from "../../../actions/auditUsersActions";
import * as CoreIcons from "@barracuda/bds-core/dist/Icons/Core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { IconButton } from "@material-ui/core";
import { isStringNullOrEmpty } from "../../../utility";
import ImportCsv from "./ImportCsv";
import { MAX_NO_OF_BILLING_USERS_TO_IMPORT } from "../../../config";
import MspType from "../../../models/MspType";
import { fetchM365AuthAction } from "../../../actions/accountActions";

interface IAuditUsersCardProps {
  handleBackToProducts: () => void;
}

const AuditUsersCard: React.FC<IAuditUsersCardProps> = ({ handleBackToProducts }) => {
  const dispatch = useDispatch();
  const nextPageToken = useSelector((state: IAppState) => state.auditUsersState.nextPageToken);
  const searchedAuditUserEmail = useSelector((state: IAppState) => state.auditUsersState.searchedAuditUserEmail);
  const apiSearchedAuditUserEmail = useSelector((state: IAppState) => state.auditUsersState.apiSearchedAuditUserEmail);
  const loadingAuditUsers = useSelector((state: IAppState) => state.auditUsersState.loadingAuditUsers);
  const loadingAccountM365Auth = useSelector((state: IAppState) => state.accountState.loadingAccountM365Auth);
  const auditUserStateBeforeSearch = useSelector((state: IAppState) => state.auditUsersState.auditUserStateBeforeSearch);
  const noOfActionsInProgress = useSelector((state: IAppState) => state.auditUsersState.noOfActionsInProgress);
  const backToProductsAndServicesTitle = "< Back to Products & Services";
  const subTitle = "ANY CHANGES MADE HERE MAY TAKE UP TO 48 HOURS TO TAKE EFFECT";
  const [title, setTitle] = useState("User Billing List");
  const [showClearButton, setShowClearButton] = useState(false);
  const [searchedAuditUser, setSearchedAuditUser] = useState(searchedAuditUserEmail);
  const [tableMessage, setTableMessage] = useState("");
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const [table, setTable] = useState<JSX.Element>();

  const onHandleBackToProducts = (event: React.SyntheticEvent) => {
    event.preventDefault();
    handleBackToProducts();
    if (!isStringNullOrEmpty(auditUserStateBeforeSearch?.nextPageToken)) {
      dispatch(clearAuditStateBeforeSearch());
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      setTitle("User Billing List for");
      setShowClearButton(false);
      dispatch(clearAuditStateBeforeSearch());
      dispatch(getBilledUsersAction(selectedAccount));
      if (selectedAccount?.type === MspType.Customer) {
        dispatch(fetchM365AuthAction(selectedAccount));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount?.id]);

  useEffect(() => {
    if (!loadingAuditUsers) {
      if (searchedAuditUser && searchedAuditUser.length > 0 && apiSearchedAuditUserEmail !== searchedAuditUser) {
        if (auditUserStateBeforeSearch?.nextPageToken && auditUserStateBeforeSearch?.nextPageToken.length > 0) {
          dispatch(getBilledUsersAction(selectedAccount, searchedAuditUser));
        } else {
          dispatch(searchAuditUserAction(searchedAuditUser));
        }
        setShowClearButton(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAuditUsers, apiSearchedAuditUserEmail]);

  const getAuditUsersContent = (message: string): JSX.Element => {
    if (isStringNullOrEmpty(message)) {
      return <AuditUsersTable></AuditUsersTable>;
    } else {
      return (
        <Typography className="DarkRedColor" data-testid={"exceedsPaginationLimit"}>
          {message}
        </Typography>
      );
    }
  };

  useEffect(() => {
    setTable(getAuditUsersContent(tableMessage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableMessage]);

  const renderAuditUserClearButton = () => {
    let adornment = null;
    if (showClearButton === true) {
      adornment = (
        <InputAdornment position="end">
          <IconButton disabled={noOfActionsInProgress > 0} data-testid={"clearSearchAuditUsers"} onClick={onHandleClearSearch}>
            <CoreIcons.Close></CoreIcons.Close>
          </IconButton>
        </InputAdornment>
      );
    }
    return adornment;
  };
  const onHandleClearSearch = () => {
    onAuditUserSearched("");
    dispatch(setSearchedAuditUserEmail(""));
  };

  const onAuditUserSearched = (value: string) => {
    setSearchedAuditUser(value);
    const searchedValue = value.trim();
    dispatch(setSearchedAuditUserEmail(searchedValue));
    if (searchedValue.length > 0) {
      if (isStringNullOrEmpty(auditUserStateBeforeSearch?.nextPageToken)) {
        dispatch(searchAuditUserAction(searchedValue));
        setShowClearButton(true);
      } else if (!isStringNullOrEmpty(auditUserStateBeforeSearch?.nextPageToken)) {
        dispatch(getBilledUsersAction(selectedAccount, searchedValue));
        setShowClearButton(true);
      }
    } else {
      setShowClearButton(false);
      dispatch(clearSearchAuditUserResultsAction());
    }
  };

  useEffect(() => {
    setSearchedAuditUser(searchedAuditUserEmail);
    if (!isStringNullOrEmpty(searchedAuditUserEmail)) {
      setShowClearButton(true);
    }
  }, [searchedAuditUserEmail]);

  useEffect(() => {
    if (nextPageToken !== undefined) {
      let exceedsLimitMessage = "Protected User list is too large! Please filter Protected Users to refine the list.";
      if (nextPageToken.length > 0 && searchedAuditUser.length === 0) {
        setTableMessage(exceedsLimitMessage);
      } else if (nextPageToken.length > 0 && searchedAuditUser.length > 0) {
        setTableMessage(exceedsLimitMessage);
      } else {
        setTableMessage("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPageToken, searchedAuditUser]);

  return (
    <Card className={"AuditCard"}>
      <CardContent>
        <Grid item container spacing={1} direction="column" style={{ paddingBottom: "10px" }}>
          <Grid item container>
            <Grid container item xs={12}>
              <Grid container style={{ paddingBottom: "10px", paddingRight: "40px" }}>
                <Typography data-testid="auditUsersListCardTitle" variant="h4" noWrap style={{ display: "flex" }}>
                  {title}&nbsp;{" "}
                  <Tooltip title={selectedAccount ? selectedAccount.name : ""}>
                    <Typography variant="h4" noWrap>
                      {selectedAccount ? selectedAccount.name : ""}
                    </Typography>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Typography data-testid="auditUsersListCardSubtitle" variant="subtitle1">
                  {subTitle}
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Link
                  data-testid="auditUsersListCardLink"
                  href="#"
                  onClick={(event: React.SyntheticEvent) => {
                    onHandleBackToProducts(event);
                  }}
                  variant="body1"
                >
                  {backToProductsAndServicesTitle}
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} direction="row">
            <Grid className={"auditSearch"} item container xs={9} style={{ paddingBottom: "7px" }}>
              <TextField
                data-testid="searchAuditUserTxt"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CoreIcons.Filter />
                    </InputAdornment>
                  ),
                  endAdornment: renderAuditUserClearButton(),
                }}
                placeholder="Filter By Billable User"
                size={"large"}
                value={searchedAuditUser}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onAuditUserSearched(ev.target.value)}
                disabled={noOfActionsInProgress > 0}
              />
            </Grid>
            <Grid item xs={9} data-testid="auditUsersListTable">
              {noOfActionsInProgress > 0 && (
                <div style={{ display: "none" }} data-testid={"auditUsersActionInProgress"}>
                  <LinearProgress />
                </div>
              )}
              {loadingAuditUsers || loadingAccountM365Auth ? (
                <div data-testid={"loadingAuditUsersTab"}>
                  <LinearProgress />
                </div>
              ) : (
                table
              )}
            </Grid>
            <Grid item xs={3} justifyContent="space-around">
              <ImportCsv></ImportCsv>
              <Typography>Entries are case sensitive</Typography>
              <Typography>Limit: {MAX_NO_OF_BILLING_USERS_TO_IMPORT} entries maximum in .CSV</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AuditUsersCard;
