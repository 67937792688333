import React, { useEffect, useState } from "react";
import "../../../table.css";
import { DataTable, DataTableColumn as Column, LinearProgress } from "@barracuda/bds-core";
import { useDispatch, useSelector } from "react-redux";
import Pager from "@barracuda/bds-core/dist/DataTable/Pager";
import { useMediaQuery } from "@material-ui/core";
import { IAppState } from "../../../store/store";
import { getButtonCount } from "../../../utility";
import { getAccountExcludedUsers } from "../../../actions/financeActions";
import IInvoice from "../../../models/Invoices/IInvoice";
import { GridNoRecords } from "@progress/kendo-react-grid";
import IAccountsWithExclusions from "../../../models/Invoices/IAccountsWithExclusions";

interface IExcludedUsersTableProps {
  account: IAccountsWithExclusions;
  invoice: IInvoice;
}

const ExcludedUsersTable: React.FC<IExcludedUsersTableProps> = ({ account, invoice }) => {
  const dispatch = useDispatch();
  const loadingAccountsExcludedUsers = useSelector((state: IAppState) => state.financeState.loadingAccountsExcludedUsers);
  const accountExcludedUsers = useSelector((state: IAppState) => state.financeState.accountExcludedUsers);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const [buttonCount, setButtonCount] = useState(10);
  const [tableState, setTableState] = useState({ take: 10, skip: 0 });

  const dataState = {
    skip: tableState?.skip,
    take: tableState?.take,

    group: [],
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "EXCLUDED BILLABLE USER",
        field: "user",
        show: true,
        filter: "text",
        filtrable: true,
      },
    ],
  };
  const [gridState, setGridState] = useState({
    dataState,
    dataResult: { data: accountExcludedUsers, total: account.total },
  });

  useEffect(() => {
    setTableState({ take: gridState.dataState.take, skip: gridState.dataState.skip });
    setGridState({ dataState: gridState.dataState, dataResult: { data: accountExcludedUsers, total: account.total } });
  }, [account.total, accountExcludedUsers, gridState.dataState]);

  useEffect(() => {
    setButtonCount(getButtonCount(account.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [account.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const getaccountExcludedUsersPage = (take: number, skip: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getAccountExcludedUsers(account.accountId, invoice, skip, take));
      resolve(result);
    });

  const dataStateChange = (e: any): void => {
    getaccountExcludedUsersPage(e.dataState.take, e.dataState.skip).then(result => {
      if (result) {
        const newDataState = { ...dataState, skip: e.dataState.skip, take: e.dataState.take };
        setTableState({ take: gridState.dataState.take, skip: gridState.dataState.skip });
        setGridState({
          dataState: newDataState,
          dataResult: { data: result, total: account.total },
        });
      }
    });
  };

  return (
    <DataTable
      className={"InvoicesTable noScrollbar noBorders"}
      data={gridState.dataResult}
      resizable
      // page
      pageConfig={{
        pageable: {
          pageSizes: [10, 25, 50],
          buttonCount: buttonCount,
        },
        skip: tableState?.skip,
        take: tableState?.take,
        total: account.total,
      }}
      pager={gridState.dataResult.data.length > 0 && Pager}
      onDataStateChange={dataStateChange}
      selectedField="selected"
      {...(gridState.dataState as any)}
    >
      <GridNoRecords>
        {loadingAccountsExcludedUsers ? (
          <div data-testid="loadingAccountsExcludedUsers">
            <LinearProgress />
          </div>
        ) : (
          <div data-testid="noRecordsAccountExcludedUsers">No records available</div>
        )}
      </GridNoRecords>

      <Column key={"exclusionAccount"} field={"user"} title={"EXCLUDED BILLABLE USER"} minResizableWidth={30} resizable={false} />
    </DataTable>
  );
};

export default ExcludedUsersTable;
