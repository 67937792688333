import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography } from "@barracuda/bds-core";
import IntegrationsBillingTable from "./IntegrationsBillingTable";
import { getIntegrationBillingTypeNameToDisplay, IIntegrationBillingMapFamily, IntegrationBllingMapType } from "../../../models/Integrations/IntegrationsBillingMap";
import IntegrationsIbuBillingTable from "./IntegrationsIbuBillingTable";

interface IIntegrationsBillingAccordionProps {
  tableKey: any;
  integrationBillingMapFamily: IIntegrationBillingMapFamily;
  expanded: boolean;
  handleExpandBillingMaps: (integrationBillingMapFamilyType: IntegrationBllingMapType, isExpanded: boolean) => void;
}

const IntegrationsBillingAccordion: React.FC<IIntegrationsBillingAccordionProps> = ({ tableKey, integrationBillingMapFamily, expanded, handleExpandBillingMaps }) => {
  const handleChange = () => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    handleExpandBillingMaps(integrationBillingMapFamily.type, isExpanded);
  };

  return (
    <div style={{ paddingBottom: "10px" }}>
      <Accordion expanded={expanded} onChange={handleChange()}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div style={{ display: "inline-flex" }}>
            <Typography display="inline" style={{ fontWeight: "bold", paddingTop: "2px" }}>
              {getIntegrationBillingTypeNameToDisplay(integrationBillingMapFamily.type)}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>{expanded && (integrationBillingMapFamily.type === IntegrationBllingMapType.IBU_PLANS ? <IntegrationsIbuBillingTable key={tableKey} integrationBillingMapFamily={integrationBillingMapFamily} /> : <IntegrationsBillingTable key={tableKey} integrationBillingMapFamily={integrationBillingMapFamily} />)}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default IntegrationsBillingAccordion;
