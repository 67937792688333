import { State } from "@progress/kendo-data-query";
import { CancelTokenSource } from "axios";
import { Reducer } from "redux";
import { AuditUsersActions, AuditUsersActionsTypes } from "../actions/auditUsersActions";
import IAuditUser from "../models/Products/IAuditUser";
import IAuditUserDisplayState from "../models/Products/IAuditUserDisplayState";
import IAuditUserImportAction from "../models/Products/IAuditUserImportAction";

export interface IAuditUsersState {
  auditUsersToDisplay: IAuditUser[];
  loadingAuditUsers: boolean;
  loadAuditUsersCancellationTokenSource?: CancelTokenSource;
  auditUsersTableState: State;
  auditUserStateBeforeSearch?: IAuditUserDisplayState;
  nextPageToken: string;
  searchedAuditUserEmail: string;
  apiSearchedAuditUserEmail: string;
  noOfActionsInProgress: number;
  importActions: IAuditUserImportAction[];
}

const initialProductState: IAuditUsersState = {
  auditUsersToDisplay: [],
  loadingAuditUsers: false,
  auditUsersTableState: { sort: [{ field: "user", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } },
  auditUserStateBeforeSearch: undefined,
  nextPageToken: "",
  searchedAuditUserEmail: "",
  apiSearchedAuditUserEmail: "",
  noOfActionsInProgress: 0,
  importActions: [],
};

export const auditUsersReducer: Reducer<IAuditUsersState, AuditUsersActions> = (state = initialProductState, action: AuditUsersActions) => {
  switch (action.type) {
    case AuditUsersActionsTypes.GET_AUDIT_USERS: {
      return {
        ...state,
        auditUsersToDisplay: action.auditUsersToDisplay,
        loadingAuditUsers: action.loadingAuditUsers,
        nextPageToken: action.nextPageToken,
        apiSearchedAuditUserEmail: action.apiSearchedAuditUserEmail,
      };
    }
    case AuditUsersActionsTypes.GET_AUDIT_USERS_TABLE_PROPS: {
      return {
        ...state,
        auditUsersTableState: action.tableState,
      };
    }
    case AuditUsersActionsTypes.SET_CANCEL_LOAD_AUDIT_USERS_TOKEN: {
      return {
        ...state,
        loadAuditUsersCancellationTokenSource: action.loadAuditUsersCancellationTokenSource,
      };
    }
    case AuditUsersActionsTypes.SET_AUDIT_DISPLAY_ITEMS: {
      return {
        ...state,
        auditUsersToDisplay: action.auditUsersToDisplay,
      };
    }
    case AuditUsersActionsTypes.SAVE_AUDIT_DISPLAY_STATE: {
      return {
        ...state,
        auditUserStateBeforeSearch: action.auditUserStateBeforeSearch,
      };
    }
    case AuditUsersActionsTypes.SET_LOADING_AUDIT_USERS: {
      return {
        ...state,
        loadingAuditUsers: action.loadingAuditUsers,
      };
    }
    case AuditUsersActionsTypes.SET_SEARCHED_AUDIT_USER_EMAIL: {
      return {
        ...state,
        searchedAuditUserEmail: action.searchedAuditUserEmail,
      };
    }
    case AuditUsersActionsTypes.SET_NO_OF_ACTIONS_IN_PROGRESS: {
      return {
        ...state,
        noOfActionsInProgress: action.noOfActionsInProgress,
      };
    }
    case AuditUsersActionsTypes.ADD_IMPORT_AUDIT_USERS_EXCLUSION_FILE:
    case AuditUsersActionsTypes.REMOVE_FINISHED_IMPORT_AUDIT_USERS_EXCLUSION_FILE: {
      return {
        ...state,
        importActions: action.importActions,
      };
    }
    default:
      return state;
  }
};
