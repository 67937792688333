import React, { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from "@barracuda/bds-core";
import IGroupedDropDownSuboption from "../models/IGroupedDropDownSuboption";
import { CurrentProductSelectionContext } from "./CurrentProductSelectionContext";
import { NewProductBundles } from "../Utilities/NewProductBundles";

interface ISelectProps {
  label: string;
  options: Record<string, IGroupedDropDownSuboption[]>[];
  selectedOptionId: string;
  error: string;
  handleChange: (value: string) => void;
  onOpen?: (isOpen: boolean) => void;
  disabled: boolean;
}

interface ISelectItem {
  value: string;
  label: string;
  idx: number;
  isSelected: boolean;
  isDisabled: boolean;
  isGroup: boolean;
}

const GroupedDropDown: React.FC<ISelectProps> = ({ label, options, selectedOptionId, error, handleChange, disabled, onOpen }) => {
  const [dropdownRef, setDropdownRef] = useState<any>();
  const defaultOption = {
    value: "--Select--",
    label: "--Select--",
    idx: 0,
    isSelected: false,
    isDisabled: true,
    isGroup: true,
  };
  const [availableOptions, setAvailableOptions] = useState([defaultOption]);
  const [selectedObject, setSelectedObject] = useState<any>(defaultOption);
  const currentProduct = useContext(CurrentProductSelectionContext);
  const bundles = new NewProductBundles();

  useEffect(() => {
    let isCurrentNewBundle = bundles.isNewBundle(currentProduct?.sku);

    let loOp: ISelectItem[] = [];
    let index = 1;
    options.forEach((op: Record<string, IGroupedDropDownSuboption[]>) => {
      const groupName = Object.getOwnPropertyNames(op).toString();
      loOp.push({
        value: groupName,
        label: groupName,
        idx: -1 * index,
        isSelected: false,
        isDisabled: true,
        isGroup: true,
      });
      op[groupName].forEach(subOp => {
        loOp.push({
          value: subOp.id,
          label: subOp.label,
          idx: index++,
          isSelected: false,
          isDisabled: isCurrentNewBundle && !bundles.isNewBundle(subOp.bundleSku),
          isGroup: false,
        });
      });
    });
    setAvailableOptions([defaultOption, ...loOp]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    const selProd = availableOptions.filter(x => x.value === selectedOptionId)[0];
    if (selProd) {
      setSelectedObject(selProd);
    } else {
      setSelectedObject(availableOptions[0]);
    }
  }, [availableOptions, selectedOptionId]);

  const onHandleStateChange: any = (_e: any, newValue: any) => {
    if (newValue.props.disabled) {
      _e.preventDefault();
      return;
    }
    if (selectedObject) {
      selectedObject.isSelected = !selectedObject.isSelected;
    }
    handleChange(newValue.props.value);
  };

  useEffect(() => {
    if (onOpen) {
      if (dropdownRef) {
        onOpen(true);
      } else {
        onOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  return (
    <TextField select required label={label} disabled={disabled} value={selectedObject?.value} onChange={onHandleStateChange} helperText={error} error={error.length > 0} variant="outlined" size={"small"} fullWidth>
      {availableOptions.map(element => {
        return (
          <MenuItem
            className={element.isGroup ? "" : "groupedDropDownAvailableOptions"}
            key={element.idx}
            value={element.value}
            size={"small"}
            disabled={element.isDisabled}
            ref={(menuElement: any) => {
              setDropdownRef(menuElement);
            }}
          >
            {element.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default GroupedDropDown;
