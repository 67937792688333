import { CircularProgress, Grid } from "@barracuda/bds-core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadSubPartnerChildren, setSelectedAccountToFilterProductsForBA } from "../../actions/accountActions";
import { getIBUStatusAction, getProductsAction } from "../../actions/productActions";
import IAccount from "../../models/IAccount";
import MspType from "../../models/MspType";
import { IAppState } from "../../store/store";
import { getCurrentlySelectedPairForProductFiltering, shouldDisplayFilterDropdowns } from "../../Utilities/productsHelper";
import DropDown from "../DropDown";
import CheckM365StatusButton from "./CheckM366Status/CheckM365StatusButton";
import ProductFamily from "../../models/Products/ProductFamily";

const ProductFilters: React.FC = () => {
  const dispatch = useDispatch();
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const mspCustomers = useSelector((state: IAppState) => state.accountState.customers);
  const mspSubpartners = useSelector((state: IAppState) => state.accountState.subpartners);
  const loadingSubPartnerAccounts = useSelector((state: IAppState) => state.accountState.loadingSubPartnerAccounts);
  const selectedAccountToFilterProductsForBA = useSelector((state: IAppState) => state.accountState.selectedAccountToFilterProductsForBA);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const loadingPartnerAccounts = useSelector((state: IAppState) => state.accountState.loadingPartnerAccounts);
  const productsToDisplay = useSelector((state: IAppState) => state.productState.productsToDisplay);
  const [selectedSubpartnerId, setSelectedSubpartnerId] = useState(0);
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [subpartners, setSubpartners] = useState<IAccount[]>([]);
  const [customers, setCustomers] = useState<IAccount[]>([]);
  const [shouldDisplayFilterByChildAccount, setShouldDisplayFilterByChildAccount] = useState(false);
  const [showCheckM356Button, setShowCheckM356Button] = useState(false);

  useEffect(() => {
    setSubpartners(mspSubpartners ? [...mspSubpartners] : []);
  }, [mspSubpartners]);

  useEffect(() => {
    setCustomers(mspCustomers ? [...mspCustomers] : []);
  }, [mspCustomers]);

  useEffect(() => {
    if (selectedAccountToFilterProductsForBA && selectedAccountToFilterProductsForBA.type !== MspType.Partner) {
      const { currentlySelectedCustomerId, currentlySelectedSubpartnerId } = getCurrentlySelectedPairForProductFiltering(selectedAccountToFilterProductsForBA, selectedAccount);
      let custs = customers;
      if (currentlySelectedSubpartnerId > 0 && subpartners && subpartners.length > 0) {
        const subpartnerIndex = subpartners.findIndex((x: IAccount) => x.id === currentlySelectedSubpartnerId);
        if (subpartnerIndex > -1) {
          setSelectedSubpartnerId(subpartnerIndex + 1);
          custs = subpartners[subpartnerIndex].accounts;
          setCustomers(custs);
        }
      }
      if (currentlySelectedCustomerId > 0 && custs && custs.length > 0) {
        const customerIndex = custs.findIndex((x: IAccount) => x.id === currentlySelectedCustomerId);
        if (customerIndex > -1) {
          setSelectedCustomerId(customerIndex + 1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountToFilterProductsForBA, selectedAccount, customers, subpartners]);

  useEffect(() => {
    if (selectedAccountToFilterProductsForBA && selectedAccountToFilterProductsForBA.type !== MspType.Partner) {
      dispatch(getProductsAction(selectedAccountToFilterProductsForBA));
      dispatch(getIBUStatusAction(selectedAccountToFilterProductsForBA));
    } else {
      dispatch(getProductsAction(selectedAccount));
      dispatch(getIBUStatusAction(selectedAccount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountToFilterProductsForBA, selectedAccount]);

  useEffect(() => {
    if (selectedAccount) {
      if (selectedAccountToFilterProductsForBA && selectedAccountToFilterProductsForBA.type !== MspType.Partner) {
        if (selectedAccountToFilterProductsForBA.type === MspType.Subpartner) {
          if (subpartners && subpartners.length > 0) {
            const subpartnerIndex = subpartners.findIndex((x: IAccount) => x.id === selectedAccountToFilterProductsForBA.id);
            if (subpartnerIndex > -1) {
              setSelectedSubpartnerId(subpartnerIndex + 1);
              const selectedSubpartner = subpartners[subpartnerIndex];
              setSelectedCustomerId(0);
              setCustomers(selectedSubpartner.accounts);
            } else {
              setSelectedSubpartnerId(0);
              setSelectedCustomerId(0);
              setCustomers(mspCustomers ? [...mspCustomers] : []);
            }
          }
        } else {
          if (customers && customers.length > 0) {
            const customerIndex = customers.findIndex((x: IAccount) => x.id === selectedAccountToFilterProductsForBA.id);
            if (customerIndex > -1) {
              setSelectedCustomerId(customerIndex + 1);
              const selectedCustomer = customers[customerIndex];
              dispatch(setSelectedAccountToFilterProductsForBA(selectedCustomer));
            }
          }
        }
      } else {
        setSelectedCustomerId(0);
        setSelectedSubpartnerId(0);
        setCustomers(mspCustomers ? [...mspCustomers] : []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountToFilterProductsForBA, mspSubpartners, mspCustomers, selectedAccount]);

  const loadSubpartChildren = (subpart: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(loadSubPartnerChildren(subpart));
      resolve(result);
    });

  const onChangedSelectedSubpartner = (selectedId: number) => {
    if (selectedId > 0) {
      setSelectedSubpartnerId(selectedId);
      const selectedSubpartner = subpartners[selectedId - 1];
      dispatch(setSelectedAccountToFilterProductsForBA(selectedSubpartner));
      loadSubpartChildren(selectedSubpartner).then(result => {
        setCustomers(result);
      });
    } else {
      setSelectedSubpartnerId(0);
      dispatch(setSelectedAccountToFilterProductsForBA(selectedAccount));
    }
  };

  const onChangedSelectedCustomer = (selectedId: number) => {
    if (selectedId > 0) {
      setSelectedCustomerId(selectedId);
      const selectedCustomer = customers[selectedId - 1];
      dispatch(setSelectedAccountToFilterProductsForBA(selectedCustomer));
    } else {
      setSelectedCustomerId(0);
      if (selectedSubpartnerId > 0) {
        const selectedSubpartner = subpartners[selectedSubpartnerId - 1];
        dispatch(setSelectedAccountToFilterProductsForBA(selectedSubpartner));
      } else {
        dispatch(setSelectedAccountToFilterProductsForBA(selectedAccount));
      }
    }
  };

  useEffect(() => {
    setShouldDisplayFilterByChildAccount(shouldDisplayFilterDropdowns(mspAccountLoggedIn, mspCustomers, mspSubpartners));
  }, [mspAccountLoggedIn, mspCustomers, mspSubpartners]);

  useEffect(() => {
    if (selectedAccountToFilterProductsForBA && selectedAccountToFilterProductsForBA.type === MspType.Customer) {
      let hasEss = false;
      productsToDisplay.forEach(element => {
        if (element.productType === ProductFamily.ESSENTIALS_SERIVICES) {
          hasEss = true;
        }
      });
      setShowCheckM356Button(hasEss);
    } else {
      setShowCheckM356Button(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountToFilterProductsForBA, selectedAccount, productsToDisplay]);

  return shouldDisplayFilterByChildAccount ? (
    <Grid container item xs={12} style={{ justifyContent: "center" }} spacing={3}>
      {subpartners.length > 0 && (
        <Grid item xs={4}>
          <div data-testid="subpartnersDropdown">
            <DropDown
              required={false}
              firstOption={"-- Show for all -- "}
              label={"Filter by MSP Subpartner"}
              options={subpartners.map(x => x.name)}
              selectedOptionId={selectedSubpartnerId}
              error={""}
              handleChange={onChangedSelectedSubpartner}
              disabled={loadingPartnerAccounts}
              onOpen={() => {
                /*nothing*/
              }}
            />
          </div>
        </Grid>
      )}
      {loadingSubPartnerAccounts ? (
        <Grid item container xs={4} style={{ alignItems: "end", paddingTop: "23px" }}>
          <CircularProgress data-testid="loadingCustomersDropdown" />
        </Grid>
      ) : (
        customers?.length > 0 && (
          <Grid item xs={4}>
            <div data-testid="customersDropdown">
              <DropDown
                required={false}
                firstOption={"-- Show for all -- "}
                label={"Filter by MSP Account"}
                options={customers?.map(x => x.name)}
                selectedOptionId={selectedCustomerId}
                error={""}
                handleChange={onChangedSelectedCustomer}
                disabled={loadingPartnerAccounts}
                onOpen={() => {
                  /*nothing*/
                }}
              />
            </div>
            <Grid item>{showCheckM356Button && <CheckM365StatusButton accountId={selectedAccountToFilterProductsForBA?.id} />}</Grid>
          </Grid>
        )
      )}
    </Grid>
  ) : null;
};

export default ProductFilters;
