import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@barracuda/bds-core";
import Grid from "@barracuda/bds-core/dist/Grid";
import { getPercentageSubpartnersLoadedProgress } from "../utility";

interface IMspUILoadingAppProgress {
  noOfLoadedSubpartners: number;
  noOfSubpartners: number;
}

const MspUILoadingAppProgress: React.FC<IMspUILoadingAppProgress> = ({ noOfLoadedSubpartners, noOfSubpartners }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(getPercentageSubpartnersLoadedProgress(noOfLoadedSubpartners, noOfSubpartners));
  }, [noOfLoadedSubpartners, noOfSubpartners]);

  const renderProgressPercentage = (progressPercentage: number) => {
    if (progressPercentage === 0) {
      return null;
    } else {
      return (
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(progressPercentage)}%`}
        </Typography>
      );
    }
  };

  return (
    <Grid className={"MspUILoadingAppProgress"} style={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress data-testid="loadingAccounts" size="150px" variant={progress === 0 ? "indeterminate" : "determinate"} value={progress} />
      <Grid
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {renderProgressPercentage(progress)}
      </Grid>
    </Grid>
  );
};

export default MspUILoadingAppProgress;
