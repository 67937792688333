export const getProductStatusIconTooltip = (hasPendingSerials: boolean | undefined, hasSerials: boolean, noOfErrors: number, showAvailableIcon: boolean, noOfRmaSerials: number): string => {
  if (!hasSerials && showAvailableIcon) {
    return "Your service is available to activate to an account";
  } else if (noOfErrors > 0) {
    return "Cannot communicate with one or more of your services. Please retry activation or contact support for assistance";
  } else if (noOfRmaSerials > 0) {
    return `One or more of your appliances requires a manual data wipe to restore it to an 'Available' state`;
  } else if (hasPendingSerials) {
    return "Activating one or more of your services. This may take several minutes";
  } else {
    return "Activated";
  }
};
