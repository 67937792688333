import { DialogTitle, DialogActions, Button, DialogContent, Dialog } from "@barracuda/bds-core";
import { Typography } from "@material-ui/core";
import React from "react";
import IAuditUserImportAction from "../../../models/Products/IAuditUserImportAction";
import { enterKey } from "../../../utility";

interface ISucessImportMesageProps {
  onClose: (accountId: number) => void;
  isActionInProgress: boolean;
  showExportDialog: boolean;
  finishedAction: IAuditUserImportAction;
}
const SucessImportMesage: React.FC<ISucessImportMesageProps> = ({ showExportDialog, isActionInProgress, onClose, finishedAction }) => {
  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onClose)} disableEscapeKeyDown={isActionInProgress} className="importCsvDialog" data-testid="importSuccessfullDialog" disableBackdropClick={true} open={showExportDialog} onClose={onClose}>
      <DialogTitle data-testid="ExportTitle"> </DialogTitle>
      <DialogContent>
        <Typography variant={"body1"}>
          Successfully excluded users from file <b>{finishedAction.csvFileName}</b> for account <b>{finishedAction.account.name}</b>.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button data-testid="closeSuccessImportMessage" primary="true" size="large" onClick={() => onClose(finishedAction.account.id)}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SucessImportMesage;
