import React from "react";
import { TextField } from "@barracuda/bds-core";
import Grid from "@barracuda/bds-core/dist/Grid";

import { getAccountTypeFromValue } from "../../../Utilities/accountsHelper";

interface IAddEditAccountDisplayNameProps {
  accountName: string;
  accountNameError: string;
  title: string;
  isActionInProgress: boolean;
  onAccountNameChanged: (value: string) => void;
}

const AddEditAccountDisplayName: React.FC<IAddEditAccountDisplayNameProps> = ({ accountName, accountNameError, title, isActionInProgress, onAccountNameChanged }) => {
  return (
    <Grid container item xs={12}>
      <Grid item xs={11} container direction="column" style={{ padding: "10px" }}>
        <TextField
          size={"small"}
          data-testid="accountName"
          label={getAccountTypeFromValue(title) + " DISPLAY NAME"}
          value={accountName}
          error={accountNameError.length > 0}
          required
          disabled={isActionInProgress}
          helperText={accountNameError}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onAccountNameChanged(ev.target.value)}
          inputProps={{
            maxLength: 64,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AddEditAccountDisplayName;
