import React from "react";
import StatusIcon from "./ProductStatusIcon";

const ProductStatusIconCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }

  return (
    <td>
      <StatusIcon hasPendingSerials={props.dataItem.hasPendingSerials} hasSerials={props.dataItem.serials.length > 0} noOfErrors={props.dataItem.noOfErrors} showActivatedIcon={true} showAvailableIcon={true} noOfRmaSerials={0}></StatusIcon>
    </td>
  );
};

export default ProductStatusIconCell;
