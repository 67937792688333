import SerialStatus from "../../../models/Products/SerialStatus";

export const getSerialStatusIconTooltip = (status: string, isBBS: boolean): string => {
  const currentProduct: string = isBBS ? "appliance" : "service";
  switch (status) {
    case SerialStatus.ACTIVE:
      return "Activated";
    case SerialStatus.AVAILABLE:
      return `Your appliance is available to activate to an account`;
    case SerialStatus.ACTIVATION_FAILED:
    case SerialStatus.PROVISION_FAILED:
      return `Cannot communicate with ${currentProduct}. Please retry activation or contact support for assistance`;
    case SerialStatus.RMA_NEEDED:
    case SerialStatus.RMA_ISSUED:
    case SerialStatus.RMA_REQUESTED:
      return `This ${currentProduct} requires a manual data wipe to restore it to an 'Available' state`;
    case SerialStatus.SSG_PENDING:
    case SerialStatus.PENDING:
      return `Activating ${currentProduct}. This may take several minutes`;
    default:
      return "";
  }
};
