import React, { useEffect, useState } from "react";
import { DataTable, DataTableColumn as Column, LinearProgress } from "@barracuda/bds-core";
import { process } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import "../../../table.css";
import Pager from "@barracuda/bds-core/dist/DataTable/Pager";
import { IAppState } from "../../../store/store";
import { GridNoRecords } from "@progress/kendo-react-grid";
import AuditUser from "./AuditUser";
import ProductLicense from "./ProductLicense";
import { addAccountExclusionAction, deleteAccountExclusionAction, setAuditStateBeforeSearch, setAuditUsersTableProps } from "../../../actions/auditUsersActions";
import IAuditUser from "../../../models/Products/IAuditUser";
import IAccount from "../../../models/IAccount";
import { updatedAuditUserToDisplayBeforeSearch } from "../../../Utilities/auditUsersHelper";
import { isStringNullOrEmpty, getButtonCount } from "../../../utility";
import IAuditUserImportAction from "../../../models/Products/IAuditUserImportAction";
import { useMediaQuery } from "@material-ui/core";
import CheckBoxCommandCell from "../../CheckBoxCommandCell";
import produce from "immer";
import ProductLicensesDialog from "./ProductLicensesDialog";
import { fetchProductLicensesAction } from "../../../actions/accountActions";
import IProductLicenseList from "../../../models/ProductLicenseList";

const AuditUsersTable: React.FC = () => {
  const dispatch = useDispatch();
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const items = useSelector((state: IAppState) => state.auditUsersState.auditUsersToDisplay);
  const loadingAuditUsers = useSelector((state: IAppState) => state.auditUsersState.loadingAuditUsers);
  const tableState = useSelector((state: IAppState) => state.auditUsersState.auditUsersTableState);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const auditUserStateBeforeSearch = useSelector((state: IAppState) => state.auditUsersState.auditUserStateBeforeSearch);
  const searchedAuditUserEmail = useSelector((state: IAppState) => state.auditUsersState.searchedAuditUserEmail);
  const [currentlySelectedAccountId, setCurrentlySelectedAccountId] = useState(0);
  const [buttonCount, setButtonCount] = useState(10);
  const importActions = useSelector((state: IAppState) => state.auditUsersState.importActions);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [showProductLicensesDialog, setShowProductLicensesDialog] = useState(false);
  const [userName, setUserName] = useState("");
  const [productLicenseList, setProductLicenseList] = useState<IProductLicenseList>();
  const [initialProdLicenseLoading, setInitialProdLicenseLoading] = useState(false);

  useEffect(() => {
    if (selectedAccount) {
      if (importActions.length > 0) {
        const accountActionIndex = importActions.findIndex((x: IAuditUserImportAction) => x.account.id === selectedAccount?.id);
        if (accountActionIndex > -1) {
          setIsLoadingCsv(true);
        } else {
          setIsLoadingCsv(false);
        }
      } else {
        setIsLoadingCsv(false);
      }
    }
  }, [importActions, selectedAccount]);

  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    sort: tableState.sort,
    group: [],
    filter: tableState.filter,
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "user",
        field: "user",
        show: true,
        filter: "text",
      },
      {
        title: "productLicense",
        field: "productLicense",
        show: true,
        filter: "text",
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(items, dataState as any),
  });

  const dataStateChange = (e: any): void => {
    if (items.length > 0) {
      console.log(process(items, e.dataState));
      setGridState({
        dataState: { ...dataState, ...e.dataState },
        dataResult: process(items, e.dataState),
      });
    }
  };

  useEffect(() => {
    if (items && isStringNullOrEmpty(searchedAuditUserEmail)) {
      dispatch(setAuditUsersTableProps({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip, filter: gridState.dataState.filter }));
      setGridState({ dataState: gridState.dataState, dataResult: process(items, gridState.dataState as any) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState.dataState, items]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  useEffect(() => {
    if (items) {
      const ds = { ...gridState.dataState, skip: tableState.skip, take: tableState.take, sort: tableState.sort };
      setGridState({ dataState: ds, dataResult: process(items, ds as any) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedAuditUserEmail, items]);

  useEffect(() => {
    if (selectedAccount !== undefined && selectedAccount != null) {
      if (currentlySelectedAccountId !== selectedAccount?.id) {
        setCurrentlySelectedAccountId(selectedAccount.id);
        if (selectedAccount.id !== 0) {
          const ds = { ...gridState.dataState, skip: 0 };
          setGridState({
            dataState: ds,
            dataResult: process(items, ds as any),
          });
        }
      }
    }
  }, [currentlySelectedAccountId, gridState.dataState, selectedAccount, items]);

  const addAuditUsers = (account: IAccount, current: IAuditUser) =>
    new Promise<any>((resolve, reject) => {
      const addAction = dispatch(addAccountExclusionAction(account, [current.user]));
      resolve(addAction);
    });

  const deleteAuditUsers = (account: IAccount, current: IAuditUser) =>
    new Promise<any>((resolve, reject) => {
      const deleteAction = dispatch(deleteAccountExclusionAction(account, current.user));
      resolve(deleteAction);
    });

  const processAddDeleteAuditUser = (success: boolean, currentAuditUser: IAuditUser, reject: boolean) => {
    if (success) {
      const newAuditUserStateBeforeSearch = updatedAuditUserToDisplayBeforeSearch(currentAuditUser, auditUserStateBeforeSearch);
      if (newAuditUserStateBeforeSearch) {
        dispatch(setAuditStateBeforeSearch(newAuditUserStateBeforeSearch));
      }
    } else {
      setExcludeStatus(currentAuditUser, reject);
    }
  };

  const selectionChanged = (current: IAuditUser) => {
    if (selectedAccount) {
      if (current.isExcluded) {
        addAuditUsers(selectedAccount, current).then(success => {
          processAddDeleteAuditUser(success, current, false);
        });
      } else {
        deleteAuditUsers(selectedAccount, current).then(success => {
          processAddDeleteAuditUser(success, current, true);
        });
      }
    }
  };

  const setExcludeStatus = (dataItem: any, checked: boolean) => {
    const data = [...items];
    const current = data.findIndex(item => item.user === dataItem.user);
    const newData = produce(data, draft => {
      draft[current].isExcluded = checked;
    });
    return newData[current];
  };

  const handleSelectionChanged = (dataItem: any, checked: boolean) => {
    let updatedAuditUser = setExcludeStatus(dataItem, checked);
    selectionChanged(updatedAuditUser);
  };

  const handleOnCancelProductLicensesDialog = () => {
    setShowProductLicensesDialog(false);
  };

  const fetchProductLicenseList = (user: string) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(fetchProductLicensesAction(selectedAccount, user));
      resolve(result);
    });

  const handleOnShowProductLicensesDialog = (dataItem: any) => {
    setUserName(dataItem.user);
    setInitialProdLicenseLoading(true);
    setShowProductLicensesDialog(true);
    fetchProductLicenseList(dataItem.user)
      .then(result => {
        if (result) {
          setProductLicenseList(result);
        }
      })
      .catch(async (error: Error) => {
        console.log(error.message);
      })
      .finally(() => {
        setInitialProdLicenseLoading(false);
      });
  };

  const ExcludeUserCheckBoxCell = (props: any) => <CheckBoxCommandCell {...props} disabled={props.dataItem.isDisabled || isLoadingCsv} checked={props.dataItem.isExcluded} isLoading={props.dataItem.isDisabled} onChange={handleSelectionChanged} />;
  const AuditUserCell = (props: any) => <AuditUser {...props} checked={props.dataItem.isExcluded} onChange={handleSelectionChanged} />;
  const ProductLicenseCell = (props: any) => <ProductLicense {...props} onLinkClick={handleOnShowProductLicensesDialog} />;

  return (
    <div>
      <DataTable
        className={"auditUserTable noScrollbar noBorders"}
        data={gridState.dataResult}
        resizable
        filter={gridState.dataState.filter}
        // page
        pageConfig={{
          pageable: {
            pageSizes: [10, 25, 50],
            buttonCount: buttonCount,
          },
          skip: tableState.skip,
          take: tableState.take,
          total: gridState.dataResult.total,
        }}
        // sort
        sortConfig={{
          sortable: true,
          sort: tableState.sort,
        }}
        pager={gridState.dataResult.data.length > 0 && Pager}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        {...(gridState.dataState as any)}
      >
        <GridNoRecords>
          {loadingAuditUsers ? (
            <div data-testid="loadingAuditUsers">
              <LinearProgress />
            </div>
          ) : (
            <div data-testid="noRecordsAuditUsers">No records available</div>
          )}
        </GridNoRecords>
        <Column key={"auditUserCheckBox"} cell={ExcludeUserCheckBoxCell} field={"isExcluded"} title={"EXCLUDE"} width="100px" />
        <Column key={"auditUserEmail"} cell={AuditUserCell} field={"user"} title={"BILLABLE USER"} />
        {selectedAccount?.m365AuthLinked && selectedAccount?.m365AuthLinked === 1 && <Column key={"auditUserProductLicense"} cell={ProductLicenseCell} sortable={false} field={"productLicense"} title={"BILLABLE LICENSE(S)"} />}
      </DataTable>
      {showProductLicensesDialog && <ProductLicensesDialog showDialog={showProductLicensesDialog} onCancel={handleOnCancelProductLicensesDialog} userName={userName} account={selectedAccount} initLoading={initialProdLicenseLoading} productLicenseList={productLicenseList!} />}
    </div>
  );
};

export default AuditUsersTable;
