import React from "react";
import Grid from "@barracuda/bds-core/dist/Grid";
import MspType from "../../../models/MspType";
import AccountIcon from "../../Accounts/AccountIcon";

const AccountWithExclusionsIcon = (props: any) => {
  return (
    <td>
      <Grid container alignItems="center" onClick={() => props.onClick(props.dataItem)}>
        <AccountIcon entityType={MspType.Customer} />
      </Grid>
    </td>
  );
};

export default AccountWithExclusionsIcon;
