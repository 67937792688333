enum UserRole {
  Unknown = -1,
  Admin = 0,
  Finance = 1,
  Tech = 2,
  TechDoNotDelete = 3,
  ReadOnly = 4,
  BillingAggregatorAdmin = 5,
  BillingAggregatorFinance = 6,
  BillingAggregatorTech = 7,
}

export interface IUserRoleValue {
  value: number;
  authDbValue: string;
  label: string;
}

export const UserRoleValues: IUserRoleValue[] = [
  { value: UserRole.Admin, authDbValue: "msp.partneradmin", label: "Admin" },
  { value: UserRole.Finance, authDbValue: "msp.finance", label: "Finance" },
  { value: UserRole.Tech, authDbValue: "msp.tech", label: "Tech" },
  { value: UserRole.TechDoNotDelete, authDbValue: "msp.technodelete", label: "Tech (Cannot Delete)" },
  { value: UserRole.ReadOnly, authDbValue: "msp.readonly", label: "Read Only" },
  { value: UserRole.BillingAggregatorAdmin, authDbValue: "msp.aggregatoradmin", label: "Aggregator Admin" },
  { value: UserRole.BillingAggregatorFinance, authDbValue: "msp.aggregatorfinance", label: "Aggregator Finance" },
  { value: UserRole.BillingAggregatorTech, authDbValue: "msp.aggregatortech", label: "Aggregator Tech" },
];

export default UserRole;
