import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField, Typography } from "@barracuda/bds-core";
import React, { useEffect, useMemo, useState } from "react";
import Grid from "@barracuda/bds-core/dist/Grid";
import { enterKey } from "../../../utility";
import FilterAccountsCheckboxes from ".//FilterAccountsCheckboxes";
import IFilterCheckbox from "../../../models/IFilterCheckbox";
import { IAppState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersForAccountsFilteringAction } from "../../../actions/productActions";
import FilterAccountsOptions from "../../../models/FilterAccountsOptions";
import { computeNewActiveProductsCheckboxesFromState, computeNewStatusesCheckboxesFromState, computeOptions, computeStateFiltersFromCheckboxes, getOrders, setActiveProductsFilter, setStatusesFilter, updateNoAvailableProductsOptions } from "../../../businessLogic/components/Accounts/FilterAccounts/FilterAccountListDialog";
import { setAccountFiltersAction } from "../../../actions/accountActions";
import { EmailValidationRule } from "../../../fieldsValidationRules";

interface IFilterAccountListDialogProps {
  onCancel: () => void;
  showDialog: boolean;
  actionInProgress: boolean;
  title: string;
}
const FilterAccountListDialog: React.FC<IFilterAccountListDialogProps> = ({ showDialog, onCancel, actionInProgress, title }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state: IAppState) => state.accountState.filters);
  const accountsOrders = useSelector((state: IAppState) => state.productState.accountsOrders);
  const loadingOrdersForAccountId = useSelector((state: IAppState) => state.productState.loadingOrdersForAccountId);
  const filterChildrenOfAccountId = useSelector((state: IAppState) => state.accountState.filterChildrenOfAccountId);

  let statuses: IFilterCheckbox[] = [
    { label: FilterAccountsOptions.NoActivatedProducts, checked: false, disabled: false },
    { label: FilterAccountsOptions.HasActivationErrors, checked: false, disabled: false },
    { label: FilterAccountsOptions.M365LinkedOnly, checked: false, disabled: false },
    { label: FilterAccountsOptions.M365UnlinkedOnly, checked: false, disabled: false },
    { label: FilterAccountsOptions.HasSMBLogins, checked: false, disabled: false },
    { label: FilterAccountsOptions.LoginUserAssociated, checked: false, disabled: false },
    { label: FilterAccountsOptions.HasIntronisBackup, checked: false, disabled: false },
  ];

  const [searchByAccountName, setSearchByAccountName] = useState(filters.name);
  const [newActiveProducts, setNewActiveProducts] = useState<IFilterCheckbox[]>([]);
  const [newStatuses, setNewStatuses] = useState(statuses);
  const [showLoading, setShowLoading] = useState(false);
  const [userAssociatedWithEmail, setUserAssociatedWithEmail] = useState(filters.associatedLogin);
  const [userAssociatedWithEmailError, setUserAssociatedWithEmailError] = useState("");

  useEffect(() => {
    setShowLoading(loadingOrdersForAccountId > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOrdersForAccountId]);

  useMemo(() => {
    if (loadingOrdersForAccountId === 0) {
      setNewStatuses(computeNewStatusesCheckboxesFromState(statuses, filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, loadingOrdersForAccountId]);

  useEffect(() => {
    if (loadingOrdersForAccountId === 0 && newActiveProducts.length > 0 && ((filters.essSkus && filters.essSkus.length > 0) || filters.csProdSkus || filters.bbsProdSkus)) {
      setNewActiveProducts(computeNewActiveProductsCheckboxesFromState(newActiveProducts, filters));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, loadingOrdersForAccountId]);

  useMemo(() => {
    if (filterChildrenOfAccountId !== undefined && loadingOrdersForAccountId === 0) {
      if (accountsOrders.length > 0) {
        const currentOrders = getOrders(accountsOrders, filterChildrenOfAccountId);
        if (currentOrders === undefined) {
          dispatch(getOrdersForAccountsFilteringAction(filterChildrenOfAccountId));
        } else {
          if (currentOrders.length > 0) {
            setNewStatuses(statuses);
            setNewActiveProducts(computeOptions(currentOrders));
          } else {
            setNewStatuses(updateNoAvailableProductsOptions(statuses));
          }
        }
      } else {
        setNewStatuses(updateNoAvailableProductsOptions(statuses));
        dispatch(getOrdersForAccountsFilteringAction(filterChildrenOfAccountId));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsOrders, loadingOrdersForAccountId, filterChildrenOfAccountId, title]);

  const handleActiveProductsFilter = (productsFilterOptions: IFilterCheckbox[], index: number) => {
    let result = setActiveProductsFilter(productsFilterOptions, index, newStatuses);
    setNewActiveProducts(result.productsFilter);
    setNewStatuses(result.statusesFilter);
  };

  const handleStatusesFilter = (statusesFilterOptions: IFilterCheckbox[], index: number) => {
    let result = setStatusesFilter(statusesFilterOptions, index, newActiveProducts);
    setNewActiveProducts(result.productsFilter);
    setNewStatuses(result.statusesFilter);
  };

  const handleConfirmFilter = () => {
    let isError = false;
    let isAssociatedChecked = newStatuses.find(e => e.label === FilterAccountsOptions.LoginUserAssociated);
    if (isAssociatedChecked?.checked) {
      isError = validateUserAssociatedWithEmail(userAssociatedWithEmail!);
    }
    if (!isError) {
      const newFilters = computeStateFiltersFromCheckboxes(newActiveProducts, newStatuses, searchByAccountName, userAssociatedWithEmail);
      dispatch(setAccountFiltersAction(newFilters));
      onCancel();
    }
  };

  const onAccountSearched = (value: string) => {
    setSearchByAccountName(value);
  };

  const handleOnLoginUserAssociatedWithThem = (value: string) => {
    setUserAssociatedWithEmailError("");
    setUserAssociatedWithEmail(value);
  };

  const validateUserAssociatedWithEmail = (accName: string) => {
    let isErrorUserAssociatedWithEmail = false;
    if (!accName) {
      setUserAssociatedWithEmailError("Enter email");
      isErrorUserAssociatedWithEmail = true;
    } else if (!EmailValidationRule.RegularExpression.test(accName)) {
      setUserAssociatedWithEmailError("Invalid email");
      isErrorUserAssociatedWithEmail = true;
    }
    return isErrorUserAssociatedWithEmail;
  };

  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onCancel)} data-testid="showFilterAccountsDialog" disableBackdropClick={true} open={showDialog} onClose={onCancel} maxWidth={false} disableEnforceFocus>
      <div className="showFilterDialog" style={{ width: showLoading ? "450px" : "870px" }}>
        <DialogTitle data-testid="filterAccountsDialogTitle" id="alert-dialog-title" style={{ backgroundColor: "primary" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          {showLoading ? (
            <div data-testid="loadingFilterDialog">
              <LinearProgress />
            </div>
          ) : (
            <Grid container direction="row">
              <Grid container item style={{ paddingBottom: "25px" }}>
                <TextField data-testid="filterAccountNameTxt" fullWidth label="By Account Name" value={searchByAccountName} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onAccountSearched(ev.target.value)} />
              </Grid>
              <Grid container direction="row">
                {newActiveProducts.length > 0 && (
                  <Grid item xs={6} data-testid="filterAccountProductOptions">
                    <Grid item xs={12} container>
                      <Typography data-testid="activatedProductsServicesCategoryTxt" variant="subtitle2" noWrap>
                        Activated Products & Services
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container>
                      <FilterAccountsCheckboxes options={newActiveProducts} onCheckboxOptionsChange={handleActiveProductsFilter}></FilterAccountsCheckboxes>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={6} data-testid="filterAccountStatusOptions">
                  <Grid item xs={12} container>
                    <Typography data-testid="statusesCategoryTxt" variant="subtitle2" noWrap>
                      Statuses
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container>
                    <FilterAccountsCheckboxes options={newStatuses} onCheckboxOptionsChange={handleStatusesFilter} onLoginUserAssociatedWithThem={handleOnLoginUserAssociatedWithThem} userAssociatedWithThem={userAssociatedWithEmail} userAssociatedWithThemError={userAssociatedWithEmailError}></FilterAccountsCheckboxes>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <div style={{ flex: "1 0 0" }} />
          <Button data-testid="cancelFilterAccoutListDialog" color="secondary" size="large" onClick={onCancel}>
            CANCEL
          </Button>
          {!showLoading && (
            <Button data-testid="confirmFilterAccoutListDialog" isLoading={actionInProgress} size="large" onClick={handleConfirmFilter} disabled={actionInProgress}>
              FILTER NOW
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default FilterAccountListDialog;
