import React from "react";
import Grid from "@barracuda/bds-core/dist/Grid";
import { getDateTimeToDisplay } from "../../../utility";

const InvoiceDate = (props: any) => {
  return (
    <td>
      <Grid container item>
        {getDateTimeToDisplay(props.dataItem.dateToDisplay)}
      </Grid>
    </td>
  );
};

export default InvoiceDate;
