import React, { useState, useEffect } from "react";
import "../../table.css";
import { DataTable, DataTableColumn as Column } from "@barracuda/bds-core";
import { process } from "@progress/kendo-data-query";
import AccountCell from "./AccountCell";
import { useSelector, useDispatch, batch } from "react-redux";
import { IAppState } from "../../store/store";
import { setDisplayCustomersByAccountId, setSelectedAccountAction, setAccountsPageSizeAction, setAccountsPageNumberAction, setExpandedPartnerAction, getCustomers, cancelLoadSubpartnerCustomersAction, loadParentAccountWithChildrenAction, setSelectedAccountToFilterProductsForBA, setshowEmptyPanelAction } from "../../actions/accountActions";
import MspType from "../../models/MspType";
import { setError, setViewMspAccountsAction } from "../../actions/generalActions";
import Pager from "@barracuda/bds-core/dist/DataTable/Pager";
import { clearAuditStateBeforeSearch, setSearchedAuditUserEmail } from "../../actions/auditUsersActions";
import { getButtonCount } from "../../utility";
import { useMediaQuery } from "@material-ui/core";
import { setIntegrationsAction, setLoadedIntegrationAction, setSelectedIntegrationAction, setSelectedIntegrationsTabNameAction } from "../../actions/integrations/integrationsActions";
import DefaultIntegrations from "../../models/Integrations/DefaultIntegrations";
import { setLogsTableProps } from "../../actions/integrations/integrationsLogsActions";
import { setIntegrationAccountsTablePropsAction, setShowUnlinkedAccountsAction } from "../../actions/integrations/integrationsAccountsActions";
import { resetIntegrationBillingTabStateAction } from "../../actions/integrations/integrationsBillingActions";
import { Dispatch } from "redux";
import IAccount from "../../models/IAccount";
import { shouldGetParent } from "../../businessLogic/components/Accounts/AccountsTable";
import { shouldUseIndent } from "../../Utilities/accountsHelper";
import { NoMspTypeError } from "../../models/constants";

interface IAccountsTableProps {
  showExpandIcon: boolean;
}

const AccountsTable: React.FC<IAccountsTableProps> = ({ showExpandIcon }) => {
  const dispatch = useDispatch();
  const pageSize = useSelector((state: IAppState) => state.accountState.accountsPageSize);
  const pageNumber = useSelector((state: IAppState) => state.accountState.accountsPageNumber);
  const items = useSelector((state: IAppState) => state.accountState.itemsToDisplay);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const hasSubpartners = useSelector((state: IAppState) => state.generalState.hasSubpartners);
  const viewSearchResults = useSelector((state: IAppState) => state.generalState.viewSearchResults);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const viewMspAccounts = useSelector((state: IAppState) => state.generalState.viewMspAccounts);
  const loadingAccountId = useSelector((state: IAppState) => state.accountState.loadingAccountId);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);
  const [buttonCount, setButtonCount] = useState(10);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1900px)");

  const dataState = {
    skip: pageSize * (pageNumber - 1),
    take: pageSize,
    sort: [{ field: "date", dir: "asc" }],
    group: [],
    collapsedGroups: [],
    selected: 0,
    lastSelectedIndex: 0,
    columns: [
      {
        title: "name",
        field: "name",
        show: true,
        filter: "text",
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(items, dataState as any),
  });

  const dataStateChange = (e: any): void => {
    dispatch(setAccountsPageSizeAction(e.dataState.take));
    dispatch(setAccountsPageNumberAction(e.dataState.skip / e.dataState.take + 1));
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(items, e.dataState),
    });
  };

  useEffect(() => {
    let newDs;
    if (selectedAccount) {
      newDs = { ...gridState.dataState, selected: selectedAccount.id, skip: pageSize * (pageNumber - 1) };
    } else {
      newDs = { ...gridState.dataState, selected: 0, skip: pageSize * (pageNumber - 1) };
    }
    setGridState({ dataState: newDs, dataResult: process(items, newDs as any) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selectedAccount, pageNumber, pageSize]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const getSubpartnerCustomers = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(getCustomers(account.id));
      resolve(success);
    });

  const getParent = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(loadParentAccountWithChildrenAction(account.id));
      resolve(success);
    });

  const rowClick = (item: any): void => {
    if (!viewSearchResults) {
      if ((item.type === MspType.Partner || item.type === MspType.Subpartner) && !isBaLoggedIn) {
        dispatch(setAccountsPageNumberAction(1));
        setGridState({
          dataState: { ...dataState, selected: item.id, skip: 0 },
          dataResult: process(items, dataState as any),
        });
      } else {
        setGridState({
          dataState: { ...dataState, selected: item.id },
          dataResult: process(items, dataState as any),
        });
      }
    }
  };

  const onClick = (e: any): void => {
    const item = e.dataItem;

    let enableLoading = true;
    if (selectedAccount !== undefined && selectedAccount !== null) {
      if (item.id === selectedAccount.id) {
        enableLoading = false;
        dispatch(setshowEmptyPanelAction(false));
      }
    } else if (item.id === loadingAccountId) {
      enableLoading = false;
    }

    if (enableLoading) {
      batch(() => {
        dispatch(setSearchedAuditUserEmail(""));
        dispatch(clearAuditStateBeforeSearch());
        if (!viewSearchResults) {
          if (isBaLoggedIn) {
            dispatch(setSelectedAccountAction(item));
            rowClick(item);
            if (item.type === MspType.Partner) {
              dispatch(setExpandedPartnerAction(item));
              dispatch(setSelectedAccountToFilterProductsForBA(undefined));
            }
          } else {
            if (item.type === MspType.Subpartner) {
              if (selectedAccount?.partnerId === item.id || mspAccountLoggedIn.type === MspType.Subpartner) {
                setSubpartnerAsSelectedAccount(mspAccountLoggedIn.type === MspType.Subpartner);
              } else {
                getSubpartnerCustomers(item).then(response => {
                  if (response) {
                    setSubpartnerAsSelectedAccount(false);
                  }
                });
              }
            } else if (item.type === MspType.Partner) {
              dispatch(cancelLoadSubpartnerCustomersAction());
              rowClick(item);
              if (hasSubpartners) {
                dispatch(setViewMspAccountsAction(false));
              }
              dispatch(setDisplayCustomersByAccountId(item));
              dispatch(setExpandedPartnerAction(item));
              dispatch(setSelectedAccountAction(item));
            } else {
              if (hasSubpartners) {
                dispatch(setViewMspAccountsAction(false));
              }
              dispatch(setSelectedAccountAction(item));
            }
          }
        } else {
          if (shouldGetParent(item, mspAccountLoggedIn)) {
            getParent(item).then(response => {
              if (response) {
                const partnerId = response.partnerId;
                if (partnerId) {
                  rowClick(item);
                  dispatch(setSelectedAccountAction({ ...item, partnerId: partnerId }));
                } else {
                  if (response.reason === NoMspTypeError) {
                    dispatch(setError("Parent of account ID " + item.id + " has no MSP type"));
                  }
                }
              }
            });
          } else {
            rowClick(item);
            dispatch(setSelectedAccountAction(item));
          }
        }

        if (isBaLoggedIn) {
          dispatch(setSelectedAccountToFilterProductsForBA(undefined));
        } else if (item.type !== MspType.Partner) {
          resetCw(dispatch);
        }

        function setSubpartnerAsSelectedAccount(setViewMsp: boolean) {
          rowClick(item);
          dispatch(setViewMspAccountsAction(setViewMsp));
          dispatch(setExpandedPartnerAction(item));
          dispatch(setSelectedAccountAction(item));
        }
      });
    }
  };

  const AccountCellCommand = (props: any) => <AccountCell {...props} onClick={onClick} displayAccountIcon={true} displayExpandIcon={showExpandIcon && hasSubpartners && !viewSearchResults} mspAccountLoggedIn={mspAccountLoggedIn} viewMspAccounts={viewMspAccounts} shouldUseIndentation={shouldUseIndent(viewSearchResults, mspAccountLoggedIn, props.dataItem, viewMspAccounts)} loading={props.dataItem.id === loadingAccountId} />;

  return (
    <DataTable
      className={"noHeader noScrollbar noBorders accountTable"}
      data={gridState.dataResult.data.map(item => ({ ...item, selected: item.id === gridState.dataState.selected, isLoading: true }))}
      filterable
      reorderable
      resizable
      // page
      pageConfig={{
        pageable: {
          pageSizes: [10, 25, 50],
          buttonCount: buttonCount,
        },
        skip: gridState.dataState.skip,
        take: gridState.dataState.take,
        total: gridState.dataResult.total,
      }}
      pager={gridState.dataResult.data.length > 0 && Pager}
      sortConfig={{
        sortable: true,
        sort: gridState.dataState.sort as any,
      }}
      onDataStateChange={dataStateChange}
      selectedField="selected"
      onRowClick={rowClick}
      {...(gridState.dataState as any)}
    >
      {gridState.dataState.columns.map((column, idx) => column.show && <Column key={"item" + idx} field={column.field} title={column.title} cell={AccountCellCommand} />)}
    </DataTable>
  );
};

export default AccountsTable;

export function resetCw(dispatch: Dispatch<any>) {
  dispatch(setIntegrationsAction(DefaultIntegrations));
  dispatch(setSelectedIntegrationAction(undefined));
  dispatch(setLoadedIntegrationAction(undefined));
  dispatch(setLogsTableProps({ sort: [{ field: "date", dir: "desc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } }));
  dispatch(setIntegrationAccountsTablePropsAction({ sort: [{ field: "mspAccountName", dir: "asc" }], take: 10, skip: 0, filter: undefined }));
  dispatch(setSelectedIntegrationsTabNameAction(undefined));
  dispatch(setShowUnlinkedAccountsAction(false));
  dispatch(resetIntegrationBillingTabStateAction());
}
